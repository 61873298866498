import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const PhoneForm = ({ onSubmit, formType }) => {
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [password, setPassword] = useState("");
  console.log(formType);

  const handleSubmit = (event) => {
    event.preventDefault();

    onSubmit(phone, email, firstName, lastName, address, password);
  };
  const handleForgotPassword = (e) => {
    // console.log(e.target.value);;
    const email = e.target.value;
    if (e.target.value === "") {
      alert("First fill your email address and then click on forgot password");
    } else {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        email: "sumansuket123@gmail.com",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        "https://admin1.bonzaico.com/email/send-reset-password-email",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          // console.log(result);
          alert(
            `If you are a registered user, an email has ben sent at your email ${email}.`
          );
        })
        .catch((error) => alert("error"));
    }
  };

  return (
    <div className="forgot">
      <form className="phoneInput" onSubmit={handleSubmit}>
        {formType != "login" && (
          <div>
            <label>
              * First Name:
              <input
                type="text"
                value={firstName}
                onChange={(event) => setFirstName(event.target.value)}
                placeholder="First Name"
                required
              />
            </label>
            <br />
            <label>
              * Last Name:
              <input
                type="text"
                value={lastName}
                onChange={(event) => setLastName(event.target.value)}
                placeholder="Last Name"
                required
              />
            </label>
            <br />
            <label>
              *Address
              <input
                type="text"
                rows={4}
                cols={40}
                value={address}
                onChange={(event) => setAddress(event.target.value)}
                placeholder="Address"
                required
              />
            </label>
            <br />
            <div className="phoneNumberInput">
              <label htmlFor="phone"> Ph. Number:</label>
              <PhoneInput
                defaultCountry="DE"
                id="phone"
                placeholder="Enter phone number"
                value={phone}
                onChange={setPhone}
              />
            </div>
          </div>
        )}
        <div className="emailInput">
          <label htmlFor="email">* Email</label>
          <input
            type="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            placeholder="Email"
            required
          />
        </div>

        <div className="bonzaiP">
          <label>
            * Password
            <input
              type="password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              placeholder="Enter your password"
              required
            />
          </label>
        </div>
        <div className="submitButtonContainer">
          <button className="upvotelistbutton" type="submit">
            Submit
          </button>
        </div>
      </form>
      {formType === "login" && (
        <button
          className="forgotPassword"
          onClick={handleForgotPassword}
          value={email}
        >
          Forgot Password ?
        </button>
      )}
    </div>
  );
};

export default withRouter(PhoneForm);

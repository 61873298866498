import React from "react";

class ProductDetailHeader extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let url = window.location.href;

    let link = url.split("#/products/")[0];
    let eventDay = link.split("?query=")[1];
    let productString = url.split("#/products/")[1];
    let productString1 = productString.split("%22").join('"');
    let product = JSON.parse(productString1.split("%20").join(" "));

    console.log(this.props);
    if (!product) {
      return;
    }

    let eventImage = product.imageUrl;
    // console.log(this.props);

    return (
      <div className="product-detail-header">
        <div className="product-detail-header-img">
          <img
            className="product-index-image"
            src={eventImage}
            // onError={(e) => (e.target.src = "missing-logo-dummy.png")}
          />
        </div>

        <div className="product-detail-header-text">
          <p className="product-detail-header-name">{product.name}</p>
          {/* <p className="product-detail-header-tagline">{product.type}</p> */}
          <div className="product-item-tags"></div>
        </div>
      </div>
    );
  }
}

export default ProductDetailHeader;

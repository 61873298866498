import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import "react-phone-number-input/style.css";

const OTPForm = ({ onSubmit, closeModal }) => {
  // const [otp, setOTP] = useState("");

  const handleSubmit = (event) => {
    // event.preventDefault();
    // console.log(event);
    closeModal();
    // onSubmit(otp);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <h1 className="thankyou">Thank You!</h1>
        <p>Thank you for signing up. We appreciate your interest.</p>
        <p>An email has been sent to your registered email address.</p>
        <p>
          Please click on the verification link in the email to complete your
          account verification.
        </p>
        <p>
          Once your account is verified, you'll be able to purchase tickets.
        </p>
      </div>
      <div className="thankyou">
        <button type="submit">OK</button>
      </div>
    </form>
  );
};

export default withRouter(OTPForm);

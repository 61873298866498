import React from "react";
import Thread from "./product_discussion_thread";
import CommentForm from "./product_discussion_comment_form";
// import { getComment } from "../../util/product_api_util";
import { useEffect } from "react";

const ProductDiscussion = ({
  commentsById,
  comments,
  allComments,
  commentIds,
  childComments,
  currentUser,
  productId,
  createComment,
  getComment,
  destroyComment,
  createUpvote,
  destroyUpvote,
}) => {
  // console.log(comments);
  useEffect(() => {
    if (currentUser && productId) {
      getComment(productId);
    }
  }, []);
  // console.log(allComments);
  return (
    <div className="product-discussion">
      {!currentUser && (
        <div className="product-dicussion-error">
          <p>Bonzai</p>
        </div>
      )}
      {currentUser && (
        <CommentForm
          user={currentUser}
          showIcon={true}
          classProp={"new-comment-form"}
          productId={productId}
          createComment={createComment}
        />
      )}
      <div>
        {Array.isArray(allComments) &&
          allComments.map((commentId) => {
            let comment = commentId;
            // console.log(comments);
            // console.log(comment);
            // console.log(childComments);
            if (comment.parent_comment_id === "") {
              return (
                <Thread
                  key={`discussion-thread-${comment.id}`}
                  comment={comment}
                  childComments={childComments}
                  currentUser={currentUser}
                  productId={productId}
                  createComment={createComment}
                  destroyComment={destroyComment}
                  createUpvote={createUpvote}
                  destroyUpvote={destroyUpvote}
                />
              );
            }
          })}
      </div>
    </div>
  );
};

export default ProductDiscussion;

import React from "react";
import { Link } from "react-router-dom";

class UserHeader extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let user = this.props.user;
    // let accessToken = this.props.currentUser.token.access;
    // let sessionId = this.props.viewedUserId;
    // console.log(sessionId);
    // let UserHeaderButton = undefined;
    // if (accessToken === sessionId) {
    //   UserHeaderButton = (
    //     <Link
    //       to={`/users/${user.id}/edit`}
    //       className="button white-button medium-size"
    //     >
    //       Edit
    //     </Link>
    //   );
    // } else {
    //   UserHeaderButton = (
    //     <button
    //       onClick={this.followUser}
    //       className="button white-button medium-size"
    //     >
    //       Follow
    //     </button>
    //   );
    // }
    return (
      <div className="user-header">
        <div className="user-header-info">
          {/* <div className="user-header-profile-img-container">
            <img className="user-header-profile-img" src={user.imageUrl} />
          </div> */}

          <h1 className="user-profile-main-text-contianer">Profile Details</h1>
          <ul className="user-profile-main-text-contianer">
            <li>
              <div className="user-profile-main-text">
                <p className="user-username">
                  {"Name : "}
                  {user.first_name}
                  {"  "}
                  {user.second_name}
                </p>
                {/* <p className="user-username">{user.second_name}</p> */}
              </div>
            </li>
            <li className="user-userslug">
              {"Email : "}

              {user.email}
              {user.tc == 0 && (
                <div style={{ backgroundColor: "red", padding: "10px" }}>
                  Email address not Verified
                </div>
              )}
            </li>
            {user.tc == 1 && (
              <div style={{ backgroundColor: "green", padding: "10px" }}>
                Email address Verified{" "}
              </div>
            )}
            <li className="user-headline">
              {"Address : "}
              {user.address}
            </li>
            {user.phone && (
              <li className="user-headline">
                {"Phone : "}
                {user.phone}
              </li>
            )}
            {!user.phone && (
              <li className="user-headline">
                {"Phone : "}
                {"---not---updated---"}
              </li>
            )}
          </ul>
        </div>

        {/* <div className="user-profile-links">
          <Link
            to={`/users/${user.id}/followers`}
            className="user-profile-link"
          >
            Followers
          </Link>
          <Link
            to={`/users/${user.id}/following`}
            className="user-profile-link"
          >
            Following
          </Link>
          {UserHeaderButton}
        </div> */}
      </div>
    );
  }
}

export default UserHeader;

import $ from "jquery";

export const getAllProducts = (date, city) => {
  return new Promise((resolve, reject) => {
    var settings = {
      url: `https://public-api.eventim.com/websearch/search/api/exploration/v1/products?language=EN&city_names=${city}&date_from=${date}&sort=DateAsc&in_stock=true&top=50`,
      method: "GET",
      timeout: 0,
      headers: {
        Cookie:
          "_abck=FB8299313D0D2237905C7A5C19A0C9C2~-1~YAAQJ40sMU9edjGGAQAAXZN5tgkLNDjYPQvrpWgJ15VFlCBcKakfum7s5fWexj3WgnViHQrnHrhEPCxbCPqi3pZCnPXpjqkVxsqhJee72toLxvbc2+zfeXoTcm9K9tsGb5OCCHMOjeKZLuhQ4/Ifa/+UZnz9dVKEkRHWzxO4i8KZfrfUgR9VE6nX4ab4i3bx1J42R9onWhUwcyJvmbqG7ofDaXgSIxCdk5+izBwfxBbZFuf75XyFE/hggBNXiyCi63fTswrEpfkJ+enkHnIcVAl5uLrXkBsn7vmB2Y84rAp2YVFR4iaeH8/W+x8AhXErCbJoWlYZyYCoQOCORHug+WtyWPVuc4bpvQqFGxCVhGLOt/22H8C7l705~-1~-1~1678102244; bm_sz=4D717329D367E2897B6FA931B983D384~YAAQJ40sMVBedjGGAQAAXZN5thOx7XI1OHd8aVztWtPCJ7nw3u8mF7AxCioChjvlwpPfGE1Q248fNKcqEuKReG4VAb3Wq9jkRWANuZ1POwUHiLexevxSSgLOYMSgcNo44gpeTI+pN1El2ANoMmbli4vN59jPrk6khpBKtnphJV0BkOIq61ZEfXy/jSTuMo0WDLmqfOVe6bTqydGDwjm77b6bMxzZ5FfBXvqO+FKqaPbNjF/x3ako5PJt4bD8CC0hay3g3JvygTP2UgzqMtatGb78rXhLF0x+sU4vn4HvUnmGXv8H~4600370~3294000",
      },
    };

    $.ajax(settings).done(function (response) {
      resolve(response);
    });
  });
};
// $.ajax({
//   method: "GET",
//   url: `api/products`,
// });
export const getProductImages = (productUrl) => {
  return new Promise((resolve, reject) => {
    var settings = {
      url: `https://customsearch.googleapis.com/customsearch/v1?key=AIzaSyCUODRXx8NHsx53eWkUgnlv2MzmTzk76Yc&cx=25785c3e85bb94fa7&q=${productUrl}&searchType=image&imgType=photo&cr=countryDE&filter=1`,
      method: "GET",
      timeout: 0,
    };

    $.ajax(settings).done(function (response) {
      resolve(response);
    });
  });
};
export const getProduct = (productId) => {
  return new Promise((resolve, reject) => {
    var settings = {
      url: `https://add.maascot.com/api/GetProductDetail/?search=${productId}`,
      method: "GET",
      timeout: 0,
      headers: {
        "content-type": "application/json",
      },
    };

    $.ajax(settings).done(function (response) {
      resolve(response);
    });
  });
};

export const postProduct = (product) => {
  return $.ajax({
    method: "POST",
    url: `api/products`,
    data: { product },
  });
};

export const postComment = (comment) => {
  return new Promise((resolve, reject) => {
    var settings = {
      url: "https://add.maascot.com/api/PostComment/",
      method: "POST",
      timeout: 0,
      headers: {
        "Content-Type": "application/json",
      },
      data: comment,
    };

    $.ajax(settings).done(function (response) {
      resolve(response);
    });
  });
};

export const getComment = (productId) => {
  return new Promise((resolve, reject) => {
    var settings = {
      url: `https://add.maascot.com/api/GetProductComments/?search=${productId}`,
      method: "GET",
      timeout: 0,
      headers: {
        "Content-Type": "application/json",
      },
    };

    $.ajax(settings).done(function (response) {
      resolve(response);
    });
  });
};

export const deleteComment = (commentId) => {
  return new Promise((resolve, reject) => {
    var settings = {
      url: `https://add.maascot.com/api/GetCommentAndDelete/${commentId}`,
      method: "DELETE",
      timeout: 0,
    };

    $.ajax(settings).done(function (response) {
      resolve(response);
    });
  });
};

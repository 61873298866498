import React from "react";
import "../Subscribe/Subscribe.scss";

const Subscribe = (session) => {
  const handleBonzaiPremiumClick = () => {
    // console.log(typeof session.currentUser.id);
    if (!session.currentUser?.token) {
      alert("Please Login or Signup to subscribe to our plans");
    }
    if (session.currentUser.id === "0") {
      console.log("daaaaaaaasdsdsdsd");
      alert("Please verify your email address to complete Signup");
    } else if (session.currentUser.id === 1) {
      window.open("https://buy.stripe.com/4gw7u4g3eayD0Ny5km", "_blank");
    }
  };
  return (
    <div className="app-content">
      <div className="subs">
        <div className="subscription-card">
          <div className="card">
            <div className="card-header">Bonzai Premium</div>
            <div className="card-body">
              <h5 className="card-title">€69.99/month</h5>
              <ul>
                <li className="subscriptionListItem">Access to all features</li>
                <li className="subscriptionListItem">
                  8 tickets per month for movies, live events, or a combination
                  of all sorts of events
                </li>
                <li className="subscriptionListItem">
                  Personalized recommendations with premium filters and curated
                  experiences
                </li>
                <li className="subscriptionListItem">
                  Access to exclusive Bonzai community events and content
                </li>
                <li className="subscriptionListItem">
                  15% discount on additional tickets
                </li>
                <li className="subscriptionListItem">
                  Early access to event tickets and presales with VIP seating
                  options
                </li>
                <li className="subscriptionListItem">
                  Premium customer support with a dedicated account manager
                </li>
                <li className="subscriptionListItem">
                  <strong>Switch Option:</strong> Swap up to three allotted
                  tickets per month with alternative options
                </li>
              </ul>
              <button
                className="btn btn-primary"
                onClick={handleBonzaiPremiumClick}
              >
                {/* <a
                  href="https://buy.stripe.com/4gw7u4g3eayD0Ny5km"
                  target="_blank"
                > */}
                Subscribe
                {/* </a> */}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscribe;

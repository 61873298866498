const cities = [
  {
    "id": 1,
    "City": "Hamburg",
    "count": 15223,
    "slur": "hamburg-7",
    "Latitude": 53.550341,
    "Longitude": 10.000654,
    "Country": "Deutschland"
  },
  {
    "id": 2,
    "City": "Berlin",
    "count": 8709,
    "slur": "berlin-1",
    "Latitude": 52.5170365,
    "Longitude": 13.3888599,
    "Country": "Deutschland"
  },
  {
    "id": 3,
    "City": "Köln",
    "count": 6394,
    "slur": "koeln-9",
    "Latitude": 50.938361,
    "Longitude": 6.959974,
    "Country": "Deutschland"
  },
  {
    "id": 4,
    "City": "Dresden",
    "count": 4182,
    "slur": "dresden-3",
    "Latitude": 51.0493286,
    "Longitude": 13.7381437,
    "Country": "Deutschland"
  },
  {
    "id": 5,
    "City": "Wien",
    "count": 2681,
    "slur": "",
    "Latitude": 48.2083537,
    "Longitude": 16.3725042,
    "Country": "Österreich"
  },
  {
    "id": 6,
    "City": "Leipzig",
    "count": 2369,
    "slur": "leipzig-10",
    "Latitude": 51.3406321,
    "Longitude": 12.3747329,
    "Country": "Deutschland"
  },
  {
    "id": 7,
    "City": "München",
    "count": 2023,
    "slur": "muenchen-11",
    "Latitude": 48.1371079,
    "Longitude": 11.5753822,
    "Country": "Deutschland"
  },
  {
    "id": 8,
    "City": "Frankfurt",
    "count": 1804,
    "slur": "frankfurt-6",
    "Latitude": 50.1106444,
    "Longitude": 8.6820917,
    "Country": "Deutschland"
  },
  {
    "id": 9,
    "City": "Düsseldorf",
    "count": 1538,
    "slur": "duesseldorf-4",
    "Latitude": 51.2254018,
    "Longitude": 6.7763137,
    "Country": "Deutschland"
  },
  {
    "id": 10,
    "City": "Stuttgart",
    "count": 1502,
    "slur": "stuttgart-12",
    "Latitude": 48.7784485,
    "Longitude": 9.1800132,
    "Country": "Deutschland"
  },
  {
    "id": 11,
    "City": "Dortmund",
    "count": 1373,
    "slur": "dortmund-52",
    "Latitude": 51.5142273,
    "Longitude": 7.4652789,
    "Country": "Deutschland"
  },
  {
    "id": 12,
    "City": "Bonn",
    "count": 1098,
    "slur": "bonn-45",
    "Latitude": 50.735851,
    "Longitude": 7.10066,
    "Country": "Deutschland"
  },
  {
    "id": 13,
    "City": "Hannover",
    "count": 962,
    "slur": "hannover-8",
    "Latitude": 52.3744779,
    "Longitude": 9.7385532,
    "Country": "Deutschland"
  },
  {
    "id": 14,
    "City": "Bremen",
    "count": 855,
    "slur": "bremen-2",
    "Latitude": 53.0758196,
    "Longitude": 8.8071646,
    "Country": "Deutschland"
  },
  {
    "id": 15,
    "City": "Bochum",
    "count": 806,
    "slur": "bochum-44",
    "Latitude": 51.4818111,
    "Longitude": 7.2196635,
    "Country": "Deutschland"
  },
  {
    "id": 16,
    "City": "Salzburg",
    "count": 753,
    "slur": "",
    "Latitude": 47.7981346,
    "Longitude": 13.0464806,
    "Country": "Österreich"
  },
  {
    "id": 17,
    "City": "Oberhausen",
    "count": 746,
    "slur": "oberhausen-77",
    "Latitude": 51.4696137,
    "Longitude": 6.8514435,
    "Country": "Deutschland"
  },
  {
    "id": 18,
    "City": "Graz",
    "count": 681,
    "slur": "",
    "Latitude": 47.0708678,
    "Longitude": 15.4382786,
    "Country": "Österreich"
  },
  {
    "id": 19,
    "City": "Nürnberg",
    "count": 529,
    "slur": "nuernberg-76",
    "Latitude": 49.453872,
    "Longitude": 11.077298,
    "Country": "Deutschland"
  },
  {
    "id": 20,
    "City": "Münster",
    "count": 516,
    "slur": "muenster-1632",
    "Latitude": 51.9625101,
    "Longitude": 7.6251879,
    "Country": "Deutschland"
  },
  {
    "id": 21,
    "City": "Erfurt",
    "count": 508,
    "slur": "erfurt-54",
    "Latitude": 50.9777974,
    "Longitude": 11.0287364,
    "Country": "Deutschland"
  },
  {
    "id": 22,
    "City": "Mannheim",
    "count": 498,
    "slur": "mannheim-1634",
    "Latitude": 49.4892913,
    "Longitude": 8.4673098,
    "Country": "Deutschland"
  },
  {
    "id": 23,
    "City": "Oldenburg",
    "count": 471,
    "slur": "oldenburg-79",
    "Latitude": 53.1389753,
    "Longitude": 8.2146017,
    "Country": "Deutschland"
  },
  {
    "id": 24,
    "City": "Essen",
    "count": 456,
    "slur": "essen-56",
    "Latitude": 51.4582235,
    "Longitude": 7.0158171,
    "Country": "Deutschland"
  },
  {
    "id": 25,
    "City": "Freiburg im Breisgau",
    "count": 454,
    "slur": "freiburg-im-breisgau-57",
    "Latitude": 47.9960901,
    "Longitude": 7.8494005,
    "Country": "Deutschland"
  },
  {
    "id": 26,
    "City": "Lübeck",
    "count": 380,
    "slur": "luebeck-72",
    "Latitude": 53.866444,
    "Longitude": 10.684738,
    "Country": "Deutschland"
  },
  {
    "id": 27,
    "City": "Chemnitz",
    "count": 377,
    "slur": "chemnitz-49",
    "Latitude": 50.8322608,
    "Longitude": 12.9252977,
    "Country": "Deutschland"
  },
  {
    "id": 28,
    "City": "Rostock",
    "count": 372,
    "slur": "rostock-84",
    "Latitude": 54.0924445,
    "Longitude": 12.1286127,
    "Country": "Deutschland"
  },
  {
    "id": 29,
    "City": "Karlsruhe",
    "count": 370,
    "slur": "karlsruhe-66",
    "Latitude": 49.0068705,
    "Longitude": 8.4034195,
    "Country": "Deutschland"
  },
  {
    "id": 30,
    "City": "Magdeburg",
    "count": 354,
    "slur": "",
    "Latitude": 52.1315889,
    "Longitude": 11.6399609,
    "Country": "Deutschland"
  },
  {
    "id": 31,
    "City": "Heidelberg",
    "count": 342,
    "slur": "heidelberg-62",
    "Latitude": 49.4093582,
    "Longitude": 8.694724,
    "Country": "Deutschland"
  },
  {
    "id": 32,
    "City": "Bielefeld",
    "count": 326,
    "slur": "bielefeld-43",
    "Latitude": 52.0191005,
    "Longitude": 8.531007,
    "Country": "Deutschland"
  },
  {
    "id": 33,
    "City": "Halle (Saale)",
    "count": 324,
    "slur": "halle-saale-61",
    "Latitude": 51.4825041,
    "Longitude": 11.9705452,
    "Country": "Deutschland"
  },
  {
    "id": 34,
    "City": "Saarbrücken",
    "count": 296,
    "slur": "saarbruecken-85",
    "Latitude": 49.234362,
    "Longitude": 6.996379,
    "Country": "Deutschland"
  },
  {
    "id": 35,
    "City": "Braunschweig",
    "count": 266,
    "slur": "braunschweig-47",
    "Latitude": 52.2646577,
    "Longitude": 10.5236066,
    "Country": "Deutschland"
  },
  {
    "id": 36,
    "City": "Wiesbaden",
    "count": 254,
    "slur": "wiesbaden-88",
    "Latitude": 50.0820384,
    "Longitude": 8.2416556,
    "Country": "Deutschland"
  },
  {
    "id": 37,
    "City": "Augsburg",
    "count": 246,
    "slur": "augsburg-41",
    "Latitude": 48.3668041,
    "Longitude": 10.8986971,
    "Country": "Deutschland"
  },
  {
    "id": 38,
    "City": "Bad Vilbel",
    "count": 238,
    "slur": "",
    "Latitude": 50.1804837,
    "Longitude": 8.7410776,
    "Country": "Deutschland"
  },
  {
    "id": 39,
    "City": "Linz",
    "count": 232,
    "slur": "",
    "Latitude": 48.3059078,
    "Longitude": 14.286198,
    "Country": "Österreich"
  },
  {
    "id": 40,
    "City": "Osnabrück",
    "count": 226,
    "slur": "osnabrueck-80",
    "Latitude": 52.2719595,
    "Longitude": 8.047635,
    "Country": "Deutschland"
  },
  {
    "id": 41,
    "City": "Heilbronn",
    "count": 222,
    "slur": "heilbronn-63",
    "Latitude": 49.142291,
    "Longitude": 9.218655,
    "Country": "Deutschland"
  },
  {
    "id": 42,
    "City": "Flensburg",
    "count": 210,
    "slur": "",
    "Latitude": 54.7833021,
    "Longitude": 9.4333264,
    "Country": "Deutschland"
  },
  {
    "id": 43,
    "City": "Duisburg",
    "count": 205,
    "slur": "duisburg-53",
    "Latitude": 51.434999,
    "Longitude": 6.759562,
    "Country": "Deutschland"
  },
  {
    "id": 44,
    "City": "Baden-Baden",
    "count": 203,
    "slur": "",
    "Latitude": 48.7580777,
    "Longitude": 8.2415239,
    "Country": "Deutschland"
  },
  {
    "id": 45,
    "City": "Hanau",
    "count": 194,
    "slur": "",
    "Latitude": 50.132881,
    "Longitude": 8.9169797,
    "Country": "Deutschland"
  },
  {
    "id": 46,
    "City": "Cottbus",
    "count": 191,
    "slur": "cottbus-50",
    "Latitude": 51.7567447,
    "Longitude": 14.3357307,
    "Country": "Deutschland"
  },
  {
    "id": 47,
    "City": "Füssen",
    "count": 187,
    "slur": "",
    "Latitude": 47.5709212,
    "Longitude": 10.6977089,
    "Country": "Deutschland"
  },
  {
    "id": 48,
    "City": "Zwickau",
    "count": 181,
    "slur": "",
    "Latitude": 50.7185043,
    "Longitude": 12.4939267,
    "Country": "Deutschland"
  },
  {
    "id": 49,
    "City": "Göttingen",
    "count": 179,
    "slur": "goettingen-1641",
    "Latitude": 51.5328328,
    "Longitude": 9.9351811,
    "Country": "Deutschland"
  },
  {
    "id": 50,
    "City": "Zürich",
    "count": 179,
    "slur": "",
    "Latitude": 47.3744489,
    "Longitude": 8.5410422,
    "Country": "Schweiz/Suisse/Svizzera/Svizra"
  },
  {
    "id": 51,
    "City": "Kiel",
    "count": 173,
    "slur": "kiel-68",
    "Latitude": 54.3227085,
    "Longitude": 10.135555,
    "Country": "Deutschland"
  },
  {
    "id": 52,
    "City": "Potsdam",
    "count": 172,
    "slur": "potsdam-82",
    "Latitude": 52.4009309,
    "Longitude": 13.0591397,
    "Country": "Deutschland"
  },
  {
    "id": 53,
    "City": "Ingolstadt",
    "count": 165,
    "slur": "ingolstadt-65",
    "Latitude": 48.7630165,
    "Longitude": 11.4250395,
    "Country": "Deutschland"
  },
  {
    "id": 54,
    "City": "Gera",
    "count": 162,
    "slur": "",
    "Latitude": 50.8772301,
    "Longitude": 12.0796208,
    "Country": "Deutschland"
  },
  {
    "id": 55,
    "City": "Fulda",
    "count": 152,
    "slur": "",
    "Latitude": 50.5542328,
    "Longitude": 9.6770448,
    "Country": "Deutschland"
  },
  {
    "id": 56,
    "City": "Klagenfurt",
    "count": 150,
    "slur": "",
    "Latitude": 46.623943,
    "Longitude": 14.3075976,
    "Country": "Österreich"
  },
  {
    "id": 57,
    "City": "Leverkusen",
    "count": 148,
    "slur": "leverkusen-71",
    "Latitude": 51.0324743,
    "Longitude": 6.9881194,
    "Country": "Deutschland"
  },
  {
    "id": 58,
    "City": "Plauen",
    "count": 145,
    "slur": "",
    "Latitude": 50.4950632,
    "Longitude": 12.1346523,
    "Country": "Deutschland"
  },
  {
    "id": 59,
    "City": "Aachen",
    "count": 143,
    "slur": "aachen-40",
    "Latitude": 50.776351,
    "Longitude": 6.083862,
    "Country": "Deutschland"
  },
  {
    "id": 60,
    "City": "Lüneburg",
    "count": 142,
    "slur": "",
    "Latitude": 53.248706,
    "Longitude": 10.407855,
    "Country": "Deutschland"
  },
  {
    "id": 61,
    "City": "Mainz",
    "count": 142,
    "slur": "mainz-74",
    "Latitude": 50.0012314,
    "Longitude": 8.2762513,
    "Country": "Deutschland"
  },
  {
    "id": 62,
    "City": "Schwäbisch Hall",
    "count": 139,
    "slur": "",
    "Latitude": 49.1123963,
    "Longitude": 9.7369047,
    "Country": "Deutschland"
  },
  {
    "id": 63,
    "City": "Regensburg",
    "count": 136,
    "slur": "regensburg-1896",
    "Latitude": 49.0195333,
    "Longitude": 12.0974869,
    "Country": "Deutschland"
  },
  {
    "id": 64,
    "City": "Koblenz",
    "count": 135,
    "slur": "koblenz-69",
    "Latitude": 50.3533278,
    "Longitude": 7.5943951,
    "Country": "Deutschland"
  },
  {
    "id": 65,
    "City": "Heidenheim",
    "count": 134,
    "slur": "",
    "Latitude": 48.6767637,
    "Longitude": 10.152923,
    "Country": "Deutschland"
  },
  {
    "id": 66,
    "City": "Ludwigsburg",
    "count": 132,
    "slur": "",
    "Latitude": 48.8953937,
    "Longitude": 9.1895147,
    "Country": "Deutschland"
  },
  {
    "id": 67,
    "City": "Aschaffenburg",
    "count": 130,
    "slur": "",
    "Latitude": 49.9740542,
    "Longitude": 9.1493636,
    "Country": "Deutschland"
  },
  {
    "id": 68,
    "City": "Bad Kissingen",
    "count": 128,
    "slur": "",
    "Latitude": 50.1985698,
    "Longitude": 10.0746833,
    "Country": "Deutschland"
  },
  {
    "id": 69,
    "City": "Salzburg/Österreich",
    "count": 124,
    "slur": "",
    "Latitude": 47.7981346,
    "Longitude": 13.0464806,
    "Country": "Österreich"
  },
  {
    "id": 70,
    "City": "Bamberg",
    "count": 123,
    "slur": "",
    "Latitude": 49.8916044,
    "Longitude": 10.8868478,
    "Country": "Deutschland"
  },
  {
    "id": 71,
    "City": "Bad Hersfeld",
    "count": 120,
    "slur": "",
    "Latitude": 50.8681341,
    "Longitude": 9.7068481,
    "Country": "Deutschland"
  },
  {
    "id": 72,
    "City": "Kirchdorf am Inn",
    "count": 119,
    "slur": "",
    "Latitude": 48.2943094,
    "Longitude": 13.2819617,
    "Country": "Österreich"
  },
  {
    "id": 73,
    "City": "Mönchengladbach",
    "count": 119,
    "slur": "moenchengladbach-1512",
    "Latitude": 51.1946983,
    "Longitude": 6.4353641,
    "Country": "Deutschland"
  },
  {
    "id": 74,
    "City": "Neuss",
    "count": 118,
    "slur": "",
    "Latitude": 51.1981778,
    "Longitude": 6.6916476,
    "Country": "Deutschland"
  },
  {
    "id": 75,
    "City": "Paris",
    "count": 118,
    "slur": "",
    "Latitude": 48.8588897,
    "Longitude": 2.320041022,
    "Country": "France"
  },
  {
    "id": 76,
    "City": "Fürth",
    "count": 117,
    "slur": "fuerth-58",
    "Latitude": 49.4772475,
    "Longitude": 10.9893626,
    "Country": "Deutschland"
  },
  {
    "id": 77,
    "City": "Meiningen",
    "count": 115,
    "slur": "",
    "Latitude": 50.57342505,
    "Longitude": 10.42837114,
    "Country": "Deutschland"
  },
  {
    "id": 78,
    "City": "Siegburg",
    "count": 115,
    "slur": "",
    "Latitude": 50.7928332,
    "Longitude": 7.2070774,
    "Country": "Deutschland"
  },
  {
    "id": 79,
    "City": "Ulm",
    "count": 110,
    "slur": "ulm-87",
    "Latitude": 48.3974003,
    "Longitude": 9.9934336,
    "Country": "Deutschland"
  },
  {
    "id": 80,
    "City": "Hagen",
    "count": 109,
    "slur": "hagen-60",
    "Latitude": 51.3582945,
    "Longitude": 7.473296,
    "Country": "Deutschland"
  },
  {
    "id": 81,
    "City": "Gelsenkirchen",
    "count": 106,
    "slur": "gelsenkirchen-59",
    "Latitude": 51.5110321,
    "Longitude": 7.0960124,
    "Country": "Deutschland"
  },
  {
    "id": 82,
    "City": "Krefeld",
    "count": 105,
    "slur": "krefeld-70",
    "Latitude": 51.3331205,
    "Longitude": 6.5623343,
    "Country": "Deutschland"
  },
  {
    "id": 83,
    "City": "Innsbruck",
    "count": 100,
    "slur": "",
    "Latitude": 47.2654296,
    "Longitude": 11.3927685,
    "Country": "Österreich"
  },
  {
    "id": 84,
    "City": "Neubrandenburg",
    "count": 100,
    "slur": "",
    "Latitude": 53.5574458,
    "Longitude": 13.2602781,
    "Country": "Deutschland"
  },
  {
    "id": 85,
    "City": "Kaiserslautern",
    "count": 98,
    "slur": "",
    "Latitude": 49.4432174,
    "Longitude": 7.7689951,
    "Country": "Deutschland"
  },
  {
    "id": 86,
    "City": "Giessen",
    "count": 94,
    "slur": "",
    "Latitude": 50.5862066,
    "Longitude": 8.6742306,
    "Country": "Deutschland"
  },
  {
    "id": 87,
    "City": "Radebeul",
    "count": 94,
    "slur": "",
    "Latitude": 51.0999765,
    "Longitude": 13.6767995,
    "Country": "Deutschland"
  },
  {
    "id": 88,
    "City": "Basel",
    "count": 93,
    "slur": "",
    "Latitude": 47.5581077,
    "Longitude": 7.5878261,
    "Country": "Schweiz/Suisse/Svizzera/Svizra"
  },
  {
    "id": 89,
    "City": "Wuppertal",
    "count": 92,
    "slur": "wuppertal-89",
    "Latitude": 51.264018,
    "Longitude": 7.1780374,
    "Country": "Deutschland"
  },
  {
    "id": 90,
    "City": "Wetzlar",
    "count": 90,
    "slur": "",
    "Latitude": 50.5525346,
    "Longitude": 8.5074406,
    "Country": "Deutschland"
  },
  {
    "id": 91,
    "City": "Wunsiedel",
    "count": 89,
    "slur": "",
    "Latitude": 50.0373241,
    "Longitude": 12.0027278,
    "Country": "Deutschland"
  },
  {
    "id": 92,
    "City": "Düren",
    "count": 88,
    "slur": "",
    "Latitude": 50.8031684,
    "Longitude": 6.4820806,
    "Country": "Deutschland"
  },
  {
    "id": 93,
    "City": "Jena",
    "count": 88,
    "slur": "",
    "Latitude": 50.9281717,
    "Longitude": 11.5879359,
    "Country": "Deutschland"
  },
  {
    "id": 94,
    "City": "Bad Elster",
    "count": 87,
    "slur": "",
    "Latitude": 50.2811665,
    "Longitude": 12.236949,
    "Country": "Deutschland"
  },
  {
    "id": 95,
    "City": "Bremerhaven",
    "count": 86,
    "slur": "bremerhaven-48",
    "Latitude": 53.5505392,
    "Longitude": 8.5851945,
    "Country": "Deutschland"
  },
  {
    "id": 96,
    "City": "Bonn Bad Godesberg",
    "count": 85,
    "slur": "",
    "Latitude": 50.6838092,
    "Longitude": 7.1596754,
    "Country": "Deutschland"
  },
  {
    "id": 97,
    "City": "Eschweiler",
    "count": 84,
    "slur": "",
    "Latitude": 50.8175029,
    "Longitude": 6.2630894,
    "Country": "Deutschland"
  },
  {
    "id": 98,
    "City": "Schwedt/Oder",
    "count": 84,
    "slur": "",
    "Latitude": 53.0586366,
    "Longitude": 14.2840858,
    "Country": "Deutschland"
  },
  {
    "id": 99,
    "City": "Putbus (Rügen)",
    "count": 80,
    "slur": "",
    "Latitude": 54.3534787,
    "Longitude": 13.4749375,
    "Country": "Deutschland"
  },
  {
    "id": 100,
    "City": "Stralsund",
    "count": 79,
    "slur": "",
    "Latitude": 54.3096314,
    "Longitude": 13.0820846,
    "Country": "Deutschland"
  },
  {
    "id": 101,
    "City": "Tecklenburg",
    "count": 79,
    "slur": "",
    "Latitude": 52.2217481,
    "Longitude": 7.8169251,
    "Country": "Deutschland"
  },
  {
    "id": 102,
    "City": "Darmstadt",
    "count": 78,
    "slur": "darmstadt-51",
    "Latitude": 49.8851869,
    "Longitude": 8.6736295,
    "Country": "Deutschland"
  },
  {
    "id": 103,
    "City": "Weyhe",
    "count": 78,
    "slur": "",
    "Latitude": 52.9821257,
    "Longitude": 8.8495643,
    "Country": "Deutschland"
  },
  {
    "id": 104,
    "City": "Kurort Rathen",
    "count": 77,
    "slur": "",
    "Latitude": 50.958235,
    "Longitude": 14.0824651,
    "Country": "Deutschland"
  },
  {
    "id": 105,
    "City": "Trebgast",
    "count": 76,
    "slur": "",
    "Latitude": 50.0679558,
    "Longitude": 11.5515384,
    "Country": "Deutschland"
  },
  {
    "id": 106,
    "City": "Bensheim",
    "count": 74,
    "slur": "",
    "Latitude": 49.6810158,
    "Longitude": 8.6227577,
    "Country": "Deutschland"
  },
  {
    "id": 107,
    "City": "Kempten",
    "count": 74,
    "slur": "kempten-1868",
    "Latitude": 47.7267063,
    "Longitude": 10.3168835,
    "Country": "Deutschland"
  },
  {
    "id": 108,
    "City": "Kassel",
    "count": 73,
    "slur": "kassel-67",
    "Latitude": 51.3154546,
    "Longitude": 9.4924096,
    "Country": "Deutschland"
  },
  {
    "id": 109,
    "City": "Trier",
    "count": 73,
    "slur": "trier-86",
    "Latitude": 49.7596208,
    "Longitude": 6.6441878,
    "Country": "Deutschland"
  },
  {
    "id": 110,
    "City": "Übach-Palenberg",
    "count": 72,
    "slur": "",
    "Latitude": 50.9213799,
    "Longitude": 6.1128698,
    "Country": "Deutschland"
  },
  {
    "id": 111,
    "City": "Ratingen",
    "count": 70,
    "slur": "",
    "Latitude": 51.2973261,
    "Longitude": 6.8493503,
    "Country": "Deutschland"
  },
  {
    "id": 112,
    "City": "Schwetzingen",
    "count": 70,
    "slur": "",
    "Latitude": 49.3832919,
    "Longitude": 8.5735135,
    "Country": "Deutschland"
  },
  {
    "id": 113,
    "City": "Paderborn",
    "count": 66,
    "slur": "paderborn-81",
    "Latitude": 51.71895955,
    "Longitude": 8.764869778,
    "Country": "Deutschland"
  },
  {
    "id": 114,
    "City": "Wiener Neustadt",
    "count": 66,
    "slur": "",
    "Latitude": 47.80708635,
    "Longitude": 16.23326048,
    "Country": "Österreich"
  },
  {
    "id": 115,
    "City": "Altenburg",
    "count": 65,
    "slur": "",
    "Latitude": 50.9852411,
    "Longitude": 12.4340988,
    "Country": "Deutschland"
  },
  {
    "id": 116,
    "City": "Bayreuth",
    "count": 65,
    "slur": "",
    "Latitude": 49.9427202,
    "Longitude": 11.5763079,
    "Country": "Deutschland"
  },
  {
    "id": 117,
    "City": "Dessau-Rosslau",
    "count": 65,
    "slur": "",
    "Latitude": 51.8465924,
    "Longitude": 12.2312238,
    "Country": "Deutschland"
  },
  {
    "id": 118,
    "City": "Frankfurt (Oder)",
    "count": 65,
    "slur": "frankfurt-oder-2089",
    "Latitude": 52.3412273,
    "Longitude": 14.549452,
    "Country": "Deutschland"
  },
  {
    "id": 119,
    "City": "Eisenach",
    "count": 64,
    "slur": "",
    "Latitude": 50.9747134,
    "Longitude": 10.3193565,
    "Country": "Deutschland"
  },
  {
    "id": 120,
    "City": "Hameln",
    "count": 64,
    "slur": "",
    "Latitude": 52.1039941,
    "Longitude": 9.3561569,
    "Country": "Deutschland"
  },
  {
    "id": 121,
    "City": "Rheine",
    "count": 64,
    "slur": "",
    "Latitude": 52.2797707,
    "Longitude": 7.4373615,
    "Country": "Deutschland"
  },
  {
    "id": 122,
    "City": "Würzburg",
    "count": 64,
    "slur": "",
    "Latitude": 49.79245,
    "Longitude": 9.932966,
    "Country": "Deutschland"
  },
  {
    "id": 123,
    "City": "Bad Homburg",
    "count": 63,
    "slur": "",
    "Latitude": 50.2267699,
    "Longitude": 8.6169093,
    "Country": "Deutschland"
  },
  {
    "id": 124,
    "City": "Esslingen",
    "count": 63,
    "slur": "",
    "Latitude": 48.7427584,
    "Longitude": 9.3071685,
    "Country": "Deutschland"
  },
  {
    "id": 125,
    "City": "Hallstadt",
    "count": 63,
    "slur": "",
    "Latitude": 49.928801,
    "Longitude": 10.8754637,
    "Country": "Deutschland"
  },
  {
    "id": 126,
    "City": "Neustrelitz",
    "count": 63,
    "slur": "",
    "Latitude": 53.3617163,
    "Longitude": 13.0630004,
    "Country": "Deutschland"
  },
  {
    "id": 127,
    "City": "Schwerin",
    "count": 62,
    "slur": "",
    "Latitude": 53.6288297,
    "Longitude": 11.4148038,
    "Country": "Deutschland"
  },
  {
    "id": 128,
    "City": "Verona",
    "count": 62,
    "slur": "",
    "Latitude": 45.4384958,
    "Longitude": 10.9924122,
    "Country": "Italia"
  },
  {
    "id": 129,
    "City": "Soest",
    "count": 60,
    "slur": "",
    "Latitude": 51.5725501,
    "Longitude": 8.1061259,
    "Country": "Deutschland"
  },
  {
    "id": 130,
    "City": "Solingen",
    "count": 60,
    "slur": "",
    "Latitude": 51.1721629,
    "Longitude": 7.0845893,
    "Country": "Deutschland"
  },
  {
    "id": 131,
    "City": "Bautzen",
    "count": 58,
    "slur": "",
    "Latitude": 51.1813907,
    "Longitude": 14.4275735,
    "Country": "Deutschland"
  },
  {
    "id": 132,
    "City": "Memmingen",
    "count": 58,
    "slur": "",
    "Latitude": 47.9867696,
    "Longitude": 10.181319,
    "Country": "Deutschland"
  },
  {
    "id": 133,
    "City": "Feldkirch",
    "count": 56,
    "slur": "",
    "Latitude": 47.8647485,
    "Longitude": 7.2730686,
    "Country": "France"
  },
  {
    "id": 134,
    "City": "Siegen",
    "count": 56,
    "slur": "siegen-2081",
    "Latitude": 50.8749804,
    "Longitude": 8.0227233,
    "Country": "Deutschland"
  },
  {
    "id": 135,
    "City": "Sindelfingen",
    "count": 56,
    "slur": "",
    "Latitude": 48.7084162,
    "Longitude": 9.0035455,
    "Country": "Deutschland"
  },
  {
    "id": 136,
    "City": "Brühl",
    "count": 55,
    "slur": "",
    "Latitude": 50.8291313,
    "Longitude": 6.9037057,
    "Country": "Deutschland"
  },
  {
    "id": 137,
    "City": "Friedrichshafen",
    "count": 54,
    "slur": "",
    "Latitude": 47.6500279,
    "Longitude": 9.4800858,
    "Country": "Deutschland"
  },
  {
    "id": 138,
    "City": "Unna",
    "count": 54,
    "slur": "",
    "Latitude": 51.5348835,
    "Longitude": 7.689014,
    "Country": "Deutschland"
  },
  {
    "id": 139,
    "City": "Dreieich",
    "count": 53,
    "slur": "",
    "Latitude": 50.011974,
    "Longitude": 8.7123912,
    "Country": "Deutschland"
  },
  {
    "id": 140,
    "City": "Gütersloh",
    "count": 53,
    "slur": "",
    "Latitude": 51.9063997,
    "Longitude": 8.3782078,
    "Country": "Deutschland"
  },
  {
    "id": 141,
    "City": "Lingen",
    "count": 53,
    "slur": "",
    "Latitude": 52.5224659,
    "Longitude": 7.316584,
    "Country": "Deutschland"
  },
  {
    "id": 142,
    "City": "Bad Schallerbach",
    "count": 52,
    "slur": "",
    "Latitude": 48.2303903,
    "Longitude": 13.9194253,
    "Country": "Österreich"
  },
  {
    "id": 143,
    "City": "Hamm",
    "count": 52,
    "slur": "hamm-2088",
    "Latitude": 51.6804093,
    "Longitude": 7.815197,
    "Country": "Deutschland"
  },
  {
    "id": 144,
    "City": "Passau",
    "count": 52,
    "slur": "",
    "Latitude": 48.5748229,
    "Longitude": 13.4609744,
    "Country": "Deutschland"
  },
  {
    "id": 145,
    "City": "Schweinfurt",
    "count": 52,
    "slur": "",
    "Latitude": 50.0499945,
    "Longitude": 10.233302,
    "Country": "Deutschland"
  },
  {
    "id": 146,
    "City": "Radeberg",
    "count": 51,
    "slur": "",
    "Latitude": 51.1151413,
    "Longitude": 13.9175257,
    "Country": "Deutschland"
  },
  {
    "id": 147,
    "City": "Villach",
    "count": 51,
    "slur": "",
    "Latitude": 46.6167284,
    "Longitude": 13.8500268,
    "Country": "Österreich"
  },
  {
    "id": 148,
    "City": "Wolfsburg",
    "count": 51,
    "slur": "",
    "Latitude": 52.4205588,
    "Longitude": 10.7861682,
    "Country": "Deutschland"
  },
  {
    "id": 149,
    "City": "Esch Alzette/Luxemburg",
    "count": 50,
    "slur": "",
    "Latitude": 49.4959628,
    "Longitude": 5.9850306,
    "Country": "Lëtzebuerg"
  },
  {
    "id": 150,
    "City": "Bietigheim-Bissingen",
    "count": 49,
    "slur": "",
    "Latitude": 48.9502631,
    "Longitude": 9.1383314,
    "Country": "Deutschland"
  },
  {
    "id": 151,
    "City": "Heerlen",
    "count": 49,
    "slur": "",
    "Latitude": 50.8775239,
    "Longitude": 5.981506585,
    "Country": "Nederland"
  },
  {
    "id": 152,
    "City": "Isernhagen",
    "count": 49,
    "slur": "",
    "Latitude": 52.4704753,
    "Longitude": 9.8080015,
    "Country": "Deutschland"
  },
  {
    "id": 153,
    "City": "Konstanz",
    "count": 49,
    "slur": "",
    "Latitude": 47.659216,
    "Longitude": 9.1750718,
    "Country": "Deutschland"
  },
  {
    "id": 154,
    "City": "Meissen",
    "count": 48,
    "slur": "",
    "Latitude": 51.1630871,
    "Longitude": 13.4704939,
    "Country": "Deutschland"
  },
  {
    "id": 155,
    "City": "Offenbach am Main",
    "count": 48,
    "slur": "offenbach-am-main-78",
    "Latitude": 50.1055002,
    "Longitude": 8.7610698,
    "Country": "Deutschland"
  },
  {
    "id": 156,
    "City": "Torgau",
    "count": 48,
    "slur": "",
    "Latitude": 51.5581272,
    "Longitude": 13.0046538,
    "Country": "Deutschland"
  },
  {
    "id": 157,
    "City": "Erlangen",
    "count": 47,
    "slur": "erlangen-55",
    "Latitude": 49.5928616,
    "Longitude": 11.0056,
    "Country": "Deutschland"
  },
  {
    "id": 158,
    "City": "Freiberg",
    "count": 47,
    "slur": "",
    "Latitude": 50.9169415,
    "Longitude": 13.3428889,
    "Country": "Deutschland"
  },
  {
    "id": 159,
    "City": "St. Pölten",
    "count": 47,
    "slur": "",
    "Latitude": 48.2043985,
    "Longitude": 15.6229118,
    "Country": "Österreich"
  },
  {
    "id": 160,
    "City": "Bergneustadt",
    "count": 46,
    "slur": "",
    "Latitude": 51.0207425,
    "Longitude": 7.6490729,
    "Country": "Deutschland"
  },
  {
    "id": 161,
    "City": "Dornbirn",
    "count": 46,
    "slur": "",
    "Latitude": 47.4136351,
    "Longitude": 9.7423762,
    "Country": "Österreich"
  },
  {
    "id": 162,
    "City": "Strausberg",
    "count": 46,
    "slur": "",
    "Latitude": 52.5814009,
    "Longitude": 13.8833952,
    "Country": "Deutschland"
  },
  {
    "id": 163,
    "City": "Würselen-Bardenberg",
    "count": 46,
    "slur": "",
    "Latitude": 50.8410788,
    "Longitude": 6.1112251,
    "Country": "Deutschland"
  },
  {
    "id": 164,
    "City": "Hildesheim",
    "count": 45,
    "slur": "",
    "Latitude": 52.1521636,
    "Longitude": 9.9513046,
    "Country": "Deutschland"
  },
  {
    "id": 165,
    "City": "Luxembourg",
    "count": 45,
    "slur": "",
    "Latitude": 49.8158683,
    "Longitude": 6.1296751,
    "Country": "Lëtzebuerg"
  },
  {
    "id": 166,
    "City": "Rendsburg",
    "count": 45,
    "slur": "",
    "Latitude": 54.3000225,
    "Longitude": 9.6516356,
    "Country": "Deutschland"
  },
  {
    "id": 167,
    "City": "Singen",
    "count": 45,
    "slur": "",
    "Latitude": 47.7617515,
    "Longitude": 8.8348709,
    "Country": "Deutschland"
  },
  {
    "id": 168,
    "City": "Bad Doberan/Heiligendamm",
    "count": 44,
    "slur": "",
    "Latitude": 54.1423664,
    "Longitude": 11.842199,
    "Country": "Deutschland"
  },
  {
    "id": 169,
    "City": "Bad Lauchstädt",
    "count": 44,
    "slur": "",
    "Latitude": 51.3949623,
    "Longitude": 11.9004966,
    "Country": "Deutschland"
  },
  {
    "id": 170,
    "City": "Bünde",
    "count": 44,
    "slur": "",
    "Latitude": 52.2003644,
    "Longitude": 8.5827558,
    "Country": "Deutschland"
  },
  {
    "id": 171,
    "City": "Dietzenbach",
    "count": 44,
    "slur": "",
    "Latitude": 50.0171926,
    "Longitude": 8.784277,
    "Country": "Deutschland"
  },
  {
    "id": 172,
    "City": "Obertraubling",
    "count": 44,
    "slur": "",
    "Latitude": 48.9657993,
    "Longitude": 12.1671324,
    "Country": "Deutschland"
  },
  {
    "id": 173,
    "City": "Neustadt an der Weinstrasse",
    "count": 43,
    "slur": "",
    "Latitude": 49.3539802,
    "Longitude": 8.1350021,
    "Country": "Deutschland"
  },
  {
    "id": 174,
    "City": "Suhl",
    "count": 43,
    "slur": "",
    "Latitude": 50.6086518,
    "Longitude": 10.6926437,
    "Country": "Deutschland"
  },
  {
    "id": 175,
    "City": "Alsdorf",
    "count": 42,
    "slur": "",
    "Latitude": 49.8885782,
    "Longitude": 6.4643156,
    "Country": "Deutschland"
  },
  {
    "id": 176,
    "City": "Gmunden",
    "count": 42,
    "slur": "",
    "Latitude": 47.9185855,
    "Longitude": 13.8003048,
    "Country": "Österreich"
  },
  {
    "id": 177,
    "City": "Langenhagen",
    "count": 41,
    "slur": "",
    "Latitude": 52.443118,
    "Longitude": 9.738994,
    "Country": "Deutschland"
  },
  {
    "id": 178,
    "City": "Leinfelden-Echterdingen",
    "count": 41,
    "slur": "",
    "Latitude": 48.6901796,
    "Longitude": 9.1525725,
    "Country": "Deutschland"
  },
  {
    "id": 179,
    "City": "Neuruppin",
    "count": 41,
    "slur": "",
    "Latitude": 52.9243859,
    "Longitude": 12.8092919,
    "Country": "Deutschland"
  },
  {
    "id": 180,
    "City": "Poysbrunn",
    "count": 41,
    "slur": "",
    "Latitude": 48.7193088,
    "Longitude": 16.6287778,
    "Country": "Österreich"
  },
  {
    "id": 181,
    "City": "Thale",
    "count": 41,
    "slur": "",
    "Latitude": 51.7527606,
    "Longitude": 11.037722,
    "Country": "Deutschland"
  },
  {
    "id": 182,
    "City": "Bergheim",
    "count": 40,
    "slur": "",
    "Latitude": 48.2056672,
    "Longitude": 7.3621755,
    "Country": "France"
  },
  {
    "id": 183,
    "City": "Hof",
    "count": 40,
    "slur": "",
    "Latitude": 50.3219015,
    "Longitude": 11.9178807,
    "Country": "Deutschland"
  },
  {
    "id": 184,
    "City": "Aurich",
    "count": 39,
    "slur": "",
    "Latitude": 53.51228535,
    "Longitude": 7.391528704,
    "Country": "Deutschland"
  },
  {
    "id": 185,
    "City": "Euskirchen",
    "count": 39,
    "slur": "",
    "Latitude": 50.6612623,
    "Longitude": 6.7871219,
    "Country": "Deutschland"
  },
  {
    "id": 186,
    "City": "Weinheim",
    "count": 39,
    "slur": "",
    "Latitude": 49.5462349,
    "Longitude": 8.6717458,
    "Country": "Deutschland"
  },
  {
    "id": 187,
    "City": "Bruchsal",
    "count": 38,
    "slur": "",
    "Latitude": 49.124118,
    "Longitude": 8.5980244,
    "Country": "Deutschland"
  },
  {
    "id": 188,
    "City": "Frankenthal",
    "count": 38,
    "slur": "",
    "Latitude": 49.5445804,
    "Longitude": 8.3540061,
    "Country": "Deutschland"
  },
  {
    "id": 189,
    "City": "Gleisdorf",
    "count": 38,
    "slur": "",
    "Latitude": 47.106751,
    "Longitude": 15.71000656,
    "Country": "Österreich"
  },
  {
    "id": 190,
    "City": "Pulheim",
    "count": 38,
    "slur": "",
    "Latitude": 50.9979888,
    "Longitude": 6.8025409,
    "Country": "Deutschland"
  },
  {
    "id": 191,
    "City": "Reutlingen",
    "count": 38,
    "slur": "",
    "Latitude": 48.4919508,
    "Longitude": 9.2114144,
    "Country": "Deutschland"
  },
  {
    "id": 192,
    "City": "Troisdorf",
    "count": 38,
    "slur": "",
    "Latitude": 50.8153071,
    "Longitude": 7.1593271,
    "Country": "Deutschland"
  },
  {
    "id": 193,
    "City": "Arnstadt",
    "count": 37,
    "slur": "",
    "Latitude": 50.8349097,
    "Longitude": 10.9461479,
    "Country": "Deutschland"
  },
  {
    "id": 194,
    "City": "Bad Homburg v. d. Höhe",
    "count": 37,
    "slur": "",
    "Latitude": 50.2267699,
    "Longitude": 8.6169093,
    "Country": "Deutschland"
  },
  {
    "id": 195,
    "City": "Neu-Isenburg/Frankfurt",
    "count": 37,
    "slur": "",
    "Latitude": 50.05885695,
    "Longitude": 8.679685504,
    "Country": "Deutschland"
  },
  {
    "id": 196,
    "City": "Rheda-Wiedenbrück",
    "count": 37,
    "slur": "rheda-wiedenbrueck-1984",
    "Latitude": 51.8428202,
    "Longitude": 8.2985615,
    "Country": "Deutschland"
  },
  {
    "id": 197,
    "City": "Wismar",
    "count": 37,
    "slur": "",
    "Latitude": 53.8909832,
    "Longitude": 11.4647932,
    "Country": "Deutschland"
  },
  {
    "id": 198,
    "City": "Pirna",
    "count": 36,
    "slur": "",
    "Latitude": 50.9616923,
    "Longitude": 13.9386643,
    "Country": "Deutschland"
  },
  {
    "id": 199,
    "City": "Regenstauf",
    "count": 36,
    "slur": "",
    "Latitude": 49.1239753,
    "Longitude": 12.1278232,
    "Country": "Deutschland"
  },
  {
    "id": 200,
    "City": "Altomünster",
    "count": 35,
    "slur": "",
    "Latitude": 48.3875047,
    "Longitude": 11.2572114,
    "Country": "Deutschland"
  },
  {
    "id": 201,
    "City": "Bad Hönningen",
    "count": 35,
    "slur": "",
    "Latitude": 50.5157382,
    "Longitude": 7.3065922,
    "Country": "Deutschland"
  },
  {
    "id": 202,
    "City": "Coesfeld",
    "count": 35,
    "slur": "",
    "Latitude": 51.9458943,
    "Longitude": 7.1691108,
    "Country": "Deutschland"
  },
  {
    "id": 203,
    "City": "Gifhorn",
    "count": 35,
    "slur": "",
    "Latitude": 52.57806125,
    "Longitude": 10.6613448,
    "Country": "Deutschland"
  },
  {
    "id": 204,
    "City": "Neumünster",
    "count": 35,
    "slur": "",
    "Latitude": 54.0757442,
    "Longitude": 9.9815377,
    "Country": "Deutschland"
  },
  {
    "id": 205,
    "City": "Riesa",
    "count": 35,
    "slur": "",
    "Latitude": 51.3038241,
    "Longitude": 13.308828,
    "Country": "Deutschland"
  },
  {
    "id": 206,
    "City": "Roth",
    "count": 35,
    "slur": "",
    "Latitude": 49.1952934,
    "Longitude": 11.05665234,
    "Country": "Deutschland"
  },
  {
    "id": 207,
    "City": "Traun",
    "count": 35,
    "slur": "",
    "Latitude": 48.2214369,
    "Longitude": 14.2368078,
    "Country": "Österreich"
  },
  {
    "id": 208,
    "City": "Unterschleissheim",
    "count": 35,
    "slur": "",
    "Latitude": 48.2730686,
    "Longitude": 11.5702716,
    "Country": "Deutschland"
  },
  {
    "id": 209,
    "City": "Freiburg",
    "count": 34,
    "slur": "",
    "Latitude": 47.9960901,
    "Longitude": 7.8494005,
    "Country": "Deutschland"
  },
  {
    "id": 210,
    "City": "Gersthofen (Bei Augsburg)",
    "count": 34,
    "slur": "",
    "Latitude": "",
    "Longitude": "",
    "Country": ""
  },
  {
    "id": 211,
    "City": "Bad Zwischenahn",
    "count": 33,
    "slur": "",
    "Latitude": 53.185155,
    "Longitude": 8.0034877,
    "Country": "Deutschland"
  },
  {
    "id": 212,
    "City": "Breisach am Rhein",
    "count": 33,
    "slur": "",
    "Latitude": 48.0291415,
    "Longitude": 7.5806123,
    "Country": "Deutschland"
  },
  {
    "id": 213,
    "City": "Celle",
    "count": 33,
    "slur": "",
    "Latitude": 52.624056,
    "Longitude": 10.081052,
    "Country": "Deutschland"
  },
  {
    "id": 214,
    "City": "Rosenburg",
    "count": 33,
    "slur": "",
    "Latitude": 48.62761765,
    "Longitude": 15.63423915,
    "Country": "Österreich"
  },
  {
    "id": 215,
    "City": "Aalen/Unterkochen",
    "count": 32,
    "slur": "",
    "Latitude": 48.8168776,
    "Longitude": 10.1253495,
    "Country": "Deutschland"
  },
  {
    "id": 216,
    "City": "Gloggnitz",
    "count": 32,
    "slur": "",
    "Latitude": 47.6757858,
    "Longitude": 15.9374085,
    "Country": "Österreich"
  },
  {
    "id": 217,
    "City": "Gotha",
    "count": 32,
    "slur": "",
    "Latitude": 50.9494849,
    "Longitude": 10.7014435,
    "Country": "Deutschland"
  },
  {
    "id": 218,
    "City": "Gummersbach",
    "count": 32,
    "slur": "",
    "Latitude": 51.0277658,
    "Longitude": 7.5630545,
    "Country": "Deutschland"
  },
  {
    "id": 219,
    "City": "Hohenems",
    "count": 32,
    "slur": "",
    "Latitude": 47.3639973,
    "Longitude": 9.689271,
    "Country": "Österreich"
  },
  {
    "id": 220,
    "City": "Limburg",
    "count": 32,
    "slur": "",
    "Latitude": 51.2015196,
    "Longitude": 5.9046302,
    "Country": "Nederland"
  },
  {
    "id": 221,
    "City": "Ludwigshafen am Rhein",
    "count": 32,
    "slur": "ludwigshafen-am-rhein-73",
    "Latitude": 49.4704113,
    "Longitude": 8.4381568,
    "Country": "Deutschland"
  },
  {
    "id": 222,
    "City": "Wilhelmshaven",
    "count": 32,
    "slur": "",
    "Latitude": 53.5278793,
    "Longitude": 8.106301,
    "Country": "Deutschland"
  },
  {
    "id": 223,
    "City": "Bonn-Bad Godesberg Schweinheim",
    "count": 31,
    "slur": "",
    "Latitude": 50.6824332,
    "Longitude": 7.1406625,
    "Country": "Deutschland"
  },
  {
    "id": 224,
    "City": "Ingelheim am Rhein",
    "count": 31,
    "slur": "",
    "Latitude": 49.9752749,
    "Longitude": 8.0547274,
    "Country": "Deutschland"
  },
  {
    "id": 225,
    "City": "Kehl",
    "count": 31,
    "slur": "",
    "Latitude": 48.5728929,
    "Longitude": 7.8109768,
    "Country": "Deutschland"
  },
  {
    "id": 226,
    "City": "Kufstein",
    "count": 31,
    "slur": "",
    "Latitude": 47.582996,
    "Longitude": 12.1692134,
    "Country": "Österreich"
  },
  {
    "id": 227,
    "City": "Löbau",
    "count": 31,
    "slur": "",
    "Latitude": 51.0947288,
    "Longitude": 14.6692423,
    "Country": "Deutschland"
  },
  {
    "id": 228,
    "City": "Ossiach",
    "count": 31,
    "slur": "",
    "Latitude": 46.6758288,
    "Longitude": 13.9823604,
    "Country": "Österreich"
  },
  {
    "id": 229,
    "City": "Weiz",
    "count": 31,
    "slur": "",
    "Latitude": 47.2174206,
    "Longitude": 15.6221694,
    "Country": "Österreich"
  },
  {
    "id": 230,
    "City": "Ötigheim",
    "count": 31,
    "slur": "",
    "Latitude": 48.8894679,
    "Longitude": 8.2383062,
    "Country": "Deutschland"
  },
  {
    "id": 231,
    "City": "Coburg",
    "count": 30,
    "slur": "",
    "Latitude": 50.258112,
    "Longitude": 10.964463,
    "Country": "Deutschland"
  },
  {
    "id": 232,
    "City": "Erding",
    "count": 30,
    "slur": "",
    "Latitude": 48.3064441,
    "Longitude": 11.9076579,
    "Country": "Deutschland"
  },
  {
    "id": 233,
    "City": "Pfronten-Weissbach",
    "count": 30,
    "slur": "",
    "Latitude": 47.5959074,
    "Longitude": 10.5572994,
    "Country": "Deutschland"
  },
  {
    "id": 234,
    "City": "Schmallenberg",
    "count": 30,
    "slur": "",
    "Latitude": 51.1525937,
    "Longitude": 8.2836014,
    "Country": "Deutschland"
  },
  {
    "id": 235,
    "City": "Tübingen",
    "count": 30,
    "slur": "",
    "Latitude": 48.5236164,
    "Longitude": 9.0535531,
    "Country": "Deutschland"
  },
  {
    "id": 236,
    "City": "Buxtehude",
    "count": 29,
    "slur": "",
    "Latitude": 53.4767351,
    "Longitude": 9.7003941,
    "Country": "Deutschland"
  },
  {
    "id": 237,
    "City": "Deggendorf",
    "count": 29,
    "slur": "",
    "Latitude": 48.7814115,
    "Longitude": 13.0006423,
    "Country": "Deutschland"
  },
  {
    "id": 238,
    "City": "Latschach",
    "count": 29,
    "slur": "",
    "Latitude": 46.5823834,
    "Longitude": 14.0323138,
    "Country": "Österreich"
  },
  {
    "id": 239,
    "City": "Rastatt",
    "count": 29,
    "slur": "",
    "Latitude": 48.8574209,
    "Longitude": 8.2088088,
    "Country": "Deutschland"
  },
  {
    "id": 240,
    "City": "Ravensburg",
    "count": 29,
    "slur": "",
    "Latitude": 47.7811014,
    "Longitude": 9.612468,
    "Country": "Deutschland"
  },
  {
    "id": 241,
    "City": "Villingen-Schwenningen",
    "count": 29,
    "slur": "",
    "Latitude": 48.0632359,
    "Longitude": 8.4945022,
    "Country": "Deutschland"
  },
  {
    "id": 242,
    "City": "Wesel",
    "count": 29,
    "slur": "",
    "Latitude": 51.6576909,
    "Longitude": 6.617087,
    "Country": "Deutschland"
  },
  {
    "id": 243,
    "City": "Worpswede",
    "count": 29,
    "slur": "",
    "Latitude": 53.2230018,
    "Longitude": 8.9283482,
    "Country": "Deutschland"
  },
  {
    "id": 244,
    "City": "Ballenstedt",
    "count": 28,
    "slur": "",
    "Latitude": 51.722527,
    "Longitude": 11.2290268,
    "Country": "Deutschland"
  },
  {
    "id": 245,
    "City": "Erfurt-Brühl",
    "count": 28,
    "slur": "",
    "Latitude": 50.97384855,
    "Longitude": 11.01511793,
    "Country": "Deutschland"
  },
  {
    "id": 246,
    "City": "Rosenheim",
    "count": 28,
    "slur": "",
    "Latitude": 47.8539273,
    "Longitude": 12.127262,
    "Country": "Deutschland"
  },
  {
    "id": 247,
    "City": "Bludenz",
    "count": 27,
    "slur": "",
    "Latitude": 47.153037,
    "Longitude": 9.8219314,
    "Country": "Österreich"
  },
  {
    "id": 248,
    "City": "Limbach-Oberfrohna",
    "count": 27,
    "slur": "",
    "Latitude": 50.8608138,
    "Longitude": 12.7545462,
    "Country": "Deutschland"
  },
  {
    "id": 249,
    "City": "Neu-Ulm",
    "count": 27,
    "slur": "",
    "Latitude": 48.3954965,
    "Longitude": 10.00589,
    "Country": "Deutschland"
  },
  {
    "id": 250,
    "City": "Stade",
    "count": 27,
    "slur": "",
    "Latitude": 53.599794,
    "Longitude": 9.475438,
    "Country": "Deutschland"
  },
  {
    "id": 251,
    "City": "Weimar",
    "count": 27,
    "slur": "",
    "Latitude": 50.9810486,
    "Longitude": 11.3296637,
    "Country": "Deutschland"
  },
  {
    "id": 252,
    "City": "Wunstorf",
    "count": 27,
    "slur": "",
    "Latitude": 52.4257979,
    "Longitude": 9.4294425,
    "Country": "Deutschland"
  },
  {
    "id": 253,
    "City": "Georgsmarienhütte",
    "count": 26,
    "slur": "",
    "Latitude": 52.2,
    "Longitude": 8.05,
    "Country": "Deutschland"
  },
  {
    "id": 254,
    "City": "Greifswald",
    "count": 26,
    "slur": "",
    "Latitude": 54.095791,
    "Longitude": 13.3815238,
    "Country": "Deutschland"
  },
  {
    "id": 255,
    "City": "Korbach",
    "count": 26,
    "slur": "",
    "Latitude": 51.2743649,
    "Longitude": 8.8720648,
    "Country": "Deutschland"
  },
  {
    "id": 256,
    "City": "Lünen",
    "count": 26,
    "slur": "",
    "Latitude": 51.6142482,
    "Longitude": 7.5228088,
    "Country": "Deutschland"
  },
  {
    "id": 257,
    "City": "Norderstedt",
    "count": 26,
    "slur": "",
    "Latitude": 53.7089898,
    "Longitude": 9.9891914,
    "Country": "Deutschland"
  },
  {
    "id": 258,
    "City": "Witten",
    "count": 26,
    "slur": "",
    "Latitude": 51.4370171,
    "Longitude": 7.335124,
    "Country": "Deutschland"
  },
  {
    "id": 259,
    "City": "Dinslaken",
    "count": 25,
    "slur": "",
    "Latitude": 51.5623618,
    "Longitude": 6.7345106,
    "Country": "Deutschland"
  },
  {
    "id": 260,
    "City": "Görlitz",
    "count": 25,
    "slur": "",
    "Latitude": 51.1563185,
    "Longitude": 14.991018,
    "Country": "Deutschland"
  },
  {
    "id": 261,
    "City": "Hall in Tirol",
    "count": 25,
    "slur": "",
    "Latitude": 47.2816648,
    "Longitude": 11.5075337,
    "Country": "Österreich"
  },
  {
    "id": 262,
    "City": "Halle (Westfalen)",
    "count": 25,
    "slur": "halle-westfalen-2080",
    "Latitude": 52.0603355,
    "Longitude": 8.3615619,
    "Country": "Deutschland"
  },
  {
    "id": 263,
    "City": "Landshut",
    "count": 25,
    "slur": "",
    "Latitude": 48.536217,
    "Longitude": 12.1516551,
    "Country": "Deutschland"
  },
  {
    "id": 264,
    "City": "Lähden-Ahmsen",
    "count": 25,
    "slur": "",
    "Latitude": 52.7838644,
    "Longitude": 7.6275422,
    "Country": "Deutschland"
  },
  {
    "id": 265,
    "City": "Offenburg",
    "count": 25,
    "slur": "",
    "Latitude": 48.4716556,
    "Longitude": 7.944378,
    "Country": "Deutschland"
  },
  {
    "id": 266,
    "City": "Papenburg",
    "count": 25,
    "slur": "",
    "Latitude": 53.0898943,
    "Longitude": 7.3869777,
    "Country": "Deutschland"
  },
  {
    "id": 267,
    "City": "Stadt Haag",
    "count": 25,
    "slur": "",
    "Latitude": 48.1156834,
    "Longitude": 14.5660137,
    "Country": "Österreich"
  },
  {
    "id": 268,
    "City": "Weissenkirchen",
    "count": 25,
    "slur": "",
    "Latitude": 49.8339446,
    "Longitude": 20.6731487,
    "Country": "Polska"
  },
  {
    "id": 269,
    "City": "Altusried",
    "count": 24,
    "slur": "",
    "Latitude": 47.8052615,
    "Longitude": 10.2136981,
    "Country": "Deutschland"
  },
  {
    "id": 270,
    "City": "Burglengenfeld",
    "count": 24,
    "slur": "burglengenfeld-1581",
    "Latitude": 49.2071077,
    "Longitude": 12.03996,
    "Country": "Deutschland"
  },
  {
    "id": 271,
    "City": "Cham",
    "count": 24,
    "slur": "",
    "Latitude": 45.9246705,
    "Longitude": 6.8727506,
    "Country": "France"
  },
  {
    "id": 272,
    "City": "Cuxhaven",
    "count": 24,
    "slur": "",
    "Latitude": 53.58724195,
    "Longitude": 8.884079151,
    "Country": "Deutschland"
  },
  {
    "id": 273,
    "City": "Gladbeck",
    "count": 24,
    "slur": "",
    "Latitude": 51.5718665,
    "Longitude": 6.9877343,
    "Country": "Deutschland"
  },
  {
    "id": 274,
    "City": "Lauf",
    "count": 24,
    "slur": "",
    "Latitude": 48.6485831,
    "Longitude": 8.1292199,
    "Country": "Deutschland"
  },
  {
    "id": 275,
    "City": "Mödling",
    "count": 24,
    "slur": "",
    "Latitude": 48.0855922,
    "Longitude": 16.2833526,
    "Country": "Österreich"
  },
  {
    "id": 276,
    "City": "Schorndorf",
    "count": 24,
    "slur": "",
    "Latitude": 48.8055169,
    "Longitude": 9.5272665,
    "Country": "Deutschland"
  },
  {
    "id": 277,
    "City": "Böblingen",
    "count": 23,
    "slur": "",
    "Latitude": 48.684969,
    "Longitude": 9.0113444,
    "Country": "Deutschland"
  },
  {
    "id": 278,
    "City": "Friesach",
    "count": 23,
    "slur": "",
    "Latitude": 46.9520636,
    "Longitude": 14.4052266,
    "Country": "Österreich"
  },
  {
    "id": 279,
    "City": "Greiz",
    "count": 23,
    "slur": "",
    "Latitude": 50.7783814,
    "Longitude": 12.0763533,
    "Country": "Deutschland"
  },
  {
    "id": 280,
    "City": "Hoyerswerda",
    "count": 23,
    "slur": "",
    "Latitude": 51.4333103,
    "Longitude": 14.250083,
    "Country": "Deutschland"
  },
  {
    "id": 281,
    "City": "Luxembourg-City",
    "count": 23,
    "slur": "",
    "Latitude": 49.6112768,
    "Longitude": 6.129799,
    "Country": "Lëtzebuerg"
  },
  {
    "id": 282,
    "City": "Marburg",
    "count": 23,
    "slur": "",
    "Latitude": 50.8090106,
    "Longitude": 8.7704695,
    "Country": "Deutschland"
  },
  {
    "id": 283,
    "City": "Mattsee",
    "count": 23,
    "slur": "",
    "Latitude": 47.9699824,
    "Longitude": 13.1026633,
    "Country": "Österreich"
  },
  {
    "id": 284,
    "City": "Salzgitter",
    "count": 23,
    "slur": "",
    "Latitude": 52.1503721,
    "Longitude": 10.3593147,
    "Country": "Deutschland"
  },
  {
    "id": 285,
    "City": "Schleswig",
    "count": 23,
    "slur": "",
    "Latitude": 54.51851,
    "Longitude": 9.5653284,
    "Country": "Deutschland"
  },
  {
    "id": 286,
    "City": "Bad Salzschlirf",
    "count": 22,
    "slur": "",
    "Latitude": 50.6323557,
    "Longitude": 9.5003692,
    "Country": "Deutschland"
  },
  {
    "id": 287,
    "City": "Hof bei Salzburg",
    "count": 22,
    "slur": "",
    "Latitude": 47.8197109,
    "Longitude": 13.2130752,
    "Country": "Österreich"
  },
  {
    "id": 288,
    "City": "Marnach",
    "count": 22,
    "slur": "",
    "Latitude": 50.0535944,
    "Longitude": 6.0621006,
    "Country": "Lëtzebuerg"
  },
  {
    "id": 289,
    "City": "Monheim am Rhein",
    "count": 22,
    "slur": "",
    "Latitude": 51.0908881,
    "Longitude": 6.8832264,
    "Country": "Deutschland"
  },
  {
    "id": 290,
    "City": "Oberursel",
    "count": 22,
    "slur": "",
    "Latitude": 50.2005518,
    "Longitude": 8.580452,
    "Country": "Deutschland"
  },
  {
    "id": 291,
    "City": "Oranienburg",
    "count": 22,
    "slur": "",
    "Latitude": 52.7529379,
    "Longitude": 13.2457591,
    "Country": "Deutschland"
  },
  {
    "id": 292,
    "City": "Osterholz-Scharmbeck",
    "count": 22,
    "slur": "",
    "Latitude": 53.2281763,
    "Longitude": 8.7948239,
    "Country": "Deutschland"
  },
  {
    "id": 293,
    "City": "Recklinghausen",
    "count": 22,
    "slur": "recklinghausen-83",
    "Latitude": 51.6143815,
    "Longitude": 7.1978546,
    "Country": "Deutschland"
  },
  {
    "id": 294,
    "City": "Warnemünde",
    "count": 22,
    "slur": "",
    "Latitude": 54.1779039,
    "Longitude": 12.0812875,
    "Country": "Deutschland"
  },
  {
    "id": 295,
    "City": "Wiesbaden-Nordenstadt",
    "count": 22,
    "slur": "",
    "Latitude": 50.0557128,
    "Longitude": 8.3369872,
    "Country": "Deutschland"
  },
  {
    "id": 296,
    "City": "Brunsbüttel",
    "count": 21,
    "slur": "",
    "Latitude": 53.8972549,
    "Longitude": 9.1395423,
    "Country": "Deutschland"
  },
  {
    "id": 297,
    "City": "Crimmitschau",
    "count": 21,
    "slur": "",
    "Latitude": 50.8162378,
    "Longitude": 12.3867217,
    "Country": "Deutschland"
  },
  {
    "id": 298,
    "City": "Elmshorn",
    "count": 21,
    "slur": "",
    "Latitude": 53.7532486,
    "Longitude": 9.6524559,
    "Country": "Deutschland"
  },
  {
    "id": 299,
    "City": "Hürth",
    "count": 21,
    "slur": "",
    "Latitude": 50.8807379,
    "Longitude": 6.876568,
    "Country": "Deutschland"
  },
  {
    "id": 300,
    "City": "Ilsenburg",
    "count": 21,
    "slur": "",
    "Latitude": 51.8667349,
    "Longitude": 10.6831785,
    "Country": "Deutschland"
  },
  {
    "id": 301,
    "City": "Kusel",
    "count": 21,
    "slur": "",
    "Latitude": 49.5399844,
    "Longitude": 7.4008479,
    "Country": "Deutschland"
  },
  {
    "id": 302,
    "City": "Lüdenscheid",
    "count": 21,
    "slur": "",
    "Latitude": 51.218116,
    "Longitude": 7.6395513,
    "Country": "Deutschland"
  },
  {
    "id": 303,
    "City": "Neunkirchen",
    "count": 21,
    "slur": "",
    "Latitude": 49.3533493,
    "Longitude": 6.5564888,
    "Country": "France"
  },
  {
    "id": 304,
    "City": "Schwechat-Rannersdorf",
    "count": 21,
    "slur": "",
    "Latitude": 48.1239096,
    "Longitude": 16.4653307,
    "Country": "Österreich"
  },
  {
    "id": 305,
    "City": "Wels",
    "count": 21,
    "slur": "",
    "Latitude": 52.2928116,
    "Longitude": -3.73893,
    "Country": "United Kingdom"
  },
  {
    "id": 306,
    "City": "Gars am Kamp",
    "count": 20,
    "slur": "",
    "Latitude": 48.5951053,
    "Longitude": 15.6594592,
    "Country": "Österreich"
  },
  {
    "id": 307,
    "City": "Melle",
    "count": 20,
    "slur": "",
    "Latitude": 44.5620476,
    "Longitude": 7.32049,
    "Country": "Italia"
  },
  {
    "id": 308,
    "City": "Nürburg/Eifel",
    "count": 20,
    "slur": "",
    "Latitude": 50.331778,
    "Longitude": 6.9467712,
    "Country": "Deutschland"
  },
  {
    "id": 309,
    "City": "Obernkirchen",
    "count": 20,
    "slur": "",
    "Latitude": 52.26378085,
    "Longitude": 9.154346612,
    "Country": "Deutschland"
  },
  {
    "id": 310,
    "City": "Salzwedel",
    "count": 20,
    "slur": "",
    "Latitude": 52.8528456,
    "Longitude": 11.1539699,
    "Country": "Deutschland"
  },
  {
    "id": 311,
    "City": "Ternitz",
    "count": 20,
    "slur": "",
    "Latitude": 47.7066007,
    "Longitude": 16.0194688,
    "Country": "Österreich"
  },
  {
    "id": 312,
    "City": "Weissenfels",
    "count": 20,
    "slur": "",
    "Latitude": 46.4957682,
    "Longitude": 13.6549706,
    "Country": "Italia"
  },
  {
    "id": 313,
    "City": "Wörgl",
    "count": 20,
    "slur": "",
    "Latitude": 47.48033265,
    "Longitude": 12.07928068,
    "Country": "Österreich"
  },
  {
    "id": 314,
    "City": "Zielitz",
    "count": 20,
    "slur": "",
    "Latitude": 52.2884504,
    "Longitude": 11.6752462,
    "Country": "Deutschland"
  },
  {
    "id": 315,
    "City": "Borken",
    "count": 19,
    "slur": "",
    "Latitude": 51.8443183,
    "Longitude": 6.8582247,
    "Country": "Deutschland"
  },
  {
    "id": 316,
    "City": "Döbeln",
    "count": 19,
    "slur": "",
    "Latitude": 51.1167001,
    "Longitude": 13.1166999,
    "Country": "Deutschland"
  },
  {
    "id": 317,
    "City": "Günzburg",
    "count": 19,
    "slur": "",
    "Latitude": 48.4539028,
    "Longitude": 10.2785509,
    "Country": "Deutschland"
  },
  {
    "id": 318,
    "City": "Hartberg",
    "count": 19,
    "slur": "",
    "Latitude": 47.2809371,
    "Longitude": 15.9691769,
    "Country": "Österreich"
  },
  {
    "id": 319,
    "City": "Hollabrunn",
    "count": 19,
    "slur": "",
    "Latitude": 48.54886325,
    "Longitude": 16.07189696,
    "Country": "Österreich"
  },
  {
    "id": 320,
    "City": "Meschede",
    "count": 19,
    "slur": "",
    "Latitude": 51.3436532,
    "Longitude": 8.2859257,
    "Country": "Deutschland"
  },
  {
    "id": 321,
    "City": "Speyer",
    "count": 19,
    "slur": "",
    "Latitude": 49.3165553,
    "Longitude": 8.433615,
    "Country": "Deutschland"
  },
  {
    "id": 322,
    "City": "Steyr",
    "count": 19,
    "slur": "",
    "Latitude": 48.0390046,
    "Longitude": 14.4191276,
    "Country": "Österreich"
  },
  {
    "id": 323,
    "City": "Sylt",
    "count": 19,
    "slur": "sylt-2142",
    "Latitude": 54.9017773,
    "Longitude": 8.331346032,
    "Country": "Deutschland"
  },
  {
    "id": 324,
    "City": "Vechta",
    "count": 19,
    "slur": "",
    "Latitude": 52.7310691,
    "Longitude": 8.2873162,
    "Country": "Deutschland"
  },
  {
    "id": 325,
    "City": "Werl",
    "count": 19,
    "slur": "",
    "Latitude": 51.5533457,
    "Longitude": 7.9155558,
    "Country": "Deutschland"
  },
  {
    "id": 326,
    "City": "Bad Orb",
    "count": 18,
    "slur": "",
    "Latitude": 50.2276774,
    "Longitude": 9.3485142,
    "Country": "Deutschland"
  },
  {
    "id": 327,
    "City": "Bergen auf Rügen",
    "count": 18,
    "slur": "",
    "Latitude": 54.4172673,
    "Longitude": 13.4305634,
    "Country": "Deutschland"
  },
  {
    "id": 328,
    "City": "Bocholt",
    "count": 18,
    "slur": "",
    "Latitude": 51.8382715,
    "Longitude": 6.6148669,
    "Country": "Deutschland"
  },
  {
    "id": 329,
    "City": "Bregenz/Österreich",
    "count": 18,
    "slur": "",
    "Latitude": 47.5025779,
    "Longitude": 9.7472924,
    "Country": "Österreich"
  },
  {
    "id": 330,
    "City": "Coswig",
    "count": 18,
    "slur": "",
    "Latitude": 51.1267305,
    "Longitude": 13.5783983,
    "Country": "Deutschland"
  },
  {
    "id": 331,
    "City": "Eckernförde",
    "count": 18,
    "slur": "",
    "Latitude": 54.4716456,
    "Longitude": 9.8374947,
    "Country": "Deutschland"
  },
  {
    "id": 332,
    "City": "Eichstätt",
    "count": 18,
    "slur": "",
    "Latitude": 48.8933417,
    "Longitude": 11.1838965,
    "Country": "Deutschland"
  },
  {
    "id": 333,
    "City": "Leer",
    "count": 18,
    "slur": "",
    "Latitude": 53.20509275,
    "Longitude": 7.459463962,
    "Country": "Deutschland"
  },
  {
    "id": 334,
    "City": "Leoben",
    "count": 18,
    "slur": "",
    "Latitude": 47.3805128,
    "Longitude": 15.0947756,
    "Country": "Österreich"
  },
  {
    "id": 335,
    "City": "Saalfeld",
    "count": 18,
    "slur": "",
    "Latitude": 50.6481277,
    "Longitude": 11.3616652,
    "Country": "Deutschland"
  },
  {
    "id": 336,
    "City": "Tuttlingen",
    "count": 18,
    "slur": "",
    "Latitude": 47.9844315,
    "Longitude": 8.8186606,
    "Country": "Deutschland"
  },
  {
    "id": 337,
    "City": "Weingarten",
    "count": 18,
    "slur": "",
    "Latitude": 47.8075299,
    "Longitude": 9.6431145,
    "Country": "Deutschland"
  },
  {
    "id": 338,
    "City": "Zwettl",
    "count": 18,
    "slur": "",
    "Latitude": 48.602082,
    "Longitude": 14.9851629,
    "Country": "Österreich"
  },
  {
    "id": 339,
    "City": "Aichach Ot Obermauerbach",
    "count": 17,
    "slur": "",
    "Latitude": "",
    "Longitude": "",
    "Country": ""
  },
  {
    "id": 340,
    "City": "Attendorn",
    "count": 17,
    "slur": "",
    "Latitude": 51.1250541,
    "Longitude": 7.9010992,
    "Country": "Deutschland"
  },
  {
    "id": 341,
    "City": "Delbrück",
    "count": 17,
    "slur": "",
    "Latitude": 51.7649178,
    "Longitude": 8.5594953,
    "Country": "Deutschland"
  },
  {
    "id": 342,
    "City": "Dessau",
    "count": 17,
    "slur": "",
    "Latitude": 51.8309956,
    "Longitude": 12.2430723,
    "Country": "Deutschland"
  },
  {
    "id": 343,
    "City": "Emden",
    "count": 17,
    "slur": "",
    "Latitude": 53.3670541,
    "Longitude": 7.2058304,
    "Country": "Deutschland"
  },
  {
    "id": 344,
    "City": "Freistadt",
    "count": 17,
    "slur": "",
    "Latitude": 48.5112961,
    "Longitude": 14.5047566,
    "Country": "Österreich"
  },
  {
    "id": 345,
    "City": "Glauchau",
    "count": 17,
    "slur": "",
    "Latitude": 50.8199031,
    "Longitude": 12.5397873,
    "Country": "Deutschland"
  },
  {
    "id": 346,
    "City": "Miesbach",
    "count": 17,
    "slur": "",
    "Latitude": 47.790025,
    "Longitude": 11.8335417,
    "Country": "Deutschland"
  },
  {
    "id": 347,
    "City": "Naumburg",
    "count": 17,
    "slur": "",
    "Latitude": 51.1525648,
    "Longitude": 11.8099186,
    "Country": "Deutschland"
  },
  {
    "id": 348,
    "City": "Oberstdorf",
    "count": 17,
    "slur": "",
    "Latitude": 47.4118318,
    "Longitude": 10.2781005,
    "Country": "Deutschland"
  },
  {
    "id": 349,
    "City": "Oberwaltersdorf",
    "count": 17,
    "slur": "",
    "Latitude": 47.9759646,
    "Longitude": 16.3232686,
    "Country": "Österreich"
  },
  {
    "id": 350,
    "City": "Pfungstadt",
    "count": 17,
    "slur": "",
    "Latitude": 49.7943,
    "Longitude": 8.5877,
    "Country": "Deutschland"
  },
  {
    "id": 351,
    "City": "Saarlouis",
    "count": 17,
    "slur": "",
    "Latitude": 49.3164661,
    "Longitude": 6.749846,
    "Country": "Deutschland"
  },
  {
    "id": 352,
    "City": "Traunreut",
    "count": 17,
    "slur": "",
    "Latitude": 47.9627599,
    "Longitude": 12.5952942,
    "Country": "Deutschland"
  },
  {
    "id": 353,
    "City": "Ursensollen",
    "count": 17,
    "slur": "",
    "Latitude": 49.4008869,
    "Longitude": 11.759185,
    "Country": "Deutschland"
  },
  {
    "id": 354,
    "City": "Wieselburg",
    "count": 17,
    "slur": "",
    "Latitude": 48.1298877,
    "Longitude": 15.1376585,
    "Country": "Österreich"
  },
  {
    "id": 355,
    "City": "Aue",
    "count": 16,
    "slur": "",
    "Latitude": 50.586993,
    "Longitude": 12.6992687,
    "Country": "Deutschland"
  },
  {
    "id": 356,
    "City": "Bad Griesbach",
    "count": 16,
    "slur": "",
    "Latitude": 48.4513352,
    "Longitude": 13.1931047,
    "Country": "Deutschland"
  },
  {
    "id": 357,
    "City": "Bad Neustadt an der Saale",
    "count": 16,
    "slur": "",
    "Latitude": 50.322637,
    "Longitude": 10.2164873,
    "Country": "Deutschland"
  },
  {
    "id": 358,
    "City": "Hassloch",
    "count": 16,
    "slur": "",
    "Latitude": 49.362976,
    "Longitude": 8.2565755,
    "Country": "Deutschland"
  },
  {
    "id": 359,
    "City": "Kulmbach",
    "count": 16,
    "slur": "",
    "Latitude": 50.1008448,
    "Longitude": 11.4479149,
    "Country": "Deutschland"
  },
  {
    "id": 360,
    "City": "Lemgo",
    "count": 16,
    "slur": "",
    "Latitude": 52.0280674,
    "Longitude": 8.9012894,
    "Country": "Deutschland"
  },
  {
    "id": 361,
    "City": "Peine",
    "count": 16,
    "slur": "",
    "Latitude": 52.3117289,
    "Longitude": 10.25190401,
    "Country": "Deutschland"
  },
  {
    "id": 362,
    "City": "Rankweil",
    "count": 16,
    "slur": "",
    "Latitude": 47.2731056,
    "Longitude": 9.631509381,
    "Country": "Österreich"
  },
  {
    "id": 363,
    "City": "Schwabmünchen",
    "count": 16,
    "slur": "",
    "Latitude": 48.1840821,
    "Longitude": 10.7580502,
    "Country": "Deutschland"
  },
  {
    "id": 364,
    "City": "Weiden",
    "count": 16,
    "slur": "",
    "Latitude": 46.0634632,
    "Longitude": 13.2358377,
    "Country": "Italia"
  },
  {
    "id": 365,
    "City": "Altomünster/Thalhausen",
    "count": 15,
    "slur": "",
    "Latitude": 48.4191138,
    "Longitude": 11.2154083,
    "Country": "Deutschland"
  },
  {
    "id": 366,
    "City": "Andernach",
    "count": 15,
    "slur": "",
    "Latitude": 50.4391924,
    "Longitude": 7.4023145,
    "Country": "Deutschland"
  },
  {
    "id": 367,
    "City": "Balingen",
    "count": 15,
    "slur": "",
    "Latitude": 48.2737512,
    "Longitude": 8.8557862,
    "Country": "Deutschland"
  },
  {
    "id": 368,
    "City": "Baunatal",
    "count": 15,
    "slur": "",
    "Latitude": 51.2550775,
    "Longitude": 9.4119007,
    "Country": "Deutschland"
  },
  {
    "id": 369,
    "City": "Bergisch-Gladbach",
    "count": 15,
    "slur": "bergisch-gladbach-42",
    "Latitude": 50.9929303,
    "Longitude": 7.1277379,
    "Country": "Deutschland"
  },
  {
    "id": 370,
    "City": "Bern",
    "count": 15,
    "slur": "",
    "Latitude": 46.9482713,
    "Longitude": 7.4514512,
    "Country": "Schweiz/Suisse/Svizzera/Svizra"
  },
  {
    "id": 371,
    "City": "Bernburg",
    "count": 15,
    "slur": "",
    "Latitude": 51.7930788,
    "Longitude": 11.7391606,
    "Country": "Deutschland"
  },
  {
    "id": 372,
    "City": "Cloppenburg",
    "count": 15,
    "slur": "",
    "Latitude": 52.8461339,
    "Longitude": 8.0438783,
    "Country": "Deutschland"
  },
  {
    "id": 373,
    "City": "Detmold",
    "count": 15,
    "slur": "",
    "Latitude": 51.936284,
    "Longitude": 8.8791526,
    "Country": "Deutschland"
  },
  {
    "id": 374,
    "City": "Dötlingen",
    "count": 15,
    "slur": "",
    "Latitude": 52.9356656,
    "Longitude": 8.3820642,
    "Country": "Deutschland"
  },
  {
    "id": 375,
    "City": "Göppingen",
    "count": 15,
    "slur": "",
    "Latitude": 48.7031377,
    "Longitude": 9.6541116,
    "Country": "Deutschland"
  },
  {
    "id": 376,
    "City": "Götzis",
    "count": 15,
    "slur": "",
    "Latitude": 47.3351291,
    "Longitude": 9.6440092,
    "Country": "Österreich"
  },
  {
    "id": 377,
    "City": "Herford",
    "count": 15,
    "slur": "",
    "Latitude": 52.1152245,
    "Longitude": 8.6711118,
    "Country": "Deutschland"
  },
  {
    "id": 378,
    "City": "Hockenheim",
    "count": 15,
    "slur": "",
    "Latitude": 49.3188892,
    "Longitude": 8.5475467,
    "Country": "Deutschland"
  },
  {
    "id": 379,
    "City": "Husum",
    "count": 15,
    "slur": "",
    "Latitude": 54.4853638,
    "Longitude": 9.0538156,
    "Country": "Deutschland"
  },
  {
    "id": 380,
    "City": "Langenberg",
    "count": 15,
    "slur": "",
    "Latitude": 48.746807,
    "Longitude": 6.8605468,
    "Country": "France"
  },
  {
    "id": 381,
    "City": "Lauenburg/Elbe",
    "count": 15,
    "slur": "",
    "Latitude": 53.3789803,
    "Longitude": 10.5513889,
    "Country": "Deutschland"
  },
  {
    "id": 382,
    "City": "Leonding",
    "count": 15,
    "slur": "",
    "Latitude": 48.2793314,
    "Longitude": 14.2487457,
    "Country": "Österreich"
  },
  {
    "id": 383,
    "City": "Lübbecke",
    "count": 15,
    "slur": "",
    "Latitude": 52.3027209,
    "Longitude": 8.6183054,
    "Country": "Deutschland"
  },
  {
    "id": 384,
    "City": "Mosbach",
    "count": 15,
    "slur": "",
    "Latitude": 49.3521085,
    "Longitude": 9.1460205,
    "Country": "Deutschland"
  },
  {
    "id": 385,
    "City": "Mülheim an der Ruhr",
    "count": 15,
    "slur": "muelheim-an-der-ruhr-75",
    "Latitude": 51.4272925,
    "Longitude": 6.8829192,
    "Country": "Deutschland"
  },
  {
    "id": 386,
    "City": "Neustadt",
    "count": 15,
    "slur": "",
    "Latitude": 49.3539802,
    "Longitude": 8.1350021,
    "Country": "Deutschland"
  },
  {
    "id": 387,
    "City": "Parndorf",
    "count": 15,
    "slur": "",
    "Latitude": 47.9973956,
    "Longitude": 16.8608307,
    "Country": "Österreich"
  },
  {
    "id": 388,
    "City": "Quedlinburg",
    "count": 15,
    "slur": "",
    "Latitude": 51.7855428,
    "Longitude": 11.1519933,
    "Country": "Deutschland"
  },
  {
    "id": 389,
    "City": "Reichenbach/Vogtland",
    "count": 15,
    "slur": "",
    "Latitude": 50.6219793,
    "Longitude": 12.30508837,
    "Country": "Deutschland"
  },
  {
    "id": 390,
    "City": "Rheinberg",
    "count": 15,
    "slur": "",
    "Latitude": 51.5458979,
    "Longitude": 6.6014097,
    "Country": "Deutschland"
  },
  {
    "id": 391,
    "City": "Rüsselsheim",
    "count": 15,
    "slur": "",
    "Latitude": 49.991701,
    "Longitude": 8.4138251,
    "Country": "Deutschland"
  },
  {
    "id": 392,
    "City": "Schwandorf",
    "count": 15,
    "slur": "",
    "Latitude": 49.3261854,
    "Longitude": 12.1092708,
    "Country": "Deutschland"
  },
  {
    "id": 393,
    "City": "Uelzen",
    "count": 15,
    "slur": "",
    "Latitude": 52.9840679,
    "Longitude": 10.53858815,
    "Country": "Deutschland"
  },
  {
    "id": 394,
    "City": "Ybbs an der Donau",
    "count": 15,
    "slur": "",
    "Latitude": 48.1772445,
    "Longitude": 15.0852856,
    "Country": "Österreich"
  },
  {
    "id": 395,
    "City": "Amberg",
    "count": 14,
    "slur": "",
    "Latitude": 49.4454211,
    "Longitude": 11.858728,
    "Country": "Deutschland"
  },
  {
    "id": 396,
    "City": "Bitterfeld-Wolfen",
    "count": 14,
    "slur": "",
    "Latitude": 51.6425032,
    "Longitude": 12.3076334,
    "Country": "Deutschland"
  },
  {
    "id": 397,
    "City": "Echternach",
    "count": 14,
    "slur": "",
    "Latitude": 49.8120961,
    "Longitude": 6.4214859,
    "Country": "Lëtzebuerg"
  },
  {
    "id": 398,
    "City": "Freising",
    "count": 14,
    "slur": "",
    "Latitude": 48.4008273,
    "Longitude": 11.7439565,
    "Country": "Deutschland"
  },
  {
    "id": 399,
    "City": "Grasellenbach",
    "count": 14,
    "slur": "",
    "Latitude": 49.62869345,
    "Longitude": 8.843560887,
    "Country": "Deutschland"
  },
  {
    "id": 400,
    "City": "Güssing",
    "count": 14,
    "slur": "",
    "Latitude": 47.05552765,
    "Longitude": 16.32271786,
    "Country": "Österreich"
  },
  {
    "id": 401,
    "City": "Halberstadt",
    "count": 14,
    "slur": "",
    "Latitude": 51.8953514,
    "Longitude": 11.0520563,
    "Country": "Deutschland"
  },
  {
    "id": 402,
    "City": "Landau",
    "count": 14,
    "slur": "",
    "Latitude": 49.1982825,
    "Longitude": 8.1123441,
    "Country": "Deutschland"
  },
  {
    "id": 403,
    "City": "Leibnitz",
    "count": 14,
    "slur": "",
    "Latitude": 46.7804762,
    "Longitude": 15.5407005,
    "Country": "Österreich"
  },
  {
    "id": 404,
    "City": "Lustenau",
    "count": 14,
    "slur": "",
    "Latitude": 47.4276538,
    "Longitude": 9.6599406,
    "Country": "Österreich"
  },
  {
    "id": 405,
    "City": "Lüdinghausen",
    "count": 14,
    "slur": "",
    "Latitude": 51.7717757,
    "Longitude": 7.444639,
    "Country": "Deutschland"
  },
  {
    "id": 406,
    "City": "Moers",
    "count": 14,
    "slur": "",
    "Latitude": 51.451283,
    "Longitude": 6.62843,
    "Country": "Deutschland"
  },
  {
    "id": 407,
    "City": "Neuenhagen",
    "count": 14,
    "slur": "",
    "Latitude": 53.7467873,
    "Longitude": 13.1115253,
    "Country": "Deutschland"
  },
  {
    "id": 408,
    "City": "Ober-Grafendorf",
    "count": 14,
    "slur": "",
    "Latitude": 48.15042475,
    "Longitude": 15.55516808,
    "Country": "Österreich"
  },
  {
    "id": 409,
    "City": "Oschatz",
    "count": 14,
    "slur": "",
    "Latitude": 51.297984,
    "Longitude": 13.1082637,
    "Country": "Deutschland"
  },
  {
    "id": 410,
    "City": "Remchingen",
    "count": 14,
    "slur": "",
    "Latitude": 48.9520266,
    "Longitude": 8.5706039,
    "Country": "Deutschland"
  },
  {
    "id": 411,
    "City": "Schenefeld",
    "count": 14,
    "slur": "",
    "Latitude": 54.0484989,
    "Longitude": 9.4703368,
    "Country": "Deutschland"
  },
  {
    "id": 412,
    "City": "Sigmaringen",
    "count": 14,
    "slur": "",
    "Latitude": 48.0855844,
    "Longitude": 9.2178879,
    "Country": "Deutschland"
  },
  {
    "id": 413,
    "City": "Warendorf",
    "count": 14,
    "slur": "",
    "Latitude": 51.9532449,
    "Longitude": 7.9912335,
    "Country": "Deutschland"
  },
  {
    "id": 414,
    "City": "Aarburg",
    "count": 13,
    "slur": "",
    "Latitude": 47.3206417,
    "Longitude": 7.8993599,
    "Country": "Schweiz/Suisse/Svizzera/Svizra"
  },
  {
    "id": 415,
    "City": "Andermatt",
    "count": 13,
    "slur": "",
    "Latitude": 46.6340499,
    "Longitude": 8.5948148,
    "Country": "Schweiz/Suisse/Svizzera/Svizra"
  },
  {
    "id": 416,
    "City": "Arnsberg",
    "count": 13,
    "slur": "",
    "Latitude": 51.4002384,
    "Longitude": 8.0605908,
    "Country": "Deutschland"
  },
  {
    "id": 417,
    "City": "Bad Staffelstein",
    "count": 13,
    "slur": "",
    "Latitude": 50.1028339,
    "Longitude": 10.999159,
    "Country": "Deutschland"
  },
  {
    "id": 418,
    "City": "Böhlen",
    "count": 13,
    "slur": "",
    "Latitude": 51.2027284,
    "Longitude": 12.3859676,
    "Country": "Deutschland"
  },
  {
    "id": 419,
    "City": "Dorsten",
    "count": 13,
    "slur": "",
    "Latitude": 51.6604071,
    "Longitude": 6.9647431,
    "Country": "Deutschland"
  },
  {
    "id": 420,
    "City": "Eisenstadt",
    "count": 13,
    "slur": "",
    "Latitude": 47.83875775,
    "Longitude": 16.53621586,
    "Country": "Österreich"
  },
  {
    "id": 421,
    "City": "Falkensee",
    "count": 13,
    "slur": "",
    "Latitude": 52.5678244,
    "Longitude": 13.0855621,
    "Country": "Deutschland"
  },
  {
    "id": 422,
    "City": "Grafenwörth",
    "count": 13,
    "slur": "",
    "Latitude": 48.4294853,
    "Longitude": 15.78067189,
    "Country": "Österreich"
  },
  {
    "id": 423,
    "City": "Heide",
    "count": 13,
    "slur": "",
    "Latitude": 52.8217435,
    "Longitude": 9.6601426,
    "Country": "Deutschland"
  },
  {
    "id": 424,
    "City": "Himberg bei Wien",
    "count": 13,
    "slur": "",
    "Latitude": "",
    "Longitude": "",
    "Country": ""
  },
  {
    "id": 425,
    "City": "Ilmenau",
    "count": 13,
    "slur": "",
    "Latitude": 50.6867695,
    "Longitude": 10.9142385,
    "Country": "Deutschland"
  },
  {
    "id": 426,
    "City": "Judenburg",
    "count": 13,
    "slur": "",
    "Latitude": 47.1694082,
    "Longitude": 14.6601079,
    "Country": "Österreich"
  },
  {
    "id": 427,
    "City": "Kirchheim bei München",
    "count": 13,
    "slur": "",
    "Latitude": 48.1806379,
    "Longitude": 11.7529851,
    "Country": "Deutschland"
  },
  {
    "id": 428,
    "City": "Kronberg im Taunus",
    "count": 13,
    "slur": "",
    "Latitude": 50.1907415,
    "Longitude": 8.518277905,
    "Country": "Deutschland"
  },
  {
    "id": 429,
    "City": "Königswinter",
    "count": 13,
    "slur": "",
    "Latitude": 50.6739719,
    "Longitude": 7.1930989,
    "Country": "Deutschland"
  },
  {
    "id": 430,
    "City": "Lauchheim",
    "count": 13,
    "slur": "",
    "Latitude": 48.8736283,
    "Longitude": 10.2487381,
    "Country": "Deutschland"
  },
  {
    "id": 431,
    "City": "Meppen",
    "count": 13,
    "slur": "",
    "Latitude": 52.6959802,
    "Longitude": 7.2980648,
    "Country": "Deutschland"
  },
  {
    "id": 432,
    "City": "Schwarzenberg",
    "count": 13,
    "slur": "",
    "Latitude": 47.4137551,
    "Longitude": 9.852372,
    "Country": "Österreich"
  },
  {
    "id": 433,
    "City": "Straubing",
    "count": 13,
    "slur": "",
    "Latitude": 48.8819801,
    "Longitude": 12.569716,
    "Country": "Deutschland"
  },
  {
    "id": 434,
    "City": "Telfs",
    "count": 13,
    "slur": "",
    "Latitude": 47.3046511,
    "Longitude": 11.071515,
    "Country": "Österreich"
  },
  {
    "id": 435,
    "City": "Thüringen",
    "count": 13,
    "slur": "",
    "Latitude": 50.9014721,
    "Longitude": 11.0377839,
    "Country": "Deutschland"
  },
  {
    "id": 436,
    "City": "Waiblingen",
    "count": 13,
    "slur": "",
    "Latitude": 48.8425793,
    "Longitude": 9.3305129,
    "Country": "Deutschland"
  },
  {
    "id": 437,
    "City": "Winterberg",
    "count": 13,
    "slur": "",
    "Latitude": 51.1930723,
    "Longitude": 8.5338466,
    "Country": "Deutschland"
  },
  {
    "id": 438,
    "City": "Wolfenbüttel",
    "count": 13,
    "slur": "",
    "Latitude": 52.1625283,
    "Longitude": 10.5348215,
    "Country": "Deutschland"
  },
  {
    "id": 439,
    "City": "Zwentendorf",
    "count": 13,
    "slur": "",
    "Latitude": 48.6106026,
    "Longitude": 16.4330201,
    "Country": "Österreich"
  },
  {
    "id": 440,
    "City": "Altötting",
    "count": 12,
    "slur": "",
    "Latitude": 48.2268085,
    "Longitude": 12.6757914,
    "Country": "Deutschland"
  },
  {
    "id": 441,
    "City": "Amstetten",
    "count": 12,
    "slur": "",
    "Latitude": 48.0999102,
    "Longitude": 14.8259206,
    "Country": "Österreich"
  },
  {
    "id": 442,
    "City": "Aspach",
    "count": 12,
    "slur": "",
    "Latitude": 48.6545137,
    "Longitude": 6.9624243,
    "Country": "France"
  },
  {
    "id": 443,
    "City": "Bad Tölz",
    "count": 12,
    "slur": "",
    "Latitude": 47.7610641,
    "Longitude": 11.5582933,
    "Country": "Deutschland"
  },
  {
    "id": 444,
    "City": "Bottrop",
    "count": 12,
    "slur": "bottrop-46",
    "Latitude": 51.521581,
    "Longitude": 6.929204,
    "Country": "Deutschland"
  },
  {
    "id": 445,
    "City": "Dachau",
    "count": 12,
    "slur": "",
    "Latitude": 48.2592477,
    "Longitude": 11.4354419,
    "Country": "Deutschland"
  },
  {
    "id": 446,
    "City": "Delmenhorst",
    "count": 12,
    "slur": "",
    "Latitude": 53.0528092,
    "Longitude": 8.6294626,
    "Country": "Deutschland"
  },
  {
    "id": 447,
    "City": "Frauental",
    "count": 12,
    "slur": "",
    "Latitude": 49.4970649,
    "Longitude": 10.0903486,
    "Country": "Deutschland"
  },
  {
    "id": 448,
    "City": "Goslar",
    "count": 12,
    "slur": "",
    "Latitude": 51.9059936,
    "Longitude": 10.4266284,
    "Country": "Deutschland"
  },
  {
    "id": 449,
    "City": "Grefrath",
    "count": 12,
    "slur": "",
    "Latitude": 51.336077,
    "Longitude": 6.343738,
    "Country": "Deutschland"
  },
  {
    "id": 450,
    "City": "Gronau",
    "count": 12,
    "slur": "",
    "Latitude": 52.2123746,
    "Longitude": 7.0244423,
    "Country": "Deutschland"
  },
  {
    "id": 451,
    "City": "Hard",
    "count": 12,
    "slur": "",
    "Latitude": 47.489391,
    "Longitude": 9.6916539,
    "Country": "Österreich"
  },
  {
    "id": 452,
    "City": "Hattingen",
    "count": 12,
    "slur": "",
    "Latitude": 51.4007175,
    "Longitude": 7.1862486,
    "Country": "Deutschland"
  },
  {
    "id": 453,
    "City": "Hückelhoven",
    "count": 12,
    "slur": "",
    "Latitude": 51.0552368,
    "Longitude": 6.2247322,
    "Country": "Deutschland"
  },
  {
    "id": 454,
    "City": "Immenstadt im Allgäu",
    "count": 12,
    "slur": "",
    "Latitude": 47.561006,
    "Longitude": 10.2201663,
    "Country": "Deutschland"
  },
  {
    "id": 455,
    "City": "Kapfenberg",
    "count": 12,
    "slur": "",
    "Latitude": 47.4405481,
    "Longitude": 15.2901669,
    "Country": "Österreich"
  },
  {
    "id": 456,
    "City": "Klosterneuburg",
    "count": 12,
    "slur": "",
    "Latitude": 48.30499,
    "Longitude": 16.323756,
    "Country": "Österreich"
  },
  {
    "id": 457,
    "City": "Laakirchen",
    "count": 12,
    "slur": "",
    "Latitude": 47.9825598,
    "Longitude": 13.8225156,
    "Country": "Österreich"
  },
  {
    "id": 458,
    "City": "Langen",
    "count": 12,
    "slur": "",
    "Latitude": 49.9927036,
    "Longitude": 8.6671682,
    "Country": "Deutschland"
  },
  {
    "id": 459,
    "City": "Lippstadt",
    "count": 12,
    "slur": "",
    "Latitude": 51.6747074,
    "Longitude": 8.347194,
    "Country": "Deutschland"
  },
  {
    "id": 460,
    "City": "Lörrach",
    "count": 12,
    "slur": "",
    "Latitude": 47.6120896,
    "Longitude": 7.6607218,
    "Country": "Deutschland"
  },
  {
    "id": 461,
    "City": "Northeim",
    "count": 12,
    "slur": "",
    "Latitude": 51.76438235,
    "Longitude": 9.858328874,
    "Country": "Deutschland"
  },
  {
    "id": 462,
    "City": "Oberschleissheim",
    "count": 12,
    "slur": "",
    "Latitude": 48.2549383,
    "Longitude": 11.5546058,
    "Country": "Deutschland"
  },
  {
    "id": 463,
    "City": "Pinneberg",
    "count": 12,
    "slur": "",
    "Latitude": 53.7278939,
    "Longitude": 9.6979598,
    "Country": "Deutschland"
  },
  {
    "id": 464,
    "City": "Ronnenberg",
    "count": 12,
    "slur": "",
    "Latitude": 52.3166623,
    "Longitude": 9.6532075,
    "Country": "Deutschland"
  },
  {
    "id": 465,
    "City": "Sangerhausen",
    "count": 12,
    "slur": "",
    "Latitude": 51.4729797,
    "Longitude": 11.2981851,
    "Country": "Deutschland"
  },
  {
    "id": 466,
    "City": "Schwabach",
    "count": 12,
    "slur": "",
    "Latitude": 49.3295535,
    "Longitude": 11.0195132,
    "Country": "Deutschland"
  },
  {
    "id": 467,
    "City": "St. Wendel",
    "count": 12,
    "slur": "",
    "Latitude": 49.4669906,
    "Longitude": 7.1696192,
    "Country": "Deutschland"
  },
  {
    "id": 468,
    "City": "Stollberg",
    "count": 12,
    "slur": "",
    "Latitude": 50.7087734,
    "Longitude": 12.7762607,
    "Country": "Deutschland"
  },
  {
    "id": 469,
    "City": "Waidhofen an der Ybbs",
    "count": 12,
    "slur": "",
    "Latitude": 47.9512695,
    "Longitude": 14.74451882,
    "Country": "Österreich"
  },
  {
    "id": 470,
    "City": "Weitra",
    "count": 12,
    "slur": "",
    "Latitude": 48.7007937,
    "Longitude": 14.8941385,
    "Country": "Österreich"
  },
  {
    "id": 471,
    "City": "Bad Oeynhausen",
    "count": 11,
    "slur": "",
    "Latitude": 52.2014399,
    "Longitude": 8.7981244,
    "Country": "Deutschland"
  },
  {
    "id": 472,
    "City": "Dippoldiswalde",
    "count": 11,
    "slur": "",
    "Latitude": 50.88220275,
    "Longitude": 13.65945263,
    "Country": "Deutschland"
  },
  {
    "id": 473,
    "City": "Emmelshausen",
    "count": 11,
    "slur": "",
    "Latitude": 50.1557618,
    "Longitude": 7.5557802,
    "Country": "Deutschland"
  },
  {
    "id": 474,
    "City": "Feldbach",
    "count": 11,
    "slur": "",
    "Latitude": 46.9525777,
    "Longitude": 15.8887826,
    "Country": "Österreich"
  },
  {
    "id": 475,
    "City": "Fürstenfeld",
    "count": 11,
    "slur": "",
    "Latitude": 47.0499925,
    "Longitude": 16.0816815,
    "Country": "Österreich"
  },
  {
    "id": 476,
    "City": "Güstrow",
    "count": 11,
    "slur": "",
    "Latitude": 53.7936523,
    "Longitude": 12.1759661,
    "Country": "Deutschland"
  },
  {
    "id": 477,
    "City": "Halver",
    "count": 11,
    "slur": "",
    "Latitude": 51.1874423,
    "Longitude": 7.4987312,
    "Country": "Deutschland"
  },
  {
    "id": 478,
    "City": "Heiligenstedten",
    "count": 11,
    "slur": "",
    "Latitude": 53.9313413,
    "Longitude": 9.4697982,
    "Country": "Deutschland"
  },
  {
    "id": 479,
    "City": "Kaufbeuren",
    "count": 11,
    "slur": "",
    "Latitude": 47.8803788,
    "Longitude": 10.622246,
    "Country": "Deutschland"
  },
  {
    "id": 480,
    "City": "Kleve",
    "count": 11,
    "slur": "",
    "Latitude": 51.7854839,
    "Longitude": 6.131367415,
    "Country": "Deutschland"
  },
  {
    "id": 481,
    "City": "Knittelfeld",
    "count": 11,
    "slur": "",
    "Latitude": 47.2147399,
    "Longitude": 14.8297233,
    "Country": "Österreich"
  },
  {
    "id": 482,
    "City": "Lappersdorf",
    "count": 11,
    "slur": "",
    "Latitude": 49.05151,
    "Longitude": 12.087733,
    "Country": "Deutschland"
  },
  {
    "id": 483,
    "City": "Laufen",
    "count": 11,
    "slur": "",
    "Latitude": 47.9354122,
    "Longitude": 12.930309,
    "Country": "Deutschland"
  },
  {
    "id": 484,
    "City": "Launsdorf",
    "count": 11,
    "slur": "",
    "Latitude": 49.4384886,
    "Longitude": 6.4949595,
    "Country": "France"
  },
  {
    "id": 485,
    "City": "Leonberg",
    "count": 11,
    "slur": "",
    "Latitude": 48.8012983,
    "Longitude": 9.0150026,
    "Country": "Deutschland"
  },
  {
    "id": 486,
    "City": "Ludwigslust",
    "count": 11,
    "slur": "",
    "Latitude": 53.3300229,
    "Longitude": 11.4993644,
    "Country": "Deutschland"
  },
  {
    "id": 487,
    "City": "Marktoberdorf",
    "count": 11,
    "slur": "",
    "Latitude": 47.7750654,
    "Longitude": 10.6170847,
    "Country": "Deutschland"
  },
  {
    "id": 488,
    "City": "Minden",
    "count": 11,
    "slur": "",
    "Latitude": 52.2881045,
    "Longitude": 8.9168852,
    "Country": "Deutschland"
  },
  {
    "id": 489,
    "City": "Olsberg",
    "count": 11,
    "slur": "",
    "Latitude": 51.355148,
    "Longitude": 8.4893388,
    "Country": "Deutschland"
  },
  {
    "id": 490,
    "City": "Pforzheim",
    "count": 11,
    "slur": "",
    "Latitude": 48.8908846,
    "Longitude": 8.7029532,
    "Country": "Deutschland"
  },
  {
    "id": 491,
    "City": "Pratteln",
    "count": 11,
    "slur": "",
    "Latitude": 47.5192013,
    "Longitude": 7.6944741,
    "Country": "Schweiz/Suisse/Svizzera/Svizra"
  },
  {
    "id": 492,
    "City": "Rietberg",
    "count": 11,
    "slur": "",
    "Latitude": 51.8074106,
    "Longitude": 8.4286614,
    "Country": "Deutschland"
  },
  {
    "id": 493,
    "City": "Saarburg",
    "count": 11,
    "slur": "",
    "Latitude": 49.6082527,
    "Longitude": 6.548746,
    "Country": "Deutschland"
  },
  {
    "id": 494,
    "City": "Schwäbisch-Gmünd",
    "count": 11,
    "slur": "",
    "Latitude": 48.7999036,
    "Longitude": 9.7977584,
    "Country": "Deutschland"
  },
  {
    "id": 495,
    "City": "Staatz-Kautendorf",
    "count": 11,
    "slur": "",
    "Latitude": 48.6733753,
    "Longitude": 16.4863387,
    "Country": "Österreich"
  },
  {
    "id": 496,
    "City": "Starnberg",
    "count": 11,
    "slur": "",
    "Latitude": 48.0001038,
    "Longitude": 11.3508972,
    "Country": "Deutschland"
  },
  {
    "id": 497,
    "City": "Wernau",
    "count": 11,
    "slur": "",
    "Latitude": 48.6943334,
    "Longitude": 9.4189845,
    "Country": "Deutschland"
  },
  {
    "id": 498,
    "City": "Wittenberge",
    "count": 11,
    "slur": "",
    "Latitude": 52.9983723,
    "Longitude": 11.7507204,
    "Country": "Deutschland"
  },
  {
    "id": 499,
    "City": "Ahaus",
    "count": 10,
    "slur": "",
    "Latitude": 52.0761541,
    "Longitude": 7.004876,
    "Country": "Deutschland"
  },
  {
    "id": 500,
    "City": "Ahlen",
    "count": 10,
    "slur": "",
    "Latitude": 51.7627633,
    "Longitude": 7.8909086,
    "Country": "Deutschland"
  },
  {
    "id": 501,
    "City": "Bad Fallingbostel",
    "count": 10,
    "slur": "",
    "Latitude": 52.8654484,
    "Longitude": 9.6930195,
    "Country": "Deutschland"
  },
  {
    "id": 502,
    "City": "Bad Mergentheim",
    "count": 10,
    "slur": "",
    "Latitude": 49.490532,
    "Longitude": 9.7730692,
    "Country": "Deutschland"
  },
  {
    "id": 503,
    "City": "Bad Rappenau",
    "count": 10,
    "slur": "",
    "Latitude": 49.2393031,
    "Longitude": 9.101268,
    "Country": "Deutschland"
  },
  {
    "id": 504,
    "City": "Bebra",
    "count": 10,
    "slur": "",
    "Latitude": 50.9835038,
    "Longitude": 9.8388054,
    "Country": "Deutschland"
  },
  {
    "id": 505,
    "City": "Brandenburg an der Havel",
    "count": 10,
    "slur": "",
    "Latitude": 52.4108261,
    "Longitude": 12.5497933,
    "Country": "Deutschland"
  },
  {
    "id": 506,
    "City": "Bregenz",
    "count": 10,
    "slur": "",
    "Latitude": 47.5025779,
    "Longitude": 9.7472924,
    "Country": "Österreich"
  },
  {
    "id": 507,
    "City": "Burg bei Magdeburg",
    "count": 10,
    "slur": "",
    "Latitude": 52.2705632,
    "Longitude": 11.8588198,
    "Country": "Deutschland"
  },
  {
    "id": 508,
    "City": "Burgdorf",
    "count": 10,
    "slur": "",
    "Latitude": 47.0571316,
    "Longitude": 7.6237466,
    "Country": "Schweiz/Suisse/Svizzera/Svizra"
  },
  {
    "id": 509,
    "City": "Burgthann",
    "count": 10,
    "slur": "",
    "Latitude": 49.3562217,
    "Longitude": 11.3173995,
    "Country": "Deutschland"
  },
  {
    "id": 510,
    "City": "Castrop-Rauxel",
    "count": 10,
    "slur": "",
    "Latitude": 51.5646195,
    "Longitude": 7.3106175,
    "Country": "Deutschland"
  },
  {
    "id": 511,
    "City": "Chieming-Hart",
    "count": 10,
    "slur": "",
    "Latitude": 47.9276563,
    "Longitude": 12.5493006,
    "Country": "Deutschland"
  },
  {
    "id": 512,
    "City": "Ellmau",
    "count": 10,
    "slur": "",
    "Latitude": 47.51927245,
    "Longitude": 12.29224775,
    "Country": "Österreich"
  },
  {
    "id": 513,
    "City": "Eltville am Rhein",
    "count": 10,
    "slur": "",
    "Latitude": 50.0275441,
    "Longitude": 8.1189741,
    "Country": "Deutschland"
  },
  {
    "id": 514,
    "City": "Grafenegg",
    "count": 10,
    "slur": "",
    "Latitude": 48.43941255,
    "Longitude": 15.73007307,
    "Country": "Österreich"
  },
  {
    "id": 515,
    "City": "Grosswarasdorf",
    "count": 10,
    "slur": "",
    "Latitude": 47.5396097,
    "Longitude": 16.5527854,
    "Country": "Österreich"
  },
  {
    "id": 516,
    "City": "Heusenstamm",
    "count": 10,
    "slur": "",
    "Latitude": 50.0537652,
    "Longitude": 8.8236013,
    "Country": "Deutschland"
  },
  {
    "id": 517,
    "City": "Itzehoe",
    "count": 10,
    "slur": "",
    "Latitude": 53.919448,
    "Longitude": 9.5172441,
    "Country": "Deutschland"
  },
  {
    "id": 518,
    "City": "Kevelaer",
    "count": 10,
    "slur": "",
    "Latitude": 51.5802996,
    "Longitude": 6.2456273,
    "Country": "Deutschland"
  },
  {
    "id": 519,
    "City": "Krems-Stein",
    "count": 10,
    "slur": "",
    "Latitude": 48.4078437,
    "Longitude": 15.5863,
    "Country": "Österreich"
  },
  {
    "id": 520,
    "City": "Lindau",
    "count": 10,
    "slur": "",
    "Latitude": 47.550753,
    "Longitude": 9.6926624,
    "Country": "Deutschland"
  },
  {
    "id": 521,
    "City": "Merseburg",
    "count": 10,
    "slur": "",
    "Latitude": 51.3564413,
    "Longitude": 11.996148,
    "Country": "Deutschland"
  },
  {
    "id": 522,
    "City": "Oelsnitz/Vogtland",
    "count": 10,
    "slur": "",
    "Latitude": 50.4118935,
    "Longitude": 12.1568975,
    "Country": "Deutschland"
  },
  {
    "id": 523,
    "City": "Olsberg-Bigge",
    "count": 10,
    "slur": "",
    "Latitude": 51.3554864,
    "Longitude": 8.4684991,
    "Country": "Deutschland"
  },
  {
    "id": 524,
    "City": "Radolfzell",
    "count": 10,
    "slur": "",
    "Latitude": 47.7372802,
    "Longitude": 8.9702755,
    "Country": "Deutschland"
  },
  {
    "id": 525,
    "City": "Recklinghausen Suderwich",
    "count": 10,
    "slur": "",
    "Latitude": 51.60111475,
    "Longitude": 7.271999391,
    "Country": "Deutschland"
  },
  {
    "id": 526,
    "City": "Schwaz",
    "count": 10,
    "slur": "",
    "Latitude": 47.3449529,
    "Longitude": 11.7084253,
    "Country": "Österreich"
  },
  {
    "id": 527,
    "City": "Selb",
    "count": 10,
    "slur": "",
    "Latitude": 50.7444077,
    "Longitude": 7.7679356,
    "Country": "Deutschland"
  },
  {
    "id": 528,
    "City": "Spielberg",
    "count": 10,
    "slur": "",
    "Latitude": 48.2274518,
    "Longitude": 15.3572755,
    "Country": "Österreich"
  },
  {
    "id": 529,
    "City": "Stadtschlaining",
    "count": 10,
    "slur": "",
    "Latitude": 47.3233193,
    "Longitude": 16.2768141,
    "Country": "Österreich"
  },
  {
    "id": 530,
    "City": "Waren/Müritz",
    "count": 10,
    "slur": "",
    "Latitude": 53.5217959,
    "Longitude": 12.6805557,
    "Country": "Deutschland"
  },
  {
    "id": 531,
    "City": "Willich",
    "count": 10,
    "slur": "",
    "Latitude": 51.2641433,
    "Longitude": 6.5446958,
    "Country": "Deutschland"
  },
  {
    "id": 532,
    "City": "Zweibrücken",
    "count": 10,
    "slur": "",
    "Latitude": 49.248655,
    "Longitude": 7.3641981,
    "Country": "Deutschland"
  },
  {
    "id": 533,
    "City": "Ahrenshoop",
    "count": 9,
    "slur": "",
    "Latitude": 54.3789509,
    "Longitude": 12.419192,
    "Country": "Deutschland"
  },
  {
    "id": 534,
    "City": "Bad Ischl",
    "count": 9,
    "slur": "",
    "Latitude": 47.7115299,
    "Longitude": 13.6239333,
    "Country": "Österreich"
  },
  {
    "id": 535,
    "City": "Bad Rothenfelde",
    "count": 9,
    "slur": "",
    "Latitude": 52.11102,
    "Longitude": 8.1615281,
    "Country": "Deutschland"
  },
  {
    "id": 536,
    "City": "Basthorst",
    "count": 9,
    "slur": "",
    "Latitude": 53.5767858,
    "Longitude": 10.4737545,
    "Country": "Deutschland"
  },
  {
    "id": 537,
    "City": "Biberach/Riss",
    "count": 9,
    "slur": "",
    "Latitude": 48.101927,
    "Longitude": 9.7931144,
    "Country": "Deutschland"
  },
  {
    "id": 538,
    "City": "Brunn am Gebirge",
    "count": 9,
    "slur": "",
    "Latitude": 48.1070931,
    "Longitude": 16.2836918,
    "Country": "Österreich"
  },
  {
    "id": 539,
    "City": "Dischingen",
    "count": 9,
    "slur": "",
    "Latitude": 48.6986742,
    "Longitude": 10.3570233,
    "Country": "Deutschland"
  },
  {
    "id": 540,
    "City": "Eisenhüttenstadt",
    "count": 9,
    "slur": "",
    "Latitude": 52.1448863,
    "Longitude": 14.6294413,
    "Country": "Deutschland"
  },
  {
    "id": 541,
    "City": "Emsdetten",
    "count": 9,
    "slur": "",
    "Latitude": 52.174687,
    "Longitude": 7.5301756,
    "Country": "Deutschland"
  },
  {
    "id": 542,
    "City": "Friedberg/Hessen",
    "count": 9,
    "slur": "",
    "Latitude": 50.3352682,
    "Longitude": 8.7539306,
    "Country": "Deutschland"
  },
  {
    "id": 543,
    "City": "Fürstenfeldbruck",
    "count": 9,
    "slur": "",
    "Latitude": 48.1813797,
    "Longitude": 11.2382125,
    "Country": "Deutschland"
  },
  {
    "id": 544,
    "City": "Genève",
    "count": 9,
    "slur": "",
    "Latitude": 46.2017559,
    "Longitude": 6.1466014,
    "Country": "Schweiz/Suisse/Svizzera/Svizra"
  },
  {
    "id": 545,
    "City": "Gersthofen",
    "count": 9,
    "slur": "",
    "Latitude": 48.4248151,
    "Longitude": 10.8778564,
    "Country": "Deutschland"
  },
  {
    "id": 546,
    "City": "Grafschaft",
    "count": 9,
    "slur": "",
    "Latitude": 50.5754022,
    "Longitude": 7.088919194,
    "Country": "Deutschland"
  },
  {
    "id": 547,
    "City": "Herne",
    "count": 9,
    "slur": "herne-64",
    "Latitude": 51.5380394,
    "Longitude": 7.219985,
    "Country": "Deutschland"
  },
  {
    "id": 548,
    "City": "Hitzacker",
    "count": 9,
    "slur": "",
    "Latitude": 53.1512116,
    "Longitude": 11.0440938,
    "Country": "Deutschland"
  },
  {
    "id": 549,
    "City": "Höxter",
    "count": 9,
    "slur": "",
    "Latitude": 51.7938902,
    "Longitude": 9.348855,
    "Country": "Deutschland"
  },
  {
    "id": 550,
    "City": "Inning am Ammersee",
    "count": 9,
    "slur": "",
    "Latitude": 48.0774991,
    "Longitude": 11.1526202,
    "Country": "Deutschland"
  },
  {
    "id": 551,
    "City": "Kamenz",
    "count": 9,
    "slur": "",
    "Latitude": 51.270139,
    "Longitude": 14.0948628,
    "Country": "Deutschland"
  },
  {
    "id": 552,
    "City": "Kerpen-Sindorf",
    "count": 9,
    "slur": "",
    "Latitude": 50.9076838,
    "Longitude": 6.672073,
    "Country": "Deutschland"
  },
  {
    "id": 553,
    "City": "Kittsee",
    "count": 9,
    "slur": "",
    "Latitude": 48.0924999,
    "Longitude": 17.0638888,
    "Country": "Österreich"
  },
  {
    "id": 554,
    "City": "Klam",
    "count": 9,
    "slur": "",
    "Latitude": 48.224833,
    "Longitude": 14.7825148,
    "Country": "Österreich"
  },
  {
    "id": 555,
    "City": "Lunz am See",
    "count": 9,
    "slur": "",
    "Latitude": 47.8606764,
    "Longitude": 15.0270919,
    "Country": "Österreich"
  },
  {
    "id": 556,
    "City": "Nordkirchen",
    "count": 9,
    "slur": "",
    "Latitude": 51.7380176,
    "Longitude": 7.5251792,
    "Country": "Deutschland"
  },
  {
    "id": 557,
    "City": "Nürtingen",
    "count": 9,
    "slur": "",
    "Latitude": 48.6265854,
    "Longitude": 9.3365463,
    "Country": "Deutschland"
  },
  {
    "id": 558,
    "City": "Offenbach",
    "count": 9,
    "slur": "",
    "Latitude": 50.1055002,
    "Longitude": 8.7610698,
    "Country": "Deutschland"
  },
  {
    "id": 559,
    "City": "Pössneck",
    "count": 9,
    "slur": "",
    "Latitude": 50.694735,
    "Longitude": 11.5953247,
    "Country": "Deutschland"
  },
  {
    "id": 560,
    "City": "Ramstein-Miesenbach",
    "count": 9,
    "slur": "",
    "Latitude": 49.4518819,
    "Longitude": 7.5543118,
    "Country": "Deutschland"
  },
  {
    "id": 561,
    "City": "Rheinstetten",
    "count": 9,
    "slur": "",
    "Latitude": 48.968549,
    "Longitude": 8.3097117,
    "Country": "Deutschland"
  },
  {
    "id": 562,
    "City": "Rodgau",
    "count": 9,
    "slur": "",
    "Latitude": 50.0176771,
    "Longitude": 8.8853392,
    "Country": "Deutschland"
  },
  {
    "id": 563,
    "City": "Schaffhausen",
    "count": 9,
    "slur": "",
    "Latitude": 47.6960491,
    "Longitude": 8.634513,
    "Country": "Schweiz/Suisse/Svizzera/Svizra"
  },
  {
    "id": 564,
    "City": "Schwarzenbach",
    "count": 9,
    "slur": "",
    "Latitude": 49.71877745,
    "Longitude": 12.00240483,
    "Country": "Deutschland"
  },
  {
    "id": 565,
    "City": "Schärding",
    "count": 9,
    "slur": "",
    "Latitude": 48.4569975,
    "Longitude": 13.4318,
    "Country": "Österreich"
  },
  {
    "id": 566,
    "City": "Sinsheim",
    "count": 9,
    "slur": "",
    "Latitude": 49.2553,
    "Longitude": 8.87722,
    "Country": "Deutschland"
  },
  {
    "id": 567,
    "City": "Sinzheim",
    "count": 9,
    "slur": "",
    "Latitude": 48.7603662,
    "Longitude": 8.1652016,
    "Country": "Deutschland"
  },
  {
    "id": 568,
    "City": "Sonneberg",
    "count": 9,
    "slur": "",
    "Latitude": 50.3575412,
    "Longitude": 11.1691714,
    "Country": "Deutschland"
  },
  {
    "id": 569,
    "City": "St. Georgen an der Gusen",
    "count": 9,
    "slur": "",
    "Latitude": 48.2718132,
    "Longitude": 14.4485413,
    "Country": "Österreich"
  },
  {
    "id": 570,
    "City": "Timmendorfer Strand",
    "count": 9,
    "slur": "",
    "Latitude": 53.99180005,
    "Longitude": 10.77793727,
    "Country": "Deutschland"
  },
  {
    "id": 571,
    "City": "Wehrbleck",
    "count": 9,
    "slur": "",
    "Latitude": 52.6319617,
    "Longitude": 8.6923076,
    "Country": "Deutschland"
  },
  {
    "id": 572,
    "City": "Wiesloch",
    "count": 9,
    "slur": "",
    "Latitude": 49.2942599,
    "Longitude": 8.698707,
    "Country": "Deutschland"
  },
  {
    "id": 573,
    "City": "Wipperfürth",
    "count": 9,
    "slur": "",
    "Latitude": 51.1155182,
    "Longitude": 7.3879068,
    "Country": "Deutschland"
  },
  {
    "id": 574,
    "City": "Zeulenroda-Triebes",
    "count": 9,
    "slur": "",
    "Latitude": 50.6562697,
    "Longitude": 12.0058399,
    "Country": "Deutschland"
  },
  {
    "id": 575,
    "City": "Abfahrt Ab Dt Göttingen",
    "count": 8,
    "slur": "",
    "Latitude": "",
    "Longitude": "",
    "Country": ""
  },
  {
    "id": 576,
    "City": "Bergisch Gladbach",
    "count": 8,
    "slur": "",
    "Latitude": 50.9929303,
    "Longitude": 7.1277379,
    "Country": "Deutschland"
  },
  {
    "id": 577,
    "City": "Calviá",
    "count": 8,
    "slur": "",
    "Latitude": 39.5464408,
    "Longitude": 2.5113385,
    "Country": "España"
  },
  {
    "id": 578,
    "City": "Donzdorf",
    "count": 8,
    "slur": "",
    "Latitude": 48.6856725,
    "Longitude": 9.8107595,
    "Country": "Deutschland"
  },
  {
    "id": 579,
    "City": "Eberswalde",
    "count": 8,
    "slur": "",
    "Latitude": 52.8350807,
    "Longitude": 13.7996542,
    "Country": "Deutschland"
  },
  {
    "id": 580,
    "City": "Eferding",
    "count": 8,
    "slur": "",
    "Latitude": 48.3082607,
    "Longitude": 14.0203999,
    "Country": "Österreich"
  },
  {
    "id": 581,
    "City": "Elsteraue/Ot Alt-Tröglitz (Zeitz)",
    "count": 8,
    "slur": "",
    "Latitude": "",
    "Longitude": "",
    "Country": ""
  },
  {
    "id": 582,
    "City": "Finsterwalde",
    "count": 8,
    "slur": "finsterwalde-2087",
    "Latitude": 51.6315282,
    "Longitude": 13.707358,
    "Country": "Deutschland"
  },
  {
    "id": 583,
    "City": "Gehrden",
    "count": 8,
    "slur": "",
    "Latitude": 52.313508,
    "Longitude": 9.6008438,
    "Country": "Deutschland"
  },
  {
    "id": 584,
    "City": "Grub am Forst",
    "count": 8,
    "slur": "",
    "Latitude": 50.2308766,
    "Longitude": 11.022204,
    "Country": "Deutschland"
  },
  {
    "id": 585,
    "City": "Gunskirchen",
    "count": 8,
    "slur": "",
    "Latitude": 48.1334587,
    "Longitude": 13.9427447,
    "Country": "Österreich"
  },
  {
    "id": 586,
    "City": "Halbturn",
    "count": 8,
    "slur": "",
    "Latitude": 47.8714144,
    "Longitude": 16.9742478,
    "Country": "Österreich"
  },
  {
    "id": 587,
    "City": "Heideblick",
    "count": 8,
    "slur": "",
    "Latitude": 51.80965455,
    "Longitude": 13.64738997,
    "Country": "Deutschland"
  },
  {
    "id": 588,
    "City": "Hennef",
    "count": 8,
    "slur": "",
    "Latitude": 50.7754417,
    "Longitude": 7.2847945,
    "Country": "Deutschland"
  },
  {
    "id": 589,
    "City": "Hofheim",
    "count": 8,
    "slur": "",
    "Latitude": 50.0940795,
    "Longitude": 8.422004734,
    "Country": "Deutschland"
  },
  {
    "id": 590,
    "City": "Höchstadt an der Aisch",
    "count": 8,
    "slur": "",
    "Latitude": 49.7046173,
    "Longitude": 10.8077534,
    "Country": "Deutschland"
  },
  {
    "id": 591,
    "City": "Imst",
    "count": 8,
    "slur": "",
    "Latitude": 47.2759021,
    "Longitude": 10.70069037,
    "Country": "Österreich"
  },
  {
    "id": 592,
    "City": "Kronach",
    "count": 8,
    "slur": "",
    "Latitude": 50.2397846,
    "Longitude": 11.3277669,
    "Country": "Deutschland"
  },
  {
    "id": 593,
    "City": "Lichtenfels",
    "count": 8,
    "slur": "",
    "Latitude": 50.14568,
    "Longitude": 11.06382,
    "Country": "Deutschland"
  },
  {
    "id": 594,
    "City": "Lutherstadt Wittenberg",
    "count": 8,
    "slur": "",
    "Latitude": 51.8666527,
    "Longitude": 12.646761,
    "Country": "Deutschland"
  },
  {
    "id": 595,
    "City": "Luzern",
    "count": 8,
    "slur": "",
    "Latitude": 47.0505452,
    "Longitude": 8.3054682,
    "Country": "Schweiz/Suisse/Svizzera/Svizra"
  },
  {
    "id": 596,
    "City": "Lössnitz/Ot Affalter",
    "count": 8,
    "slur": "",
    "Latitude": "",
    "Longitude": "",
    "Country": ""
  },
  {
    "id": 597,
    "City": "Mattighofen",
    "count": 8,
    "slur": "",
    "Latitude": 48.1030792,
    "Longitude": 13.1503106,
    "Country": "Österreich"
  },
  {
    "id": 598,
    "City": "Moritzburg",
    "count": 8,
    "slur": "",
    "Latitude": 51.1592018,
    "Longitude": 13.6819406,
    "Country": "Deutschland"
  },
  {
    "id": 599,
    "City": "Neuburg an der Donau",
    "count": 8,
    "slur": "",
    "Latitude": 48.7371951,
    "Longitude": 11.1795268,
    "Country": "Deutschland"
  },
  {
    "id": 600,
    "City": "Neukirchen-Vluyn",
    "count": 8,
    "slur": "",
    "Latitude": 51.4413742,
    "Longitude": 6.5467641,
    "Country": "Deutschland"
  },
  {
    "id": 601,
    "City": "Neuwied bei Koblenz",
    "count": 8,
    "slur": "",
    "Latitude": "",
    "Longitude": "",
    "Country": ""
  },
  {
    "id": 602,
    "City": "Nüziders",
    "count": 8,
    "slur": "",
    "Latitude": 47.1717622,
    "Longitude": 9.8003634,
    "Country": "Österreich"
  },
  {
    "id": 603,
    "City": "Osterode am Harz",
    "count": 8,
    "slur": "",
    "Latitude": 51.72784,
    "Longitude": 10.2508204,
    "Country": "Deutschland"
  },
  {
    "id": 604,
    "City": "Rathenow",
    "count": 8,
    "slur": "",
    "Latitude": 52.6063703,
    "Longitude": 12.3382259,
    "Country": "Deutschland"
  },
  {
    "id": 605,
    "City": "Renningen",
    "count": 8,
    "slur": "",
    "Latitude": 48.7648163,
    "Longitude": 8.9347008,
    "Country": "Deutschland"
  },
  {
    "id": 606,
    "City": "Rotenburg (Wümme)",
    "count": 8,
    "slur": "",
    "Latitude": 53.1108906,
    "Longitude": 9.4049292,
    "Country": "Deutschland"
  },
  {
    "id": 607,
    "City": "Rottendorf bei Würzburg",
    "count": 8,
    "slur": "",
    "Latitude": "",
    "Longitude": "",
    "Country": ""
  },
  {
    "id": 608,
    "City": "Sankt Goarshausen",
    "count": 8,
    "slur": "",
    "Latitude": 50.1548796,
    "Longitude": 7.7160976,
    "Country": "Deutschland"
  },
  {
    "id": 609,
    "City": "St. Veit an der Glan",
    "count": 8,
    "slur": "",
    "Latitude": 46.7672814,
    "Longitude": 14.3576981,
    "Country": "Österreich"
  },
  {
    "id": 610,
    "City": "Stockerau",
    "count": 8,
    "slur": "",
    "Latitude": 48.3846124,
    "Longitude": 16.2076303,
    "Country": "Österreich"
  },
  {
    "id": 611,
    "City": "Verden",
    "count": 8,
    "slur": "",
    "Latitude": 52.9759701,
    "Longitude": 9.180839132,
    "Country": "Deutschland"
  },
  {
    "id": 612,
    "City": "Werdau",
    "count": 8,
    "slur": "",
    "Latitude": 50.7361377,
    "Longitude": 12.3763847,
    "Country": "Deutschland"
  },
  {
    "id": 613,
    "City": "Zirndorf",
    "count": 8,
    "slur": "",
    "Latitude": 49.445762,
    "Longitude": 10.9560462,
    "Country": "Deutschland"
  },
  {
    "id": 614,
    "City": "Aalen",
    "count": 7,
    "slur": "",
    "Latitude": 48.8362705,
    "Longitude": 10.0931765,
    "Country": "Deutschland"
  },
  {
    "id": 615,
    "City": "Agathenburg",
    "count": 7,
    "slur": "",
    "Latitude": 53.5667,
    "Longitude": 9.53333,
    "Country": "Deutschland"
  },
  {
    "id": 616,
    "City": "Ahrensburg",
    "count": 7,
    "slur": "",
    "Latitude": 53.6736529,
    "Longitude": 10.2358999,
    "Country": "Deutschland"
  },
  {
    "id": 617,
    "City": "Allmannsdorf",
    "count": 7,
    "slur": "",
    "Latitude": 47.68134075,
    "Longitude": 9.19864133,
    "Country": "Deutschland"
  },
  {
    "id": 618,
    "City": "Alsfeld",
    "count": 7,
    "slur": "",
    "Latitude": 50.7523545,
    "Longitude": 9.268355,
    "Country": "Deutschland"
  },
  {
    "id": 619,
    "City": "Altlandsberg",
    "count": 7,
    "slur": "",
    "Latitude": 52.5614869,
    "Longitude": 13.7245542,
    "Country": "Deutschland"
  },
  {
    "id": 620,
    "City": "Ansfelden",
    "count": 7,
    "slur": "",
    "Latitude": 48.2090744,
    "Longitude": 14.2892608,
    "Country": "Österreich"
  },
  {
    "id": 621,
    "City": "Bad Dürkheim",
    "count": 7,
    "slur": "",
    "Latitude": 49.4632944,
    "Longitude": 8.1705063,
    "Country": "Deutschland"
  },
  {
    "id": 622,
    "City": "Bad Iburg",
    "count": 7,
    "slur": "",
    "Latitude": 52.1576093,
    "Longitude": 8.0454627,
    "Country": "Deutschland"
  },
  {
    "id": 623,
    "City": "Bad Münder",
    "count": 7,
    "slur": "",
    "Latitude": 52.1952894,
    "Longitude": 9.4633959,
    "Country": "Deutschland"
  },
  {
    "id": 624,
    "City": "Bad Säckingen",
    "count": 7,
    "slur": "",
    "Latitude": 47.5525538,
    "Longitude": 7.9495387,
    "Country": "Deutschland"
  },
  {
    "id": 625,
    "City": "Baden",
    "count": 7,
    "slur": "",
    "Latitude": 48.1928371,
    "Longitude": 8.1390286,
    "Country": "Deutschland"
  },
  {
    "id": 626,
    "City": "Bassum",
    "count": 7,
    "slur": "",
    "Latitude": 52.8495881,
    "Longitude": 8.728382,
    "Country": "Deutschland"
  },
  {
    "id": 627,
    "City": "Besigheim",
    "count": 7,
    "slur": "",
    "Latitude": 48.9996364,
    "Longitude": 9.1417523,
    "Country": "Deutschland"
  },
  {
    "id": 628,
    "City": "Brilon",
    "count": 7,
    "slur": "",
    "Latitude": 51.3955721,
    "Longitude": 8.5677743,
    "Country": "Deutschland"
  },
  {
    "id": 629,
    "City": "Dieburg",
    "count": 7,
    "slur": "",
    "Latitude": 49.8949312,
    "Longitude": 8.8368946,
    "Country": "Deutschland"
  },
  {
    "id": 630,
    "City": "Dormagen",
    "count": 7,
    "slur": "",
    "Latitude": 51.0941656,
    "Longitude": 6.8407931,
    "Country": "Deutschland"
  },
  {
    "id": 631,
    "City": "Eschwege",
    "count": 7,
    "slur": "",
    "Latitude": 51.186867,
    "Longitude": 10.0575056,
    "Country": "Deutschland"
  },
  {
    "id": 632,
    "City": "Freital (Döhlen)",
    "count": 7,
    "slur": "",
    "Latitude": 51.0070347,
    "Longitude": 13.6475354,
    "Country": "Deutschland"
  },
  {
    "id": 633,
    "City": "Haldensleben",
    "count": 7,
    "slur": "",
    "Latitude": 52.2911243,
    "Longitude": 11.4132719,
    "Country": "Deutschland"
  },
  {
    "id": 634,
    "City": "Hamminkeln",
    "count": 7,
    "slur": "",
    "Latitude": 51.7306922,
    "Longitude": 6.590865,
    "Country": "Deutschland"
  },
  {
    "id": 635,
    "City": "Hiddenhausen",
    "count": 7,
    "slur": "",
    "Latitude": 52.1530879,
    "Longitude": 8.6394715,
    "Country": "Deutschland"
  },
  {
    "id": 636,
    "City": "Hildburghausen",
    "count": 7,
    "slur": "",
    "Latitude": 50.4266327,
    "Longitude": 10.7300263,
    "Country": "Deutschland"
  },
  {
    "id": 637,
    "City": "Icking",
    "count": 7,
    "slur": "",
    "Latitude": 47.9515105,
    "Longitude": 11.4320346,
    "Country": "Deutschland"
  },
  {
    "id": 638,
    "City": "Illingen/Saar",
    "count": 7,
    "slur": "",
    "Latitude": 49.3764573,
    "Longitude": 7.0489784,
    "Country": "Deutschland"
  },
  {
    "id": 639,
    "City": "Joldelund",
    "count": 7,
    "slur": "",
    "Latitude": 54.6497452,
    "Longitude": 9.1375357,
    "Country": "Deutschland"
  },
  {
    "id": 640,
    "City": "Kamp-Lintfort",
    "count": 7,
    "slur": "",
    "Latitude": 51.5017981,
    "Longitude": 6.547923,
    "Country": "Deutschland"
  },
  {
    "id": 641,
    "City": "Kelbra",
    "count": 7,
    "slur": "",
    "Latitude": 51.4362095,
    "Longitude": 11.0401125,
    "Country": "Deutschland"
  },
  {
    "id": 642,
    "City": "Königsbrunn",
    "count": 7,
    "slur": "",
    "Latitude": 48.2679546,
    "Longitude": 10.8883816,
    "Country": "Deutschland"
  },
  {
    "id": 643,
    "City": "Künzelsau",
    "count": 7,
    "slur": "",
    "Latitude": 49.2803765,
    "Longitude": 9.6901512,
    "Country": "Deutschland"
  },
  {
    "id": 644,
    "City": "Landsberg am Lech",
    "count": 7,
    "slur": "",
    "Latitude": 48.0514973,
    "Longitude": 10.8737728,
    "Country": "Deutschland"
  },
  {
    "id": 645,
    "City": "Luckenwalde",
    "count": 7,
    "slur": "",
    "Latitude": 52.0902045,
    "Longitude": 13.1741882,
    "Country": "Deutschland"
  },
  {
    "id": 646,
    "City": "Mariazell",
    "count": 7,
    "slur": "",
    "Latitude": 47.7717395,
    "Longitude": 15.3174781,
    "Country": "Österreich"
  },
  {
    "id": 647,
    "City": "Marl",
    "count": 7,
    "slur": "",
    "Latitude": 16.3700359,
    "Longitude": -2.2900239,
    "Country": "Mali"
  },
  {
    "id": 648,
    "City": "Meinerzhagen",
    "count": 7,
    "slur": "",
    "Latitude": 51.1063495,
    "Longitude": 7.6408956,
    "Country": "Deutschland"
  },
  {
    "id": 649,
    "City": "Millstatt",
    "count": 7,
    "slur": "",
    "Latitude": 46.8051293,
    "Longitude": 13.5714926,
    "Country": "Österreich"
  },
  {
    "id": 650,
    "City": "Mönchengladbach (Wickrath)",
    "count": 7,
    "slur": "",
    "Latitude": 51.1392937,
    "Longitude": 6.4234058,
    "Country": "Deutschland"
  },
  {
    "id": 651,
    "City": "Mühlhausen",
    "count": 7,
    "slur": "",
    "Latitude": 51.2094255,
    "Longitude": 10.4589044,
    "Country": "Deutschland"
  },
  {
    "id": 652,
    "City": "Neukieritzsch",
    "count": 7,
    "slur": "",
    "Latitude": 51.1502147,
    "Longitude": 12.4090412,
    "Country": "Deutschland"
  },
  {
    "id": 653,
    "City": "Neumarkt",
    "count": 7,
    "slur": "",
    "Latitude": 49.279624,
    "Longitude": 11.4594662,
    "Country": "Deutschland"
  },
  {
    "id": 654,
    "City": "Nickelsdorf",
    "count": 7,
    "slur": "",
    "Latitude": 47.9403019,
    "Longitude": 17.0661763,
    "Country": "Österreich"
  },
  {
    "id": 655,
    "City": "Nidda-Wallernhausen",
    "count": 7,
    "slur": "",
    "Latitude": 50.3938553,
    "Longitude": 9.0173366,
    "Country": "Deutschland"
  },
  {
    "id": 656,
    "City": "Nieder-Olm",
    "count": 7,
    "slur": "",
    "Latitude": 49.9094108,
    "Longitude": 8.2033406,
    "Country": "Deutschland"
  },
  {
    "id": 657,
    "City": "Nordhorn",
    "count": 7,
    "slur": "",
    "Latitude": 52.4359731,
    "Longitude": 7.0707659,
    "Country": "Deutschland"
  },
  {
    "id": 658,
    "City": "Nördlingen",
    "count": 7,
    "slur": "",
    "Latitude": 48.8516578,
    "Longitude": 10.4885873,
    "Country": "Deutschland"
  },
  {
    "id": 659,
    "City": "Obertshausen",
    "count": 7,
    "slur": "",
    "Latitude": 50.0731304,
    "Longitude": 8.8732839,
    "Country": "Deutschland"
  },
  {
    "id": 660,
    "City": "Oelde",
    "count": 7,
    "slur": "",
    "Latitude": 51.8260823,
    "Longitude": 8.1453352,
    "Country": "Deutschland"
  },
  {
    "id": 661,
    "City": "Ransbach-Baumbach",
    "count": 7,
    "slur": "",
    "Latitude": 50.4667,
    "Longitude": 7.73333,
    "Country": "Deutschland"
  },
  {
    "id": 662,
    "City": "Ribnitz-Damgarten",
    "count": 7,
    "slur": "",
    "Latitude": 54.2433326,
    "Longitude": 12.4319733,
    "Country": "Deutschland"
  },
  {
    "id": 663,
    "City": "Ried im Innkreis",
    "count": 7,
    "slur": "",
    "Latitude": 48.2085868,
    "Longitude": 13.48839,
    "Country": "Österreich"
  },
  {
    "id": 664,
    "City": "Rüdesheim",
    "count": 7,
    "slur": "",
    "Latitude": 49.8457903,
    "Longitude": 7.8153313,
    "Country": "Deutschland"
  },
  {
    "id": 665,
    "City": "Rüdesheim am Rhein",
    "count": 7,
    "slur": "",
    "Latitude": 49.9816097,
    "Longitude": 7.9310198,
    "Country": "Deutschland"
  },
  {
    "id": 666,
    "City": "Schneeberg",
    "count": 7,
    "slur": "",
    "Latitude": 50.5950694,
    "Longitude": 12.6417008,
    "Country": "Deutschland"
  },
  {
    "id": 667,
    "City": "Seifhennersdorf",
    "count": 7,
    "slur": "",
    "Latitude": 50.9346533,
    "Longitude": 14.6092022,
    "Country": "Deutschland"
  },
  {
    "id": 668,
    "City": "Seligenstadt",
    "count": 7,
    "slur": "",
    "Latitude": 50.0441737,
    "Longitude": 8.9755128,
    "Country": "Deutschland"
  },
  {
    "id": 669,
    "City": "St. Florian",
    "count": 7,
    "slur": "",
    "Latitude": 34.863213,
    "Longitude": -87.60843952,
    "Country": "United States"
  },
  {
    "id": 670,
    "City": "Vöcklabruck",
    "count": 7,
    "slur": "",
    "Latitude": 48.0078587,
    "Longitude": 13.6460378,
    "Country": "Österreich"
  },
  {
    "id": 671,
    "City": "Weissach",
    "count": 7,
    "slur": "",
    "Latitude": 48.8467574,
    "Longitude": 8.9273862,
    "Country": "Deutschland"
  },
  {
    "id": 672,
    "City": "Winzendorf",
    "count": 7,
    "slur": "",
    "Latitude": 47.2731897,
    "Longitude": 15.8834114,
    "Country": "Österreich"
  },
  {
    "id": 673,
    "City": "Ziersdorf",
    "count": 7,
    "slur": "",
    "Latitude": 48.51998945,
    "Longitude": 15.90265765,
    "Country": "Österreich"
  },
  {
    "id": 674,
    "City": "Aichach",
    "count": 6,
    "slur": "",
    "Latitude": 48.4591478,
    "Longitude": 11.1309953,
    "Country": "Deutschland"
  },
  {
    "id": 675,
    "City": "Aigen",
    "count": 6,
    "slur": "",
    "Latitude": 47.7154066,
    "Longitude": 13.5276087,
    "Country": "Österreich"
  },
  {
    "id": 676,
    "City": "Ammersbek",
    "count": 6,
    "slur": "",
    "Latitude": 53.6950809,
    "Longitude": 10.1905014,
    "Country": "Deutschland"
  },
  {
    "id": 677,
    "City": "Aschersleben",
    "count": 6,
    "slur": "",
    "Latitude": 51.7559549,
    "Longitude": 11.462134,
    "Country": "Deutschland"
  },
  {
    "id": 678,
    "City": "Bad Griesbach im Rottal",
    "count": 6,
    "slur": "",
    "Latitude": 48.4513352,
    "Longitude": 13.1931047,
    "Country": "Deutschland"
  },
  {
    "id": 679,
    "City": "Bad Köstritz",
    "count": 6,
    "slur": "",
    "Latitude": 50.9301118,
    "Longitude": 12.0113701,
    "Country": "Deutschland"
  },
  {
    "id": 680,
    "City": "Bickenbach",
    "count": 6,
    "slur": "",
    "Latitude": 50.1264152,
    "Longitude": 7.5266937,
    "Country": "Deutschland"
  },
  {
    "id": 681,
    "City": "Bingen am Rhein",
    "count": 6,
    "slur": "",
    "Latitude": 49.9667,
    "Longitude": 7.9,
    "Country": "Deutschland"
  },
  {
    "id": 682,
    "City": "Bischofswerda",
    "count": 6,
    "slur": "",
    "Latitude": 51.1277815,
    "Longitude": 14.1795516,
    "Country": "Deutschland"
  },
  {
    "id": 683,
    "City": "Butjadingen/Isens",
    "count": 6,
    "slur": "",
    "Latitude": 53.5460564,
    "Longitude": 8.3929706,
    "Country": "Deutschland"
  },
  {
    "id": 684,
    "City": "Calw",
    "count": 6,
    "slur": "",
    "Latitude": 48.7112108,
    "Longitude": 8.7452043,
    "Country": "Deutschland"
  },
  {
    "id": 685,
    "City": "Cunewalde",
    "count": 6,
    "slur": "",
    "Latitude": 51.098481,
    "Longitude": 14.514475,
    "Country": "Deutschland"
  },
  {
    "id": 686,
    "City": "Dillingen",
    "count": 6,
    "slur": "",
    "Latitude": 48.5812547,
    "Longitude": 10.4951425,
    "Country": "Deutschland"
  },
  {
    "id": 687,
    "City": "Düren-Niederau",
    "count": 6,
    "slur": "",
    "Latitude": 50.7640821,
    "Longitude": 6.4936131,
    "Country": "Deutschland"
  },
  {
    "id": 688,
    "City": "Ebern-Eyrichshof",
    "count": 6,
    "slur": "",
    "Latitude": 50.1094887,
    "Longitude": 10.7845619,
    "Country": "Deutschland"
  },
  {
    "id": 689,
    "City": "Einbeck",
    "count": 6,
    "slur": "",
    "Latitude": 51.8185067,
    "Longitude": 9.8678465,
    "Country": "Deutschland"
  },
  {
    "id": 690,
    "City": "Ergolding",
    "count": 6,
    "slur": "",
    "Latitude": 48.5763412,
    "Longitude": 12.1714786,
    "Country": "Deutschland"
  },
  {
    "id": 691,
    "City": "Falkenberg",
    "count": 6,
    "slur": "",
    "Latitude": 56.904894,
    "Longitude": 12.4912926,
    "Country": "Sverige"
  },
  {
    "id": 692,
    "City": "Frechen",
    "count": 6,
    "slur": "",
    "Latitude": 50.9096219,
    "Longitude": 6.8081935,
    "Country": "Deutschland"
  },
  {
    "id": 693,
    "City": "Frohnleiten",
    "count": 6,
    "slur": "",
    "Latitude": 47.2715315,
    "Longitude": 15.3260206,
    "Country": "Österreich"
  },
  {
    "id": 694,
    "City": "Germersheim",
    "count": 6,
    "slur": "",
    "Latitude": 49.2222749,
    "Longitude": 8.36659,
    "Country": "Deutschland"
  },
  {
    "id": 695,
    "City": "Glücksburg",
    "count": 6,
    "slur": "",
    "Latitude": 54.84544485,
    "Longitude": 9.562033403,
    "Country": "Deutschland"
  },
  {
    "id": 696,
    "City": "Hallwang",
    "count": 6,
    "slur": "",
    "Latitude": 47.8511946,
    "Longitude": 13.0830349,
    "Country": "Österreich"
  },
  {
    "id": 697,
    "City": "Hartha",
    "count": 6,
    "slur": "",
    "Latitude": 51.0984539,
    "Longitude": 12.9715576,
    "Country": "Deutschland"
  },
  {
    "id": 698,
    "City": "Heringsdorf",
    "count": 6,
    "slur": "",
    "Latitude": 53.9543267,
    "Longitude": 14.1673508,
    "Country": "Deutschland"
  },
  {
    "id": 699,
    "City": "Holzminden",
    "count": 6,
    "slur": "",
    "Latitude": 51.8989989,
    "Longitude": 9.5741099,
    "Country": "Deutschland"
  },
  {
    "id": 700,
    "City": "Jülich",
    "count": 6,
    "slur": "",
    "Latitude": 50.9220931,
    "Longitude": 6.3611015,
    "Country": "Deutschland"
  },
  {
    "id": 701,
    "City": "Kellinghusen",
    "count": 6,
    "slur": "",
    "Latitude": 53.949549,
    "Longitude": 9.7188996,
    "Country": "Deutschland"
  },
  {
    "id": 702,
    "City": "Ketzin/Havel Ot Paretz",
    "count": 6,
    "slur": "",
    "Latitude": "",
    "Longitude": "",
    "Country": ""
  },
  {
    "id": 703,
    "City": "Kilb",
    "count": 6,
    "slur": "",
    "Latitude": 48.1006741,
    "Longitude": 15.409716,
    "Country": "Österreich"
  },
  {
    "id": 704,
    "City": "Kirchberg I.T.",
    "count": 6,
    "slur": "",
    "Latitude": 48.1006741,
    "Longitude": 15.409716,
    "Country": "Österreich"
  },
  {
    "id": 705,
    "City": "Kirchdorf",
    "count": 6,
    "slur": "",
    "Latitude": 52.5966234,
    "Longitude": 8.8355037,
    "Country": "Deutschland"
  },
  {
    "id": 706,
    "City": "Klipphausen",
    "count": 6,
    "slur": "",
    "Latitude": 51.0749571,
    "Longitude": 13.5293546,
    "Country": "Deutschland"
  },
  {
    "id": 707,
    "City": "Kraichtal",
    "count": 6,
    "slur": "",
    "Latitude": 49.1319246,
    "Longitude": 8.7375729,
    "Country": "Deutschland"
  },
  {
    "id": 708,
    "City": "Kremsmünster",
    "count": 6,
    "slur": "",
    "Latitude": 48.0533652,
    "Longitude": 14.128766,
    "Country": "Österreich"
  },
  {
    "id": 709,
    "City": "Lampertheim",
    "count": 6,
    "slur": "",
    "Latitude": 48.6503574,
    "Longitude": 7.7003567,
    "Country": "France"
  },
  {
    "id": 710,
    "City": "Leuna",
    "count": 6,
    "slur": "",
    "Latitude": 51.3233638,
    "Longitude": 12.0195081,
    "Country": "Deutschland"
  },
  {
    "id": 711,
    "City": "Lingen/Ems",
    "count": 6,
    "slur": "",
    "Latitude": 52.5224659,
    "Longitude": 7.316584,
    "Country": "Deutschland"
  },
  {
    "id": 712,
    "City": "Losheim am See/Saar",
    "count": 6,
    "slur": "",
    "Latitude": 49.5082531,
    "Longitude": 6.7447054,
    "Country": "Deutschland"
  },
  {
    "id": 713,
    "City": "Lübben (Spreewald)",
    "count": 6,
    "slur": "",
    "Latitude": 51.9418987,
    "Longitude": 13.8963805,
    "Country": "Deutschland"
  },
  {
    "id": 714,
    "City": "Maintal",
    "count": 6,
    "slur": "",
    "Latitude": 50.143872,
    "Longitude": 8.8371444,
    "Country": "Deutschland"
  },
  {
    "id": 715,
    "City": "Maria Anzbach",
    "count": 6,
    "slur": "",
    "Latitude": 48.189586,
    "Longitude": 15.930371,
    "Country": "Österreich"
  },
  {
    "id": 716,
    "City": "Markt Indersdorf",
    "count": 6,
    "slur": "",
    "Latitude": 48.3607668,
    "Longitude": 11.374301,
    "Country": "Deutschland"
  },
  {
    "id": 717,
    "City": "Mayrhofen",
    "count": 6,
    "slur": "",
    "Latitude": 47.1672188,
    "Longitude": 11.8638664,
    "Country": "Österreich"
  },
  {
    "id": 718,
    "City": "Menden",
    "count": 6,
    "slur": "",
    "Latitude": 51.43779,
    "Longitude": 7.7953822,
    "Country": "Deutschland"
  },
  {
    "id": 719,
    "City": "Meran",
    "count": 6,
    "slur": "",
    "Latitude": 46.6695547,
    "Longitude": 11.1594185,
    "Country": "Italia"
  },
  {
    "id": 720,
    "City": "Münster-Hiltrup",
    "count": 6,
    "slur": "",
    "Latitude": 51.9025964,
    "Longitude": 7.6428337,
    "Country": "Deutschland"
  },
  {
    "id": 721,
    "City": "Neu-Isenburg",
    "count": 6,
    "slur": "",
    "Latitude": 50.0464196,
    "Longitude": 8.6717748,
    "Country": "Deutschland"
  },
  {
    "id": 722,
    "City": "Neustadt/Wstr.-Mussbach",
    "count": 6,
    "slur": "",
    "Latitude": "",
    "Longitude": "",
    "Country": ""
  },
  {
    "id": 723,
    "City": "Niederau/Ot Oberau",
    "count": 6,
    "slur": "",
    "Latitude": "",
    "Longitude": "",
    "Country": ""
  },
  {
    "id": 724,
    "City": "Nienburg/Weser",
    "count": 6,
    "slur": "",
    "Latitude": 52.6487602,
    "Longitude": 9.257810517,
    "Country": "Deutschland"
  },
  {
    "id": 725,
    "City": "Oberndorf",
    "count": 6,
    "slur": "",
    "Latitude": 48.2908613,
    "Longitude": 8.5711222,
    "Country": "Deutschland"
  },
  {
    "id": 726,
    "City": "Rechberghausen",
    "count": 6,
    "slur": "",
    "Latitude": 48.7304376,
    "Longitude": 9.6438315,
    "Country": "Deutschland"
  },
  {
    "id": 727,
    "City": "Reichenschwand",
    "count": 6,
    "slur": "",
    "Latitude": 49.5131237,
    "Longitude": 11.3718039,
    "Country": "Deutschland"
  },
  {
    "id": 728,
    "City": "Reinsberg",
    "count": 6,
    "slur": "",
    "Latitude": 51.0101314,
    "Longitude": 13.3581795,
    "Country": "Deutschland"
  },
  {
    "id": 729,
    "City": "Ritterhude",
    "count": 6,
    "slur": "",
    "Latitude": 53.1886097,
    "Longitude": 8.7521428,
    "Country": "Deutschland"
  },
  {
    "id": 730,
    "City": "Schermbeck",
    "count": 6,
    "slur": "",
    "Latitude": 51.6926147,
    "Longitude": 6.870219,
    "Country": "Deutschland"
  },
  {
    "id": 731,
    "City": "Schladming",
    "count": 6,
    "slur": "",
    "Latitude": 47.3940415,
    "Longitude": 13.6867878,
    "Country": "Österreich"
  },
  {
    "id": 732,
    "City": "Schopfheim",
    "count": 6,
    "slur": "",
    "Latitude": 47.6500525,
    "Longitude": 7.8216997,
    "Country": "Deutschland"
  },
  {
    "id": 733,
    "City": "Seevetal",
    "count": 6,
    "slur": "",
    "Latitude": 53.3965903,
    "Longitude": 10.0176952,
    "Country": "Deutschland"
  },
  {
    "id": 734,
    "City": "Soltau",
    "count": 6,
    "slur": "",
    "Latitude": 52.9859666,
    "Longitude": 9.8433909,
    "Country": "Deutschland"
  },
  {
    "id": 735,
    "City": "Spremberg",
    "count": 6,
    "slur": "",
    "Latitude": 51.5714513,
    "Longitude": 14.3804302,
    "Country": "Deutschland"
  },
  {
    "id": 736,
    "City": "Stadtallendorf",
    "count": 6,
    "slur": "",
    "Latitude": 50.82595195,
    "Longitude": 9.036930305,
    "Country": "Deutschland"
  },
  {
    "id": 737,
    "City": "Steinheim an der Murr",
    "count": 6,
    "slur": "",
    "Latitude": 48.9652015,
    "Longitude": 9.2789021,
    "Country": "Deutschland"
  },
  {
    "id": 738,
    "City": "Stendal",
    "count": 6,
    "slur": "",
    "Latitude": 52.6050782,
    "Longitude": 11.8594279,
    "Country": "Deutschland"
  },
  {
    "id": 739,
    "City": "Straelen",
    "count": 6,
    "slur": "",
    "Latitude": 51.4439341,
    "Longitude": 6.2694388,
    "Country": "Deutschland"
  },
  {
    "id": 740,
    "City": "Sömmerda",
    "count": 6,
    "slur": "",
    "Latitude": 51.1618258,
    "Longitude": 11.1174874,
    "Country": "Deutschland"
  },
  {
    "id": 741,
    "City": "Tauberbischofsheim",
    "count": 6,
    "slur": "",
    "Latitude": 49.6229682,
    "Longitude": 9.6627138,
    "Country": "Deutschland"
  },
  {
    "id": 742,
    "City": "Torgelow",
    "count": 6,
    "slur": "",
    "Latitude": 53.6304232,
    "Longitude": 14.0113284,
    "Country": "Deutschland"
  },
  {
    "id": 743,
    "City": "Ursberg",
    "count": 6,
    "slur": "",
    "Latitude": 48.2650708,
    "Longitude": 10.4445686,
    "Country": "Deutschland"
  },
  {
    "id": 744,
    "City": "Wagna",
    "count": 6,
    "slur": "",
    "Latitude": 46.766314,
    "Longitude": 15.5571703,
    "Country": "Österreich"
  },
  {
    "id": 745,
    "City": "Warin",
    "count": 6,
    "slur": "",
    "Latitude": 53.7998004,
    "Longitude": 11.7043569,
    "Country": "Deutschland"
  },
  {
    "id": 746,
    "City": "Weil der Stadt",
    "count": 6,
    "slur": "",
    "Latitude": 48.7501041,
    "Longitude": 8.8707147,
    "Country": "Deutschland"
  },
  {
    "id": 747,
    "City": "Wertheim",
    "count": 6,
    "slur": "",
    "Latitude": 49.7614982,
    "Longitude": 9.5155411,
    "Country": "Deutschland"
  },
  {
    "id": 748,
    "City": "Winden am See",
    "count": 6,
    "slur": "",
    "Latitude": 47.9440351,
    "Longitude": 16.76320358,
    "Country": "Österreich"
  },
  {
    "id": 749,
    "City": "Würzburg-Heidingsfeld",
    "count": 6,
    "slur": "",
    "Latitude": 49.7520734,
    "Longitude": 9.933974,
    "Country": "Deutschland"
  },
  {
    "id": 750,
    "City": "Abenberg bei Nürnberg",
    "count": 5,
    "slur": "",
    "Latitude": "",
    "Longitude": "",
    "Country": ""
  },
  {
    "id": 751,
    "City": "Aitrang",
    "count": 5,
    "slur": "",
    "Latitude": 47.819518,
    "Longitude": 10.5356002,
    "Country": "Deutschland"
  },
  {
    "id": 752,
    "City": "Albershausen",
    "count": 5,
    "slur": "",
    "Latitude": 48.6919721,
    "Longitude": 9.5658873,
    "Country": "Deutschland"
  },
  {
    "id": 753,
    "City": "Altdorf",
    "count": 5,
    "slur": "",
    "Latitude": 46.8820732,
    "Longitude": 8.6413922,
    "Country": "Schweiz/Suisse/Svizzera/Svizra"
  },
  {
    "id": 754,
    "City": "Apolda",
    "count": 5,
    "slur": "",
    "Latitude": 51.0227878,
    "Longitude": 11.5129742,
    "Country": "Deutschland"
  },
  {
    "id": 755,
    "City": "Bad Endorf",
    "count": 5,
    "slur": "",
    "Latitude": 47.906411,
    "Longitude": 12.3013697,
    "Country": "Deutschland"
  },
  {
    "id": 756,
    "City": "Bad Friedrichshall",
    "count": 5,
    "slur": "",
    "Latitude": 49.2300761,
    "Longitude": 9.212604,
    "Country": "Deutschland"
  },
  {
    "id": 757,
    "City": "Bad Langensalza",
    "count": 5,
    "slur": "",
    "Latitude": 51.1082267,
    "Longitude": 10.6464452,
    "Country": "Deutschland"
  },
  {
    "id": 758,
    "City": "Bad Nenndorf",
    "count": 5,
    "slur": "",
    "Latitude": 52.3360719,
    "Longitude": 9.3769596,
    "Country": "Deutschland"
  },
  {
    "id": 759,
    "City": "Bad Salzungen",
    "count": 5,
    "slur": "",
    "Latitude": 50.812318,
    "Longitude": 10.2320784,
    "Country": "Deutschland"
  },
  {
    "id": 760,
    "City": "Bad Windsheim",
    "count": 5,
    "slur": "",
    "Latitude": 49.5020006,
    "Longitude": 10.4175301,
    "Country": "Deutschland"
  },
  {
    "id": 761,
    "City": "Bernau",
    "count": 5,
    "slur": "",
    "Latitude": 52.6787254,
    "Longitude": 13.5881114,
    "Country": "Deutschland"
  },
  {
    "id": 762,
    "City": "Beverungen",
    "count": 5,
    "slur": "",
    "Latitude": 51.6676535,
    "Longitude": 9.3759072,
    "Country": "Deutschland"
  },
  {
    "id": 763,
    "City": "Biberach",
    "count": 5,
    "slur": "",
    "Latitude": 48.101927,
    "Longitude": 9.7931144,
    "Country": "Deutschland"
  },
  {
    "id": 764,
    "City": "Braunau am Inn",
    "count": 5,
    "slur": "",
    "Latitude": 48.253136,
    "Longitude": 13.039506,
    "Country": "Österreich"
  },
  {
    "id": 765,
    "City": "Bruchhausen-Vilsen",
    "count": 5,
    "slur": "",
    "Latitude": 52.8288647,
    "Longitude": 8.988901,
    "Country": "Deutschland"
  },
  {
    "id": 766,
    "City": "Bruchköbel",
    "count": 5,
    "slur": "",
    "Latitude": 50.1845,
    "Longitude": 8.9369,
    "Country": "Deutschland"
  },
  {
    "id": 767,
    "City": "Bürstadt",
    "count": 5,
    "slur": "",
    "Latitude": 49.6416146,
    "Longitude": 8.4549068,
    "Country": "Deutschland"
  },
  {
    "id": 768,
    "City": "Büsum",
    "count": 5,
    "slur": "",
    "Latitude": 54.1298489,
    "Longitude": 8.8586989,
    "Country": "Deutschland"
  },
  {
    "id": 769,
    "City": "Darmstadt Eberstadt",
    "count": 5,
    "slur": "",
    "Latitude": 49.8106422,
    "Longitude": 8.6286797,
    "Country": "Deutschland"
  },
  {
    "id": 770,
    "City": "Dernau/Marienthal",
    "count": 5,
    "slur": "",
    "Latitude": 50.53740875,
    "Longitude": 7.058317257,
    "Country": "Deutschland"
  },
  {
    "id": 771,
    "City": "Donaueschingen",
    "count": 5,
    "slur": "",
    "Latitude": 47.9534194,
    "Longitude": 8.4959257,
    "Country": "Deutschland"
  },
  {
    "id": 772,
    "City": "Dudelange",
    "count": 5,
    "slur": "",
    "Latitude": 49.4786477,
    "Longitude": 6.0847792,
    "Country": "Lëtzebuerg"
  },
  {
    "id": 773,
    "City": "Duingen",
    "count": 5,
    "slur": "",
    "Latitude": 52.0057957,
    "Longitude": 9.6970268,
    "Country": "Deutschland"
  },
  {
    "id": 774,
    "City": "Eberbach",
    "count": 5,
    "slur": "",
    "Latitude": 48.9273858,
    "Longitude": 8.066278,
    "Country": "France"
  },
  {
    "id": 775,
    "City": "Eltville-Hattenheim",
    "count": 5,
    "slur": "",
    "Latitude": 50.0150007,
    "Longitude": 8.056326,
    "Country": "Deutschland"
  },
  {
    "id": 776,
    "City": "Emkendorf",
    "count": 5,
    "slur": "",
    "Latitude": 54.2623019,
    "Longitude": 9.8470685,
    "Country": "Deutschland"
  },
  {
    "id": 777,
    "City": "Erding/Eichenried",
    "count": 5,
    "slur": "",
    "Latitude": 48.2759422,
    "Longitude": 11.7905219,
    "Country": "Deutschland"
  },
  {
    "id": 778,
    "City": "Erdweg",
    "count": 5,
    "slur": "",
    "Latitude": 48.3328236,
    "Longitude": 11.3012652,
    "Country": "Deutschland"
  },
  {
    "id": 779,
    "City": "Esslingen am Neckar",
    "count": 5,
    "slur": "",
    "Latitude": 48.7427584,
    "Longitude": 9.3071685,
    "Country": "Deutschland"
  },
  {
    "id": 780,
    "City": "Fellbach",
    "count": 5,
    "slur": "",
    "Latitude": 48.8181556,
    "Longitude": 9.278085,
    "Country": "Deutschland"
  },
  {
    "id": 781,
    "City": "Filderstadt",
    "count": 5,
    "slur": "",
    "Latitude": 48.6616311,
    "Longitude": 9.2236805,
    "Country": "Deutschland"
  },
  {
    "id": 782,
    "City": "Freiberg/Sachsen",
    "count": 5,
    "slur": "",
    "Latitude": 50.9086704,
    "Longitude": 13.3445784,
    "Country": "Deutschland"
  },
  {
    "id": 783,
    "City": "Freital",
    "count": 5,
    "slur": "",
    "Latitude": 51.016265,
    "Longitude": 13.6530329,
    "Country": "Deutschland"
  },
  {
    "id": 784,
    "City": "Garsten",
    "count": 5,
    "slur": "",
    "Latitude": 48.0222203,
    "Longitude": 14.4077971,
    "Country": "Österreich"
  },
  {
    "id": 785,
    "City": "Geseke",
    "count": 5,
    "slur": "",
    "Latitude": 51.6395322,
    "Longitude": 8.512578,
    "Country": "Deutschland"
  },
  {
    "id": 786,
    "City": "Giebelstadt/Würzburg",
    "count": 5,
    "slur": "",
    "Latitude": 49.6526294,
    "Longitude": 9.9475152,
    "Country": "Deutschland"
  },
  {
    "id": 787,
    "City": "Grimma",
    "count": 5,
    "slur": "",
    "Latitude": 51.2383379,
    "Longitude": 12.7287511,
    "Country": "Deutschland"
  },
  {
    "id": 788,
    "City": "Grossarl",
    "count": 5,
    "slur": "",
    "Latitude": 47.2374042,
    "Longitude": 13.2013974,
    "Country": "Österreich"
  },
  {
    "id": 789,
    "City": "Hallstatt",
    "count": 5,
    "slur": "",
    "Latitude": 47.5621698,
    "Longitude": 13.6486697,
    "Country": "Österreich"
  },
  {
    "id": 790,
    "City": "Haltern am See",
    "count": 5,
    "slur": "",
    "Latitude": 51.7430516,
    "Longitude": 7.187221,
    "Country": "Deutschland"
  },
  {
    "id": 791,
    "City": "Haslach",
    "count": 5,
    "slur": "",
    "Latitude": 48.5841173,
    "Longitude": 8.831684052,
    "Country": "Deutschland"
  },
  {
    "id": 792,
    "City": "Hassmersheim",
    "count": 5,
    "slur": "",
    "Latitude": 49.3010953,
    "Longitude": 9.1466828,
    "Country": "Deutschland"
  },
  {
    "id": 793,
    "City": "Hemer",
    "count": 5,
    "slur": "",
    "Latitude": 51.3854116,
    "Longitude": 7.7661798,
    "Country": "Deutschland"
  },
  {
    "id": 794,
    "City": "Hohenmölsen",
    "count": 5,
    "slur": "",
    "Latitude": 51.1573976,
    "Longitude": 12.0981844,
    "Country": "Deutschland"
  },
  {
    "id": 795,
    "City": "Holzwickede",
    "count": 5,
    "slur": "",
    "Latitude": 51.5007037,
    "Longitude": 7.6186433,
    "Country": "Deutschland"
  },
  {
    "id": 796,
    "City": "Hornberg",
    "count": 5,
    "slur": "",
    "Latitude": 48.2128411,
    "Longitude": 8.2316356,
    "Country": "Deutschland"
  },
  {
    "id": 797,
    "City": "Hoya",
    "count": 5,
    "slur": "",
    "Latitude": 52.8065,
    "Longitude": 9.13957,
    "Country": "Deutschland"
  },
  {
    "id": 798,
    "City": "Ibbenbüren",
    "count": 5,
    "slur": "",
    "Latitude": 52.2774772,
    "Longitude": 7.7151417,
    "Country": "Deutschland"
  },
  {
    "id": 799,
    "City": "Ingelheim",
    "count": 5,
    "slur": "",
    "Latitude": 53.7313889,
    "Longitude": 21.0586111,
    "Country": "Polska"
  },
  {
    "id": 800,
    "City": "Isen",
    "count": 5,
    "slur": "",
    "Latitude": 48.2120342,
    "Longitude": 12.0571384,
    "Country": "Deutschland"
  },
  {
    "id": 801,
    "City": "Iserlohn",
    "count": 5,
    "slur": "",
    "Latitude": 51.3746778,
    "Longitude": 7.6999713,
    "Country": "Deutschland"
  },
  {
    "id": 802,
    "City": "Kerkrade",
    "count": 5,
    "slur": "",
    "Latitude": 50.87489555,
    "Longitude": 6.059386696,
    "Country": "Nederland"
  },
  {
    "id": 803,
    "City": "Kerpen",
    "count": 5,
    "slur": "",
    "Latitude": 50.3097609,
    "Longitude": 6.7293316,
    "Country": "Deutschland"
  },
  {
    "id": 804,
    "City": "Krems",
    "count": 5,
    "slur": "",
    "Latitude": 48.4108382,
    "Longitude": 15.6003717,
    "Country": "Österreich"
  },
  {
    "id": 805,
    "City": "Kössen",
    "count": 5,
    "slur": "",
    "Latitude": 47.6468128,
    "Longitude": 12.36484244,
    "Country": "Österreich"
  },
  {
    "id": 806,
    "City": "Landau an der Isar",
    "count": 5,
    "slur": "",
    "Latitude": 48.6728826,
    "Longitude": 12.6941291,
    "Country": "Deutschland"
  },
  {
    "id": 807,
    "City": "Landgraaf Nl",
    "count": 5,
    "slur": "",
    "Latitude": 50.9080845,
    "Longitude": 6.0264604,
    "Country": "Nederland"
  },
  {
    "id": 808,
    "City": "Lennestadt-Elspe",
    "count": 5,
    "slur": "",
    "Latitude": 51.1534131,
    "Longitude": 8.046321,
    "Country": "Deutschland"
  },
  {
    "id": 809,
    "City": "Lingen (Ems)",
    "count": 5,
    "slur": "",
    "Latitude": 52.5224659,
    "Longitude": 7.316584,
    "Country": "Deutschland"
  },
  {
    "id": 810,
    "City": "Luckau",
    "count": 5,
    "slur": "",
    "Latitude": 51.8524163,
    "Longitude": 13.7150055,
    "Country": "Deutschland"
  },
  {
    "id": 811,
    "City": "Ludwigshafen",
    "count": 5,
    "slur": "",
    "Latitude": 49.4704113,
    "Longitude": 8.4381568,
    "Country": "Deutschland"
  },
  {
    "id": 812,
    "City": "Marienberg",
    "count": 5,
    "slur": "",
    "Latitude": 50.6507279,
    "Longitude": 13.163188,
    "Country": "Deutschland"
  },
  {
    "id": 813,
    "City": "Merzig-Saar",
    "count": 5,
    "slur": "",
    "Latitude": 49.4365845,
    "Longitude": 6.6341891,
    "Country": "Deutschland"
  },
  {
    "id": 814,
    "City": "Mühldorf am Inn",
    "count": 5,
    "slur": "",
    "Latitude": 48.2405007,
    "Longitude": 12.5250991,
    "Country": "Deutschland"
  },
  {
    "id": 815,
    "City": "Neuenrade",
    "count": 5,
    "slur": "",
    "Latitude": 51.2831254,
    "Longitude": 7.7830374,
    "Country": "Deutschland"
  },
  {
    "id": 816,
    "City": "Neumarkt an der Ybbs",
    "count": 5,
    "slur": "",
    "Latitude": 48.1405853,
    "Longitude": 15.0591107,
    "Country": "Österreich"
  },
  {
    "id": 817,
    "City": "Nossen",
    "count": 5,
    "slur": "",
    "Latitude": 51.1232755,
    "Longitude": 13.3116361,
    "Country": "Deutschland"
  },
  {
    "id": 818,
    "City": "Olpe",
    "count": 5,
    "slur": "",
    "Latitude": 51.029464,
    "Longitude": 7.8438856,
    "Country": "Deutschland"
  },
  {
    "id": 819,
    "City": "Overath",
    "count": 5,
    "slur": "",
    "Latitude": 50.9320015,
    "Longitude": 7.2839042,
    "Country": "Deutschland"
  },
  {
    "id": 820,
    "City": "Petersberg/Fulda",
    "count": 5,
    "slur": "",
    "Latitude": 50.576674,
    "Longitude": 9.7375148,
    "Country": "Deutschland"
  },
  {
    "id": 821,
    "City": "Pillnitz",
    "count": 5,
    "slur": "",
    "Latitude": 51.0126628,
    "Longitude": 13.8713801,
    "Country": "Deutschland"
  },
  {
    "id": 822,
    "City": "Plainfeld",
    "count": 5,
    "slur": "",
    "Latitude": 47.8323325,
    "Longitude": 13.1831669,
    "Country": "Österreich"
  },
  {
    "id": 823,
    "City": "Rees",
    "count": 5,
    "slur": "",
    "Latitude": 51.7581242,
    "Longitude": 6.3956605,
    "Country": "Deutschland"
  },
  {
    "id": 824,
    "City": "Rheinbach",
    "count": 5,
    "slur": "",
    "Latitude": 50.6256808,
    "Longitude": 6.9491436,
    "Country": "Deutschland"
  },
  {
    "id": 825,
    "City": "Ruhpolding",
    "count": 5,
    "slur": "",
    "Latitude": 47.7620099,
    "Longitude": 12.6459934,
    "Country": "Deutschland"
  },
  {
    "id": 826,
    "City": "Rust",
    "count": 5,
    "slur": "",
    "Latitude": 47.8037042,
    "Longitude": 16.68901429,
    "Country": "Österreich"
  },
  {
    "id": 827,
    "City": "Rödental",
    "count": 5,
    "slur": "",
    "Latitude": 50.2971914,
    "Longitude": 11.0428276,
    "Country": "Deutschland"
  },
  {
    "id": 828,
    "City": "Rödermark/Ober-Roden",
    "count": 5,
    "slur": "",
    "Latitude": 49.9733473,
    "Longitude": 8.8289584,
    "Country": "Deutschland"
  },
  {
    "id": 829,
    "City": "Rüdersdorf",
    "count": 5,
    "slur": "",
    "Latitude": 52.471293,
    "Longitude": 13.784527,
    "Country": "Deutschland"
  },
  {
    "id": 830,
    "City": "Sachsenheim",
    "count": 5,
    "slur": "",
    "Latitude": 48.9654062,
    "Longitude": 9.0583956,
    "Country": "Deutschland"
  },
  {
    "id": 831,
    "City": "Sankt Augustin",
    "count": 5,
    "slur": "",
    "Latitude": 50.7752776,
    "Longitude": 7.1895507,
    "Country": "Deutschland"
  },
  {
    "id": 832,
    "City": "Schleiden",
    "count": 5,
    "slur": "",
    "Latitude": 50.5285919,
    "Longitude": 6.4761556,
    "Country": "Deutschland"
  },
  {
    "id": 833,
    "City": "Schmalkalden",
    "count": 5,
    "slur": "",
    "Latitude": 50.7213643,
    "Longitude": 10.4510467,
    "Country": "Deutschland"
  },
  {
    "id": 834,
    "City": "Schwalmstadt",
    "count": 5,
    "slur": "",
    "Latitude": 50.9257352,
    "Longitude": 9.2020472,
    "Country": "Deutschland"
  },
  {
    "id": 835,
    "City": "Schwerte",
    "count": 5,
    "slur": "",
    "Latitude": 51.445525,
    "Longitude": 7.5674652,
    "Country": "Deutschland"
  },
  {
    "id": 836,
    "City": "Schönberg",
    "count": 5,
    "slur": "",
    "Latitude": 53.6781808,
    "Longitude": 10.4318363,
    "Country": "Deutschland"
  },
  {
    "id": 837,
    "City": "Schönenberg-Kübelberg",
    "count": 5,
    "slur": "",
    "Latitude": 49.39804195,
    "Longitude": 7.379821803,
    "Country": "Deutschland"
  },
  {
    "id": 838,
    "City": "Simbach am Inn",
    "count": 5,
    "slur": "",
    "Latitude": 48.2663113,
    "Longitude": 13.0279972,
    "Country": "Deutschland"
  },
  {
    "id": 839,
    "City": "St. Martin im Innkreis",
    "count": 5,
    "slur": "",
    "Latitude": 48.2932421,
    "Longitude": 13.4386654,
    "Country": "Österreich"
  },
  {
    "id": 840,
    "City": "Tamm",
    "count": 5,
    "slur": "",
    "Latitude": 48.9244825,
    "Longitude": 9.1237094,
    "Country": "Deutschland"
  },
  {
    "id": 841,
    "City": "Teutschenthal",
    "count": 5,
    "slur": "",
    "Latitude": 51.45,
    "Longitude": 11.8,
    "Country": "Deutschland"
  },
  {
    "id": 842,
    "City": "Trechtingshausen",
    "count": 5,
    "slur": "",
    "Latitude": 50.0112841,
    "Longitude": 7.847867,
    "Country": "Deutschland"
  },
  {
    "id": 843,
    "City": "Usingen",
    "count": 5,
    "slur": "",
    "Latitude": 50.3342403,
    "Longitude": 8.5369972,
    "Country": "Deutschland"
  },
  {
    "id": 844,
    "City": "Weinböhla",
    "count": 5,
    "slur": "",
    "Latitude": 51.1618943,
    "Longitude": 13.5643531,
    "Country": "Deutschland"
  },
  {
    "id": 845,
    "City": "Werder (Havel)",
    "count": 5,
    "slur": "",
    "Latitude": 52.3715809,
    "Longitude": 12.9329125,
    "Country": "Deutschland"
  },
  {
    "id": 846,
    "City": "Wernigerode/Harz",
    "count": 5,
    "slur": "",
    "Latitude": 51.8344172,
    "Longitude": 10.7862526,
    "Country": "Deutschland"
  },
  {
    "id": 847,
    "City": "Wiesen",
    "count": 5,
    "slur": "",
    "Latitude": 50.1121928,
    "Longitude": 9.3660625,
    "Country": "Deutschland"
  },
  {
    "id": 848,
    "City": "Winnenden",
    "count": 5,
    "slur": "",
    "Latitude": 48.8754571,
    "Longitude": 9.3978478,
    "Country": "Deutschland"
  },
  {
    "id": 849,
    "City": "Wittenberg",
    "count": 5,
    "slur": "",
    "Latitude": 51.8666527,
    "Longitude": 12.646761,
    "Country": "Deutschland"
  },
  {
    "id": 850,
    "City": "Wundschuh",
    "count": 5,
    "slur": "",
    "Latitude": 46.9260357,
    "Longitude": 15.4508945,
    "Country": "Österreich"
  },
  {
    "id": 851,
    "City": "Würselen",
    "count": 5,
    "slur": "",
    "Latitude": 50.8178682,
    "Longitude": 6.1341108,
    "Country": "Deutschland"
  },
  {
    "id": 852,
    "City": "Ansbach",
    "count": 4,
    "slur": "",
    "Latitude": 49.3028611,
    "Longitude": 10.5722288,
    "Country": "Deutschland"
  },
  {
    "id": 853,
    "City": "Arbon",
    "count": 4,
    "slur": "",
    "Latitude": 43.0007,
    "Longitude": 0.747026,
    "Country": "France"
  },
  {
    "id": 854,
    "City": "Aschheim",
    "count": 4,
    "slur": "",
    "Latitude": 48.1713481,
    "Longitude": 11.7160347,
    "Country": "Deutschland"
  },
  {
    "id": 855,
    "City": "Bad Blankenburg",
    "count": 4,
    "slur": "",
    "Latitude": 50.6829946,
    "Longitude": 11.2726327,
    "Country": "Deutschland"
  },
  {
    "id": 856,
    "City": "Bad Saulgau",
    "count": 4,
    "slur": "",
    "Latitude": 48.0158071,
    "Longitude": 9.5010309,
    "Country": "Deutschland"
  },
  {
    "id": 857,
    "City": "Bad Sooden-Allendorf",
    "count": 4,
    "slur": "",
    "Latitude": 51.2695391,
    "Longitude": 9.9719975,
    "Country": "Deutschland"
  },
  {
    "id": 858,
    "City": "Barnstädt",
    "count": 4,
    "slur": "",
    "Latitude": 51.34467,
    "Longitude": 11.636912,
    "Country": "Deutschland"
  },
  {
    "id": 859,
    "City": "Basel/Schweiz",
    "count": 4,
    "slur": "",
    "Latitude": 47.5581077,
    "Longitude": 7.5878261,
    "Country": "Schweiz/Suisse/Svizzera/Svizra"
  },
  {
    "id": 860,
    "City": "Benediktbeuern",
    "count": 4,
    "slur": "",
    "Latitude": 47.7060548,
    "Longitude": 11.4100763,
    "Country": "Deutschland"
  },
  {
    "id": 861,
    "City": "Berchtesgaden",
    "count": 4,
    "slur": "",
    "Latitude": 47.63108,
    "Longitude": 13.0021634,
    "Country": "Deutschland"
  },
  {
    "id": 862,
    "City": "Bergkamen",
    "count": 4,
    "slur": "",
    "Latitude": 51.6149389,
    "Longitude": 7.6362876,
    "Country": "Deutschland"
  },
  {
    "id": 863,
    "City": "Billerbeck",
    "count": 4,
    "slur": "",
    "Latitude": 51.9773625,
    "Longitude": 7.290923,
    "Country": "Deutschland"
  },
  {
    "id": 864,
    "City": "Bitburg",
    "count": 4,
    "slur": "",
    "Latitude": 49.9732762,
    "Longitude": 6.524947,
    "Country": "Deutschland"
  },
  {
    "id": 865,
    "City": "Blankenfelde",
    "count": 4,
    "slur": "",
    "Latitude": 52.3384429,
    "Longitude": 13.4153952,
    "Country": "Deutschland"
  },
  {
    "id": 866,
    "City": "Borna",
    "count": 4,
    "slur": "",
    "Latitude": 51.1241649,
    "Longitude": 12.4999055,
    "Country": "Deutschland"
  },
  {
    "id": 867,
    "City": "Creuzburg bei Eisenach",
    "count": 4,
    "slur": "",
    "Latitude": "",
    "Longitude": "",
    "Country": ""
  },
  {
    "id": 868,
    "City": "Daun",
    "count": 4,
    "slur": "",
    "Latitude": 50.1962461,
    "Longitude": 6.8305031,
    "Country": "Deutschland"
  },
  {
    "id": 869,
    "City": "Dingolfing",
    "count": 4,
    "slur": "",
    "Latitude": 48.6300801,
    "Longitude": 12.4977434,
    "Country": "Deutschland"
  },
  {
    "id": 870,
    "City": "Donauwörth",
    "count": 4,
    "slur": "",
    "Latitude": 48.7180364,
    "Longitude": 10.7807299,
    "Country": "Deutschland"
  },
  {
    "id": 871,
    "City": "Durbach",
    "count": 4,
    "slur": "",
    "Latitude": 48.4946385,
    "Longitude": 8.0182635,
    "Country": "Deutschland"
  },
  {
    "id": 872,
    "City": "Ebersdorf",
    "count": 4,
    "slur": "",
    "Latitude": 53.5277157,
    "Longitude": 9.0541751,
    "Country": "Deutschland"
  },
  {
    "id": 873,
    "City": "Eisenberg/Thüringen",
    "count": 4,
    "slur": "",
    "Latitude": 50.9691346,
    "Longitude": 11.8961116,
    "Country": "Deutschland"
  },
  {
    "id": 874,
    "City": "Emmendingen",
    "count": 4,
    "slur": "",
    "Latitude": 48.1342091,
    "Longitude": 7.862308289,
    "Country": "Deutschland"
  },
  {
    "id": 875,
    "City": "Espenau",
    "count": 4,
    "slur": "",
    "Latitude": 51.3913412,
    "Longitude": 9.4582002,
    "Country": "Deutschland"
  },
  {
    "id": 876,
    "City": "Feldkirchen",
    "count": 4,
    "slur": "",
    "Latitude": 48.1475774,
    "Longitude": 11.7299205,
    "Country": "Deutschland"
  },
  {
    "id": 877,
    "City": "Freiberg am Neckar",
    "count": 4,
    "slur": "",
    "Latitude": 48.9345539,
    "Longitude": 9.1922557,
    "Country": "Deutschland"
  },
  {
    "id": 878,
    "City": "Geislingen",
    "count": 4,
    "slur": "",
    "Latitude": 48.2886289,
    "Longitude": 8.8135495,
    "Country": "Deutschland"
  },
  {
    "id": 879,
    "City": "Geldern",
    "count": 4,
    "slur": "",
    "Latitude": 51.5169736,
    "Longitude": 6.3228189,
    "Country": "Deutschland"
  },
  {
    "id": 880,
    "City": "Gevelsberg",
    "count": 4,
    "slur": "",
    "Latitude": 51.3207417,
    "Longitude": 7.3404792,
    "Country": "Deutschland"
  },
  {
    "id": 881,
    "City": "Gramastetten",
    "count": 4,
    "slur": "",
    "Latitude": 48.3791617,
    "Longitude": 14.1917923,
    "Country": "Österreich"
  },
  {
    "id": 882,
    "City": "Gratkorn",
    "count": 4,
    "slur": "",
    "Latitude": 47.1326223,
    "Longitude": 15.3390907,
    "Country": "Österreich"
  },
  {
    "id": 883,
    "City": "Greinbach/Hartberg",
    "count": 4,
    "slur": "",
    "Latitude": 47.3113284,
    "Longitude": 15.9643405,
    "Country": "Österreich"
  },
  {
    "id": 884,
    "City": "Grenchen, So",
    "count": 4,
    "slur": "",
    "Latitude": 47.1911495,
    "Longitude": 7.3967199,
    "Country": "Schweiz/Suisse/Svizzera/Svizra"
  },
  {
    "id": 885,
    "City": "Gross-Umstadt",
    "count": 4,
    "slur": "",
    "Latitude": 49.86477,
    "Longitude": 8.95669,
    "Country": "Deutschland"
  },
  {
    "id": 886,
    "City": "Grossenhain",
    "count": 4,
    "slur": "",
    "Latitude": 51.2911845,
    "Longitude": 13.5317153,
    "Country": "Deutschland"
  },
  {
    "id": 887,
    "City": "Groß Offenseth-Aspern",
    "count": 4,
    "slur": "",
    "Latitude": 53.817776,
    "Longitude": 9.729477,
    "Country": "Deutschland"
  },
  {
    "id": 888,
    "City": "Gröbming",
    "count": 4,
    "slur": "",
    "Latitude": 47.4459809,
    "Longitude": 13.9008766,
    "Country": "Österreich"
  },
  {
    "id": 889,
    "City": "Hallbergmoos",
    "count": 4,
    "slur": "",
    "Latitude": 48.3182064,
    "Longitude": 11.7445128,
    "Country": "Deutschland"
  },
  {
    "id": 890,
    "City": "Haltern",
    "count": 4,
    "slur": "",
    "Latitude": 51.7430516,
    "Longitude": 7.187221,
    "Country": "Deutschland"
  },
  {
    "id": 891,
    "City": "Havelberg",
    "count": 4,
    "slur": "",
    "Latitude": 52.8250425,
    "Longitude": 12.0741929,
    "Country": "Deutschland"
  },
  {
    "id": 892,
    "City": "Hechingen",
    "count": 4,
    "slur": "",
    "Latitude": 48.3525529,
    "Longitude": 8.9642047,
    "Country": "Deutschland"
  },
  {
    "id": 893,
    "City": "Heilbad Heiligenstadt",
    "count": 4,
    "slur": "",
    "Latitude": 51.3756186,
    "Longitude": 10.138224,
    "Country": "Deutschland"
  },
  {
    "id": 894,
    "City": "Hemsbach",
    "count": 4,
    "slur": "",
    "Latitude": 49.5919545,
    "Longitude": 8.6460261,
    "Country": "Deutschland"
  },
  {
    "id": 895,
    "City": "Hersbruck",
    "count": 4,
    "slur": "",
    "Latitude": 49.5085843,
    "Longitude": 11.4285065,
    "Country": "Deutschland"
  },
  {
    "id": 896,
    "City": "Herscheid",
    "count": 4,
    "slur": "",
    "Latitude": 51.1795935,
    "Longitude": 7.7443634,
    "Country": "Deutschland"
  },
  {
    "id": 897,
    "City": "Hilchenbach",
    "count": 4,
    "slur": "",
    "Latitude": 50.9935863,
    "Longitude": 8.107365,
    "Country": "Deutschland"
  },
  {
    "id": 898,
    "City": "Hünxe",
    "count": 4,
    "slur": "",
    "Latitude": 51.6414581,
    "Longitude": 6.7660319,
    "Country": "Deutschland"
  },
  {
    "id": 899,
    "City": "Idar-Oberstein",
    "count": 4,
    "slur": "",
    "Latitude": 49.7107134,
    "Longitude": 7.3136563,
    "Country": "Deutschland"
  },
  {
    "id": 900,
    "City": "Insel Mainau",
    "count": 4,
    "slur": "",
    "Latitude": 47.70685,
    "Longitude": 9.196708,
    "Country": "Deutschland"
  },
  {
    "id": 901,
    "City": "Isselburg/Heelden",
    "count": 4,
    "slur": "",
    "Latitude": 51.8102014,
    "Longitude": 6.441987992,
    "Country": "Deutschland"
  },
  {
    "id": 902,
    "City": "Jachenau",
    "count": 4,
    "slur": "",
    "Latitude": 47.6039611,
    "Longitude": 11.4347846,
    "Country": "Deutschland"
  },
  {
    "id": 903,
    "City": "Kaltenkirchen",
    "count": 4,
    "slur": "",
    "Latitude": 53.8371714,
    "Longitude": 9.9614889,
    "Country": "Deutschland"
  },
  {
    "id": 904,
    "City": "Kapfenberg-Schirmitzbühel",
    "count": 4,
    "slur": "",
    "Latitude": 47.46482055,
    "Longitude": 15.33139834,
    "Country": "Österreich"
  },
  {
    "id": 905,
    "City": "Kitzbühel",
    "count": 4,
    "slur": "",
    "Latitude": 47.4463585,
    "Longitude": 12.3911473,
    "Country": "Österreich"
  },
  {
    "id": 906,
    "City": "Königstein",
    "count": 4,
    "slur": "",
    "Latitude": 50.1818833,
    "Longitude": 8.465271,
    "Country": "Deutschland"
  },
  {
    "id": 907,
    "City": "Ladenburg",
    "count": 4,
    "slur": "",
    "Latitude": 49.4718989,
    "Longitude": 8.6101969,
    "Country": "Deutschland"
  },
  {
    "id": 908,
    "City": "Lahnstein",
    "count": 4,
    "slur": "",
    "Latitude": 50.3090305,
    "Longitude": 7.6044602,
    "Country": "Deutschland"
  },
  {
    "id": 909,
    "City": "Lienz",
    "count": 4,
    "slur": "",
    "Latitude": 46.82958615,
    "Longitude": 12.75031621,
    "Country": "Österreich"
  },
  {
    "id": 910,
    "City": "Liezen",
    "count": 4,
    "slur": "",
    "Latitude": 47.5070965,
    "Longitude": 14.21256838,
    "Country": "Österreich"
  },
  {
    "id": 911,
    "City": "Loburg",
    "count": 4,
    "slur": "",
    "Latitude": 52.1140753,
    "Longitude": 12.0770309,
    "Country": "Deutschland"
  },
  {
    "id": 912,
    "City": "Lohr am Main",
    "count": 4,
    "slur": "",
    "Latitude": 49.9954978,
    "Longitude": 9.5733619,
    "Country": "Deutschland"
  },
  {
    "id": 913,
    "City": "Losheim am See",
    "count": 4,
    "slur": "",
    "Latitude": 49.5082531,
    "Longitude": 6.7447054,
    "Country": "Deutschland"
  },
  {
    "id": 914,
    "City": "Mahlberg",
    "count": 4,
    "slur": "",
    "Latitude": 48.2871478,
    "Longitude": 7.8124221,
    "Country": "Deutschland"
  },
  {
    "id": 915,
    "City": "Marchtrenk",
    "count": 4,
    "slur": "",
    "Latitude": 48.1907992,
    "Longitude": 14.1117625,
    "Country": "Österreich"
  },
  {
    "id": 916,
    "City": "Markdorf",
    "count": 4,
    "slur": "",
    "Latitude": 47.7206084,
    "Longitude": 9.3917257,
    "Country": "Deutschland"
  },
  {
    "id": 917,
    "City": "Markneukirchen",
    "count": 4,
    "slur": "",
    "Latitude": 50.311883,
    "Longitude": 12.3292151,
    "Country": "Deutschland"
  },
  {
    "id": 918,
    "City": "Marl-Brassert",
    "count": 4,
    "slur": "",
    "Latitude": 51.6726964,
    "Longitude": 7.075648,
    "Country": "Deutschland"
  },
  {
    "id": 919,
    "City": "Meldorf",
    "count": 4,
    "slur": "",
    "Latitude": 54.08988,
    "Longitude": 9.070633,
    "Country": "Deutschland"
  },
  {
    "id": 920,
    "City": "Micheldorf",
    "count": 4,
    "slur": "",
    "Latitude": 46.914433,
    "Longitude": 14.4266404,
    "Country": "Österreich"
  },
  {
    "id": 921,
    "City": "Mölln",
    "count": 4,
    "slur": "",
    "Latitude": 53.62892,
    "Longitude": 10.6880174,
    "Country": "Deutschland"
  },
  {
    "id": 922,
    "City": "Mörlenbach",
    "count": 4,
    "slur": "",
    "Latitude": 49.5982679,
    "Longitude": 8.7354401,
    "Country": "Deutschland"
  },
  {
    "id": 923,
    "City": "Mülheim-Kärlich",
    "count": 4,
    "slur": "",
    "Latitude": 50.3866121,
    "Longitude": 7.5014584,
    "Country": "Deutschland"
  },
  {
    "id": 924,
    "City": "Nettetal",
    "count": 4,
    "slur": "",
    "Latitude": 51.3155092,
    "Longitude": 6.2714171,
    "Country": "Deutschland"
  },
  {
    "id": 925,
    "City": "Neunburg Vorm Wald",
    "count": 4,
    "slur": "",
    "Latitude": 49.3487414,
    "Longitude": 12.3819853,
    "Country": "Deutschland"
  },
  {
    "id": 926,
    "City": "Neustadt an der Aisch",
    "count": 4,
    "slur": "",
    "Latitude": 49.5819105,
    "Longitude": 10.6077372,
    "Country": "Deutschland"
  },
  {
    "id": 927,
    "City": "Neustadt/Weinstrasse",
    "count": 4,
    "slur": "",
    "Latitude": 49.3495669,
    "Longitude": 8.1403886,
    "Country": "Deutschland"
  },
  {
    "id": 928,
    "City": "Nienhagen",
    "count": 4,
    "slur": "",
    "Latitude": 54.159117,
    "Longitude": 11.955593,
    "Country": "Deutschland"
  },
  {
    "id": 929,
    "City": "Norderney",
    "count": 4,
    "slur": "",
    "Latitude": 53.71238565,
    "Longitude": 7.234019789,
    "Country": "Deutschland"
  },
  {
    "id": 930,
    "City": "Nordhausen Rüdigsdorf",
    "count": 4,
    "slur": "",
    "Latitude": 51.5407245,
    "Longitude": 10.8181763,
    "Country": "Deutschland"
  },
  {
    "id": 931,
    "City": "Oer-Erkenschwick",
    "count": 4,
    "slur": "",
    "Latitude": 51.6398512,
    "Longitude": 7.2605914,
    "Country": "Deutschland"
  },
  {
    "id": 932,
    "City": "Oestrich-Winkel",
    "count": 4,
    "slur": "",
    "Latitude": 50.0071863,
    "Longitude": 8.0167585,
    "Country": "Deutschland"
  },
  {
    "id": 933,
    "City": "Ohlsdorf",
    "count": 4,
    "slur": "",
    "Latitude": 53.6215437,
    "Longitude": 10.0377601,
    "Country": "Deutschland"
  },
  {
    "id": 934,
    "City": "Ortenburg",
    "count": 4,
    "slur": "",
    "Latitude": 48.5467319,
    "Longitude": 13.2262574,
    "Country": "Deutschland"
  },
  {
    "id": 935,
    "City": "Ostseebad Sellin",
    "count": 4,
    "slur": "",
    "Latitude": 54.3770567,
    "Longitude": 13.6942765,
    "Country": "Deutschland"
  },
  {
    "id": 936,
    "City": "Parchim",
    "count": 4,
    "slur": "",
    "Latitude": 53.4258455,
    "Longitude": 11.8475244,
    "Country": "Deutschland"
  },
  {
    "id": 937,
    "City": "Pfaffenhofen",
    "count": 4,
    "slur": "",
    "Latitude": 47.2987869,
    "Longitude": 11.0830431,
    "Country": "Österreich"
  },
  {
    "id": 938,
    "City": "Pfaffenhofen a. d. Ilm",
    "count": 4,
    "slur": "",
    "Latitude": 48.5296743,
    "Longitude": 11.5084954,
    "Country": "Deutschland"
  },
  {
    "id": 939,
    "City": "Pfarrkirchen",
    "count": 4,
    "slur": "",
    "Latitude": 48.4320329,
    "Longitude": 12.9386266,
    "Country": "Deutschland"
  },
  {
    "id": 940,
    "City": "Pichl bei Wels",
    "count": 4,
    "slur": "",
    "Latitude": 48.1851073,
    "Longitude": 13.9007289,
    "Country": "Österreich"
  },
  {
    "id": 941,
    "City": "Pinkafeld",
    "count": 4,
    "slur": "",
    "Latitude": 47.37841315,
    "Longitude": 16.10917002,
    "Country": "Österreich"
  },
  {
    "id": 942,
    "City": "Pirmasens",
    "count": 4,
    "slur": "",
    "Latitude": 49.1996961,
    "Longitude": 7.6087847,
    "Country": "Deutschland"
  },
  {
    "id": 943,
    "City": "Prenzlau",
    "count": 4,
    "slur": "",
    "Latitude": 53.3167,
    "Longitude": 13.866575,
    "Country": "Deutschland"
  },
  {
    "id": 944,
    "City": "Prien",
    "count": 4,
    "slur": "",
    "Latitude": 47.8541669,
    "Longitude": 12.3455493,
    "Country": "Deutschland"
  },
  {
    "id": 945,
    "City": "Puchheim",
    "count": 4,
    "slur": "",
    "Latitude": 48.1704908,
    "Longitude": 11.3526351,
    "Country": "Deutschland"
  },
  {
    "id": 946,
    "City": "Pullach",
    "count": 4,
    "slur": "",
    "Latitude": 48.0556122,
    "Longitude": 11.5217455,
    "Country": "Deutschland"
  },
  {
    "id": 947,
    "City": "Reisenberg",
    "count": 4,
    "slur": "",
    "Latitude": 47.9982784,
    "Longitude": 16.5262157,
    "Country": "Österreich"
  },
  {
    "id": 948,
    "City": "Reitwein",
    "count": 4,
    "slur": "",
    "Latitude": 52.501454,
    "Longitude": 14.5829471,
    "Country": "Deutschland"
  },
  {
    "id": 949,
    "City": "Riehen",
    "count": 4,
    "slur": "",
    "Latitude": 47.5816927,
    "Longitude": 7.6479737,
    "Country": "Schweiz/Suisse/Svizzera/Svizra"
  },
  {
    "id": 950,
    "City": "Rinteln",
    "count": 4,
    "slur": "",
    "Latitude": 52.1873798,
    "Longitude": 9.0804584,
    "Country": "Deutschland"
  },
  {
    "id": 951,
    "City": "Rottenburg",
    "count": 4,
    "slur": "",
    "Latitude": 48.47696,
    "Longitude": 8.9336788,
    "Country": "Deutschland"
  },
  {
    "id": 952,
    "City": "Röthenbach an der Pegnitz",
    "count": 4,
    "slur": "",
    "Latitude": 49.485011,
    "Longitude": 11.2474853,
    "Country": "Deutschland"
  },
  {
    "id": 953,
    "City": "Saalfelden",
    "count": 4,
    "slur": "",
    "Latitude": 47.416719,
    "Longitude": 12.84778,
    "Country": "Österreich"
  },
  {
    "id": 954,
    "City": "Salem",
    "count": 4,
    "slur": "",
    "Latitude": 44.9391565,
    "Longitude": -123.033121,
    "Country": "United States"
  },
  {
    "id": 955,
    "City": "Sankt Michael im Lungau",
    "count": 4,
    "slur": "",
    "Latitude": 47.1154536,
    "Longitude": 13.60991034,
    "Country": "Österreich"
  },
  {
    "id": 956,
    "City": "Schortens",
    "count": 4,
    "slur": "",
    "Latitude": 53.5366547,
    "Longitude": 7.9467348,
    "Country": "Deutschland"
  },
  {
    "id": 957,
    "City": "Schwaigern-Stetten",
    "count": 4,
    "slur": "",
    "Latitude": 49.1321662,
    "Longitude": 9.0086095,
    "Country": "Deutschland"
  },
  {
    "id": 958,
    "City": "Schwelm",
    "count": 4,
    "slur": "",
    "Latitude": 51.2841166,
    "Longitude": 7.2924712,
    "Country": "Deutschland"
  },
  {
    "id": 959,
    "City": "Schwäbisch Gmünd",
    "count": 4,
    "slur": "",
    "Latitude": 48.7999036,
    "Longitude": 9.7977584,
    "Country": "Deutschland"
  },
  {
    "id": 960,
    "City": "Seebronn (Rottenburg am Neckar)",
    "count": 4,
    "slur": "",
    "Latitude": 48.5072059,
    "Longitude": 8.8746281,
    "Country": "Deutschland"
  },
  {
    "id": 961,
    "City": "Seefeld in Tirol",
    "count": 4,
    "slur": "",
    "Latitude": 47.3289241,
    "Longitude": 11.1867187,
    "Country": "Österreich"
  },
  {
    "id": 962,
    "City": "Seehausen",
    "count": 4,
    "slur": "",
    "Latitude": 52.8888609,
    "Longitude": 11.7519908,
    "Country": "Deutschland"
  },
  {
    "id": 963,
    "City": "Seelze",
    "count": 4,
    "slur": "",
    "Latitude": 52.3964639,
    "Longitude": 9.594287,
    "Country": "Deutschland"
  },
  {
    "id": 964,
    "City": "Sondershausen",
    "count": 4,
    "slur": "",
    "Latitude": 51.3666041,
    "Longitude": 10.8668419,
    "Country": "Deutschland"
  },
  {
    "id": 965,
    "City": "Stassfurt",
    "count": 4,
    "slur": "",
    "Latitude": 51.8514515,
    "Longitude": 11.5929455,
    "Country": "Deutschland"
  },
  {
    "id": 966,
    "City": "Staudach-Egerndach",
    "count": 4,
    "slur": "",
    "Latitude": 47.7779415,
    "Longitude": 12.50854454,
    "Country": "Deutschland"
  },
  {
    "id": 967,
    "City": "Steinbach/Langenbach",
    "count": 4,
    "slur": "",
    "Latitude": 50.5215782,
    "Longitude": 10.8573121,
    "Country": "Deutschland"
  },
  {
    "id": 968,
    "City": "Steinhude",
    "count": 4,
    "slur": "",
    "Latitude": 52.4566199,
    "Longitude": 9.3589259,
    "Country": "Deutschland"
  },
  {
    "id": 969,
    "City": "Stolberg Südharz",
    "count": 4,
    "slur": "",
    "Latitude": 51.5732926,
    "Longitude": 10.9548323,
    "Country": "Deutschland"
  },
  {
    "id": 970,
    "City": "Sulz am Neckar",
    "count": 4,
    "slur": "",
    "Latitude": 48.3617509,
    "Longitude": 8.6314329,
    "Country": "Deutschland"
  },
  {
    "id": 971,
    "City": "Sulzfeld am Main",
    "count": 4,
    "slur": "",
    "Latitude": 49.7080478,
    "Longitude": 10.1309726,
    "Country": "Deutschland"
  },
  {
    "id": 972,
    "City": "Tettnang",
    "count": 4,
    "slur": "",
    "Latitude": 47.6716558,
    "Longitude": 9.5891158,
    "Country": "Deutschland"
  },
  {
    "id": 973,
    "City": "Thale/Harz",
    "count": 4,
    "slur": "",
    "Latitude": 51.7527606,
    "Longitude": 11.037722,
    "Country": "Deutschland"
  },
  {
    "id": 974,
    "City": "Trebsen",
    "count": 4,
    "slur": "",
    "Latitude": 51.2894732,
    "Longitude": 12.7517578,
    "Country": "Deutschland"
  },
  {
    "id": 975,
    "City": "Uchte",
    "count": 4,
    "slur": "",
    "Latitude": 52.498907,
    "Longitude": 8.907115,
    "Country": "Deutschland"
  },
  {
    "id": 976,
    "City": "Velbert",
    "count": 4,
    "slur": "",
    "Latitude": 51.3406713,
    "Longitude": 7.0439912,
    "Country": "Deutschland"
  },
  {
    "id": 977,
    "City": "Vorchdorf",
    "count": 4,
    "slur": "",
    "Latitude": 48.0035141,
    "Longitude": 13.922837,
    "Country": "Österreich"
  },
  {
    "id": 978,
    "City": "Walsrode",
    "count": 4,
    "slur": "",
    "Latitude": 52.8625395,
    "Longitude": 9.5883023,
    "Country": "Deutschland"
  },
  {
    "id": 979,
    "City": "Wasserburg",
    "count": 4,
    "slur": "",
    "Latitude": 48.0615171,
    "Longitude": 12.220026,
    "Country": "Deutschland"
  },
  {
    "id": 980,
    "City": "Werne",
    "count": 4,
    "slur": "",
    "Latitude": 51.66268,
    "Longitude": 7.6355052,
    "Country": "Deutschland"
  },
  {
    "id": 981,
    "City": "Wernigerode",
    "count": 4,
    "slur": "",
    "Latitude": 51.8344172,
    "Longitude": 10.7862526,
    "Country": "Deutschland"
  },
  {
    "id": 982,
    "City": "Wertingen",
    "count": 4,
    "slur": "",
    "Latitude": 48.5601209,
    "Longitude": 10.6808075,
    "Country": "Deutschland"
  },
  {
    "id": 983,
    "City": "Wesseling",
    "count": 4,
    "slur": "",
    "Latitude": 50.8247166,
    "Longitude": 6.9810852,
    "Country": "Deutschland"
  },
  {
    "id": 984,
    "City": "Wettenberg",
    "count": 4,
    "slur": "",
    "Latitude": 50.6372722,
    "Longitude": 8.648955,
    "Country": "Deutschland"
  },
  {
    "id": 985,
    "City": "Wiltingen",
    "count": 4,
    "slur": "",
    "Latitude": 49.6591723,
    "Longitude": 6.590185,
    "Country": "Deutschland"
  },
  {
    "id": 986,
    "City": "Wissen",
    "count": 4,
    "slur": "",
    "Latitude": 50.7829232,
    "Longitude": 7.7344766,
    "Country": "Deutschland"
  },
  {
    "id": 987,
    "City": "Worms",
    "count": 4,
    "slur": "",
    "Latitude": 49.6302618,
    "Longitude": 8.3620898,
    "Country": "Deutschland"
  },
  {
    "id": 988,
    "City": "Zeitz",
    "count": 4,
    "slur": "",
    "Latitude": 51.0491637,
    "Longitude": 12.1349991,
    "Country": "Deutschland"
  },
  {
    "id": 989,
    "City": "Zülpich",
    "count": 4,
    "slur": "",
    "Latitude": 50.6922459,
    "Longitude": 6.6472694,
    "Country": "Deutschland"
  },
  {
    "id": 990,
    "City": "Abenberg",
    "count": 3,
    "slur": "",
    "Latitude": 49.2419719,
    "Longitude": 10.9638694,
    "Country": "Deutschland"
  },
  {
    "id": 991,
    "City": "Achim",
    "count": 3,
    "slur": "",
    "Latitude": 53.0096048,
    "Longitude": 9.039649,
    "Country": "Deutschland"
  },
  {
    "id": 992,
    "City": "Adelsried",
    "count": 3,
    "slur": "",
    "Latitude": 48.4252851,
    "Longitude": 10.7193232,
    "Country": "Deutschland"
  },
  {
    "id": 993,
    "City": "Albstadt Ebingen",
    "count": 3,
    "slur": "",
    "Latitude": 48.2102719,
    "Longitude": 9.0272498,
    "Country": "Deutschland"
  },
  {
    "id": 994,
    "City": "Aldersbach",
    "count": 3,
    "slur": "",
    "Latitude": 48.5881793,
    "Longitude": 13.0846579,
    "Country": "Deutschland"
  },
  {
    "id": 995,
    "City": "Altenberg",
    "count": 3,
    "slur": "",
    "Latitude": 50.766598,
    "Longitude": 13.7523234,
    "Country": "Deutschland"
  },
  {
    "id": 996,
    "City": "Alzenau-Michelbach",
    "count": 3,
    "slur": "",
    "Latitude": 50.1019529,
    "Longitude": 9.1045829,
    "Country": "Deutschland"
  },
  {
    "id": 997,
    "City": "Amriswil Tg",
    "count": 3,
    "slur": "",
    "Latitude": 47.5500227,
    "Longitude": 9.2981219,
    "Country": "Schweiz/Suisse/Svizzera/Svizra"
  },
  {
    "id": 998,
    "City": "Anger",
    "count": 3,
    "slur": "",
    "Latitude": 47.2744566,
    "Longitude": 15.6901034,
    "Country": "Österreich"
  },
  {
    "id": 999,
    "City": "Angermünde",
    "count": 3,
    "slur": "",
    "Latitude": 53.017276,
    "Longitude": 13.9981503,
    "Country": "Deutschland"
  },
  {
    "id": 1000,
    "City": "Assen",
    "count": 3,
    "slur": "",
    "Latitude": 52.9952273,
    "Longitude": 6.560498,
    "Country": "Nederland"
  }
]
export default cities
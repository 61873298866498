import React from "react";
import { Link } from "react-router-dom";

import Logo from "./logo";
import SearchBarContainer from "../search_bar/search_bar_container";
import SiteNav from "./site_nav";
import SessionNav from "./session_nav";
import UserNav from "./user_nav";
import CountryFilter from "./country_filter";
import Plans from "./Plans";
import Events from "./Events";

class NavBar extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let currentUser = this.props.currentUser;
    let logout = this.props.logout;
    let fetchUser = this.props.fetchUser;
    let fetchAllProducts = this.props.fetchAllProducts;
    // console.log("sssssssssssssss", currentUser);

    let userSessionNav = currentUser?.token ? (
      <UserNav
        currentUser={currentUser}
        logout={logout}
        fetchUser={fetchUser}
        fetchAllProducts={fetchAllProducts}
      />
    ) : (
      <SessionNav
        openModal={this.props.openModal}
        clearErrors={this.props.clearErrors}
      />
    );
    return (
      <div className="headerColor">
        <Logo></Logo>
        {/* <CountryFilter> </CountryFilter> */}
        <div className="headerLinks">
          <Link className="linksImp" to="/home">
            Home
          </Link>
          {/* <Link className="linksImp" to="/about" target="_blank">
            Membership Plans
          </Link> */}
          <div className="linksImp">
            <Plans />
          </div>
          <div className="linksImp">
            <Events />
          </div>
          <Link className="linksImp" to="/about">
            About Us
          </Link>
          <Link className="linksImp" to="/contact">
            Contact Us
          </Link>
        </div>
        {userSessionNav}
      </div>
    );
  }
}

export default NavBar;

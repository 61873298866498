import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import validator from "validator";
import axios from "axios";

import i from "react-fontawesome";
import { tagSearch } from "../../actions/search_actions";

// import React from "react";
import GoogleMapReact from "google-map-react";

const ProductIndexItem = ({
  history,
  product,
  createUpvote,
  destroyUpvote,
  fetchAllProducts,
  currentUser,
  token,
  viewedProductId,
  fetchProduct,
  tagSearch,
  openModal,
  closeModal,
  clearErrors,
}) => {
  const AnyReactComponent = ({ text }) => <div>{text}</div>;
  const monthNames = [
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  const [loading, setLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [validEmail, setValidity] = useState(false);
  const [query, setQuery] = useState("");
  const [imageUrl2, setImageUrl] = useState("");
  const emailAddressPopUp = () => {
    setShowPopup(true);
  };
  const emailAddressPopUpClose = () => {
    setShowPopup(false);
  };
  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  useEffect(() => {
    const fetchFirstImage = async () => {
      if (!product.imageUrl) {
        const response = await axios.get(
          "https://www.googleapis.com/customsearch/v1",
          {
            params: {
              q: product.name,
              cx: "25785c3e85bb94fa7", // Replace with your Custom Search Engine ID
              key: "AIzaSyCUODRXx8NHsx53eWkUgnlv2MzmTzk76Yc", // Replace with your API key
              searchType: "image",
              num: 1,
            },
          }
        );

        setImageUrl(response.data.items[0].link);
      }
    };

    fetchFirstImage();
  }, [product.name]);

  let eventsRawDate = "";
  let day = "";
  let month = "";
  let time = "";
  let hour = "";
  let TimeType = "";
  let minutes = "";
  let seconds = "";

  if (!product) {
    return;
  }
  if (product?.typeAttributes?.liveEntertainment?.startDate) {
    eventsRawDate = product.typeAttributes.liveEntertainment.startDate;
    var a = new Date(eventsRawDate);
    day = a.getDate();
    month = monthNames[a.getMonth()];
    hour = a.getHours();

    // Checking if the Hour is less than equals to 11 then Set the Time format as AM.
    if (hour <= 11) {
      TimeType = "AM";
    } else {
      // If the Hour is Not less than equals to 11 then Set the Time format as PM.
      TimeType = "PM";
    }

    // IF current hour is grater than 12 then minus 12 from current hour to make it in 12 Hours Format.
    if (hour > 12) {
      hour = hour - 12;
    }

    // If hour value is 0 then by default set its value to 12, because 24 means 0 in 24 hours time format.
    if (hour == 0) {
      hour = 12;
    }

    // Getting the current minutes from date object.
    minutes = a.getMinutes();

    // Checking if the minutes value is less then 10 then add 0 before minutes.
    if (minutes < 10) {
      minutes = "0" + minutes.toString();
    }

    //Getting current seconds from date object.
    seconds = a.getSeconds();

    // If seconds value is less than 10 then add 0 before seconds.
    if (seconds < 10) {
      seconds = "0" + seconds.toString();
    }

    // Adding all the variables in fullTime variable.
    time =
      hour.toString() +
      ":" +
      minutes.toString() +
      ":" +
      seconds.toString() +
      " " +
      TimeType.toString();
    // time = `${a.getHours()}:${a.getMinutes()}`;
  } else if (product?.startDate) {
    eventsRawDate = product?.startDate;
    var a = new Date(eventsRawDate);
    day = a.getDate();
    month = monthNames[a.getMonth()];
    hour = a.getHours();

    // Checking if the Hour is less than equals to 11 then Set the Time format as AM.
    if (hour <= 11) {
      TimeType = "AM";
    } else {
      // If the Hour is Not less than equals to 11 then Set the Time format as PM.
      TimeType = "PM";
    }

    // IF current hour is grater than 12 then minus 12 from current hour to make it in 12 Hours Format.
    if (hour > 12) {
      hour = hour - 12;
    }

    // If hour value is 0 then by default set its value to 12, because 24 means 0 in 24 hours time format.
    if (hour == 0) {
      hour = 12;
    }

    // Getting the current minutes from date object.
    minutes = a.getMinutes();

    // Checking if the minutes value is less then 10 then add 0 before minutes.
    if (minutes < 10) {
      minutes = "0" + minutes.toString();
    }

    //Getting current seconds from date object.
    seconds = a.getSeconds();

    // If seconds value is less than 10 then add 0 before seconds.
    if (seconds < 10) {
      seconds = "0" + seconds.toString();
    }

    // Adding all the variables in fullTime variable.
    time =
      hour.toString() +
      ":" +
      minutes.toString() +
      ":" +
      seconds.toString() +
      " " +
      TimeType.toString();
    // time = `${a.getHours()}:${a.getMinutes()}`;
  }

  // const { currentUserUpvoted } = product;
  let currentUserUpvoted = 0;
  let xy = [];
  let productUpvoteCount = [];
  let productUpvoteCountString = "";
  let count = 0;
  // console.log(currentUser);

  if (product?.history_or_background) {
    // console.log(product?.history_or_background);
    // console.log(typeof product?.history_or_background);
    // console.log(product);
    count = JSON.parse(product?.history_or_background);
    productUpvoteCountString = product.history_or_background;
  }
  if (currentUser && count) {
    currentUserUpvoted = count.includes(currentUser);
  }
  // const url = product.url;
  // console.log(product);
  // let x = product.url;
  // // console.log("suket");
  // // console.log(x);
  // // console.log("suket");
  // if (x.indexOf("https://") == -1) {
  //   x = "https://" + x;
  // }
  // let domain = new URL(x);
  // domain = domain.hostname;

  // domain = domain.hostname.replace("www.", "");
  const handleOnClickListItem = (e) => {
    // console.log(e);
    if (e.target.id != "upvotelistbutton") {
      history.push(`/products/${JSON.stringify(product)}`);
    } else {
      // console.log(currentUser.token.access);
      if (!currentUser) {
        alert("Please Login or Signup to purchase tickets.");
      }
      const token = currentUser.token.access;
      if (token) {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);

        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        fetch("https://admin1.bonzaico.com/email/profile", requestOptions)
          .then((response) => response.json())
          .then((result) => {
            if (result.tc === 0) {
              alert("To purchase tickets. Please verify your email.");
            } else if (result.tc === 1) {
              var newWindow = window.open();
              newWindow.location = `${product.link}`;
            }
          })
          .catch((error) => alert("error", error));
      }
    }
    // else if (e.target.id == "upvotelistbutton") {
    //   let url = window.location.href;
    //   url = url.split("query=");

    //   const query = url[1];
    //   console.log(query);
    //   if (query) {
    //     history.push(`/search?query=${query}`);
    //   } else {
    //     console.log("aadADada");
    //     history.push("/");
    //   }
  };

  const toggleUpvote = (e) => {
    // window.open;
    // e.stopPropagation();
    // console.log(currentUser);
    // // console.log(e.target);
    // if (currentUserUpvoted) {
    //   if (productUpvoteCountString) {
    //     productUpvoteCount = JSON.parse(productUpvoteCountString);
    //     xy = productUpvoteCount.filter((item) => item !== currentUser);
    //     console.log(token);
    //     console.log(viewedProductId);
    //     console.log(JSON.stringify(xy));
    //   }
    //   createUpvote(token, viewedProductId, JSON.stringify(xy));
    //   // fetchProduct(viewedProductId);
    // } else {
    //   if (productUpvoteCountString) {
    //     productUpvoteCount = JSON.parse(productUpvoteCountString);
    //     productUpvoteCount.push(currentUser);
    //     console.log(productUpvoteCount);
    //     xy = JSON.stringify(productUpvoteCount);
    //   }
    //   console.log(productUpvoteCount);
    //   if (productUpvoteCount[productUpvoteCount.length - 1]) {
    //     // console.log(typeof x);
    //     createUpvote(token, viewedProductId, xy);
    //     // fetchAllProducts().then(() => {
    //     //   setLoading(false);
    //     //});
    //   } else {
    //     // console.log(props);
    //     clearErrors();
    //     alert("Login/SignUp to Upvote");
    //     return () => {
    //       console.log("props");
    //       openModal("signup");
    //     };
    //   }
    // }
    // if (e.target.id != "upvotelistbutton") {
    //   setTimeout(() => {
    //     fetchProduct(viewedProductId);
    //   }, "100");
    // } else if (e.target.id == "upvotelistbutton" && currentUser) {
    //   // fetchAllProducts().then(() => {
    //   //   setLoading(false);
    //   // });
    //   console.log(e.target.id);
    //   let url = window.location.href;
    //   url = url.split("query=");
    //   const query = url[1];
    //   console.log(query);
    //   if (query) {
    //     // console.log(query);
    //     setTimeout(() => {
    //       tagSearch(query);
    //     }, "200");
    //     // tagSearch(query);
    //     // .then(() => history.push(`/`));
    //   } else {
    //     setTimeout(() => {
    //       fetchAllProducts();
    //     }, "200");
    //     // fetchAllProducts();
    //     // .then(() => history.push(`/`));
    //   }
    // }
  };

  const commentCount = product.comments_count;
  const upvoteButtonClass = validEmail
    ? "orange-button small-size"
    : "white-button small-size";
  // let currentUserUpvotedtext =
  const upvoteLikeButtonText = currentUserUpvoted ? "UPVOTED " : "UPVOTE ";
  const paymenturl = product.url;
  let locationName =
    product?.typeAttributes?.liveEntertainment?.location?.name ||
    product?.products[0].typeAttributes?.liveEntertainment?.location?.name;
  let locationcity =
    product?.typeAttributes?.liveEntertainment?.location?.city ||
    product?.products[0].typeAttributes?.liveEntertainment?.location?.city;

  let price = product?.price || product?.products[0].price;

  return (
    <li
      className="product-index-item"
      onClick={(e) => handleOnClickListItem(e)}
    >
      <div className="product-index-item-img">
        {product.imageUrl && (
          <img
            className="product-index-image"
            src={product.imageUrl}
            // onError={(e) => (e.target.src = "missing-logo-dummy.png")}
          />
        )}
        {!product.imageUrl && (
          <img
            className="product-index-image"
            src={imageUrl2}
            alt={product.name}
            // onError={(e) => (e.target.src = "missing-logo-dummy.png")}
          />
        )}
      </div>

      <div className="product-index-item-text">
        <div>
          <p className="product-name">{product.name}</p>

          <p className="tags">
            {day} {month}
          </p>
          <p>{time}</p>
          <div>
            {locationName}, {locationcity}
          </div>
        </div>

        <div className="product-item-buttons">
          <div>
            <p>Tickets {product.status}</p>
            <div>Price: {price}€</div>
          </div>
          <div
            id="upvotelistbutton"
            className="upvotelistbutton"
            // href={`${product.link}`}
            // target="_blank"
          >
            Buy Ticket
          </div>
        </div>
        <p className="product-tagline">{product.description}</p>

        {/* <div className="product-item-buttons">
          <div className="product-item-tags"></div>
          <div className="product-item-minor-actions">
            {showPopup && (
              <div className="popup" id="upvotelistbutton">
                <div className="popup-content" id="upvotelistbutton">
                  <h2
                    className="popup-close-button"
                    onClick={emailAddressPopUpClose}
                    id="upvotelistbutton"
                  >
                    ×
                  </h2>
                  <h1 id="upvotelistbutton">Email Us</h1>
                  <h3 id="upvotelistbutton">
                  will contact you with ticket information.
                  </h3>
                  <input
                    id="upvotelistbtton"
                    className="popup-email-input"
                    placeholder="Email: example@example.com"
                    type="email"
                    onChange={(e) => {
                      // console.log(e.target.value);
                      // console.log(validateEmail(e.target.value));
                      if (validateEmail(e.target.value) == null) {
                        setValidity(false);
                        console.log(upvoteButtonClass);
                      } else {
                        setValidity(true);
                        console.log(upvoteButtonClass);
                      }
                    }}
                  />
                  <button className={upvoteButtonClass} id="upvotelistbutton">
                    Send
                  </button>
                </div>
              </div>
            )}
          </div>
        </div> */}
      </div>
    </li>
  );
};

export default withRouter(ProductIndexItem);

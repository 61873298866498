import $ from "jquery";

export const getUser = (userId) => {
  return new Promise((resolve, reject) => {
    var settings = {
      url: "https://admin1.bonzaico.com/email/profile",
      method: "GET",
      timeout: 0,
      headers: {
        Authorization: `Bearer ${userId}`,
      },
    };

    $.ajax(settings).done(function (response) {
      resolve(response);
    });
  });
};

export const patchUser = (userId, first_name, second_name, phone, address) => {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userId}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      first_name: first_name,
      second_name: second_name,
      phone: phone,
      address: address,
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://admin1.bonzaico.com/email/profile/update", requestOptions)
      .then((response) => response.json())
      .then((result) => resolve(result))
      .catch((error) => resolve(error));
  });
};

import { connect } from 'react-redux';
import SearchBar from './search_bar';
import { omniSearch } from '../../actions/search_actions';

const mapStateToProps = (state) => {
  let searchTerm = "";
  let searchResults = state.entities.searchResults;
  // console.log(searchResults)
  // let productResults = state.entities.tagSearchResults;
  // console.log("logi")
  // console.log(state.entities.searchResults)
  
  return {
    searchTerm,
    searchResults,
    // productResults
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    omniSearch: (query) => dispatch(omniSearch(query)),
    // tagSearch: (r) => dispatch(tagSearch(r))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
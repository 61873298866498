import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

class ProductNav extends React.Component {
  constructor(props) {
    super(props);
    let productIds = this.props.productIds;
    let dat = new Date();
    let da = dat.getDate();
    let day = da;
    if (da == "1") {
      day = "01";
    } else if (da == "2") {
      day = "02";
    } else if (da == "3") {
      day = "03";
    } else if (da == "4") {
      day = "04";
    } else if (da == "5") {
      day = "05";
    } else if (da == "6") {
      day = "06";
    } else if (da == "7") {
      day = "07";
    } else if (da == "8") {
      day = "08";
    } else if (da == "9") {
      day = "09";
    } else {
      day = da;
    }
    const months = [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
    ];
    let mont = months[dat.getMonth()];
    let month = mont;
    if (mont == "1") {
      month = "01";
    } else if (mont == "2") {
      month = "02";
    } else if (mont == "3") {
      month = "03";
    } else if (mont == "4") {
      month = "04";
    } else if (mont == "5") {
      month = "05";
    } else if (mont == "6") {
      month = "06";
    } else if (mont == "7") {
      month = "07";
    } else if (mont == "8") {
      month = "08";
    } else if (mont == "9") {
      month = "09";
    } else {
      month = mont;
    }
    let year = dat.getFullYear();
    let currentDate = year + "-" + month + "-" + day;

    let currentUrlDateR = window.location.href.split("?query=")[1];
    // console.log(currentUrlDateR);
    if (currentUrlDateR) {
      currentDate = currentUrlDateR.substring(0, currentUrlDateR.length - 2);
      // console.log(currentDate);

      // this.setState({ date: currentDate });
    }
    this.state = { date: currentDate };
    // console.log(productIds);
  }

  render() {
    // const [value, onChange] = useState(new Date());
    const months = [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
    ];
    let iid = {};
    let all = [];
    let sortable = [];
    let productIds = this.props.productIds;
    for (iid in productIds) {
      if (productIds[iid].tag_primary) {
        let id = productIds[iid];
        // console.log(id);
        let x = JSON.parse(id?.tag_primary);
        let y = JSON.parse(id?.tag_secondary);
        let z = x.concat(y);
        all = all.concat(z);
      }
    }

    // console.log(all);
    const counts = {};

    for (const num of all) {
      counts[num] = counts[num] ? counts[num] + 1 : 1;
    }

    for (var vehicle in counts) {
      sortable.push([vehicle, counts[vehicle]]);
    }

    sortable.sort(function (a, b) {
      return b[1] - a[1];
    });

    // console.log(sortable);
    // const [value, onChange] = useState(new Date());
    // let d = this.state.date;
    // let date = d.getDate();
    // let month = d.getMonth() + 1;
    // let year = d.getFullYear();
    // let s = "";
    // console.log(s);
    return (
      <div className="product-nav">
        <p className="product-nav-header">Search by Date</p>
        <Calendar
          onChange={(newDate) => {
            this.setState({ date: newDate });
            let da = newDate.getDate();
            let day = da;
            if (da == "1") {
              day = "01";
            } else if (da == "2") {
              day = "02";
            } else if (da == "3") {
              day = "03";
            } else if (da == "4") {
              day = "04";
            } else if (da == "5") {
              day = "05";
            } else if (da == "6") {
              day = "06";
            } else if (da == "7") {
              day = "07";
            } else if (da == "8") {
              day = "08";
            } else if (da == "9") {
              day = "09";
            } else {
              day = da;
            }
            let mont = months[newDate.getMonth()];
            let month = mont;
            if (mont == "1") {
              month = "01";
            } else if (mont == "2") {
              month = "02";
            } else if (mont == "3") {
              month = "03";
            } else if (mont == "4") {
              month = "04";
            } else if (mont == "5") {
              month = "05";
            } else if (mont == "6") {
              month = "06";
            } else if (mont == "7") {
              month = "07";
            } else if (mont == "8") {
              month = "08";
            } else if (mont == "9") {
              month = "09";
            } else {
              month = mont;
            }
            let year = newDate.getFullYear();
            let s = year + "-" + month + "-" + day;
            console.log(s);
            window.location.href = `?query=${s}`;

            // let dat =
            // console.log(newDate);

            // history.push(`/search?query=${s}`);
          }}
          value={new Date(this.state.date)}
          // to={`/search?query=${s}`}
          // onClickDay={() => {
          //   console.log(s);
          //   window.location.href = `?query=${this.state.date.getDate()}`;
          // }}
        />
        <p className="product-nav-header">Search by Category</p>
        <ul className="product-nav-list">
          <li>
            <NavLink
              exact
              to={`/search?query=Konzerte`}
              className="product-nav-item"
              activeClassName="product-nav-selected"
            >
              Konzerte
            </NavLink>
          </li>
          <NavLink
            // to={`/search?query=konzerte`}
            to={`/search?query=Kultur`}
            className="product-nav-item"
            activeClassName="product-nav-selected"
          >
            Kultur
          </NavLink>
          <NavLink
            exact
            to={`/search?query=Sport`}
            className="product-nav-item"
            activeClassName="product-nav-selected"
          >
            Sport
          </NavLink>
          {/* <NavLink
            exact
            to={`/search?query=Musical`}
            className="product-nav-item"
            activeClassName="product-nav-selected"
          >
            Musical
          </NavLink> */}
          <NavLink
            exact
            to={`/search?query=Humor`}
            className="product-nav-item"
            activeClassName="product-nav-selected"
          >
            Humor
          </NavLink>
          <NavLink
            exact
            to={`/search?query=Freizeit`}
            className="product-nav-item"
            activeClassName="product-nav-selected"
          >
            Freizeit
          </NavLink>
          {/* <NavLink
            exact
            to={`/search?query=Musical`}
            className="product-nav-item"
            activeClassName="product-nav-selected"
          >
            Musical
          </NavLink> */}
        </ul>
      </div>
    );
  }
}

export default ProductNav;

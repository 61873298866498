import { search } from '../util/search_api_util';
import { tagSearchResult } from '../util/search_api_util';

export const RECEIVE_SEARCH_RESULTS = 'RECEIVE_SEARCH_RESULTS';
export const RECEIVE_TAG_SEARCH_RESULTS = 'RECEIVE_TAG_SEARCH_RESULTS';

export const receiveSearchResults = (searchResults) => ({
  type: RECEIVE_SEARCH_RESULTS,
  searchResults,
});
export const recieveTagSearchResults = (tagSearchResults) => ({
  type: RECEIVE_TAG_SEARCH_RESULTS,
  tagSearchResults,
});

export const omniSearch = (query) => dispatch => (
  search(query).then(data => {
    dispatch(receiveSearchResults(data));
  })
);

export const tagSearch = (query) => dispatch => (
  tagSearchResult(query).then(data => {
    // console.log("xxxxxxxxxxxx");
    // console.log(data);
    dispatch(recieveTagSearchResults(data));
  })
);

import React from "react";
import "./AboutUs.scss";
import teamImg from './photos/team_img.jpg'

const AboutUs = () => {
  return (
    <div className="app-content">
      <div>
        <div className="about-container">
          <h1 className="about-heading">About Bonzai</h1>
          <p className="about-text">
            Bonzai is a centralised platform that provides unlimited access to
            your preferred entertainment for free with one subscription. Our
            mission is to make access to entertainment easy for everyone by
            offering recommendations, free tickets, and other benefits depending
            on the selected subscription model. With Bonzai, you have the
            freedom to choose your preferences, and we provide you with a
            hassle-free way of accessing all types of entertainment happening
            around you.
          </p>
          <h2 className="about-subheading">Our Story</h2>
          <p className="about-text">
            Bonzai was founded in 2023 by a group of passionate entertainment
            enthusiasts who recognized the need for a simpler, more convenient
            way to access the entertainment you love. With the explosion of
            streaming services and the abundance of events and activities
            available, we saw an opportunity to bring everything together in one
            place and make it accessible to everyone. Our team is dedicated to
            providing you with the best possible experience, and we're
            constantly working to improve and expand our offerings.
          </p>
          <h2 className="about-subheading">Our Team</h2>
          <p className="about-text">
            At Bonzai, we're a diverse team of entertainment enthusiasts, tech
            experts, and business professionals who are united by our passion
            for bringing people closer to the entertainment they love. We're
            based in Hamburg, but our team is made up of individuals from
            all over the world, each bringing their unique perspectives and
            experiences to the table.
          </p>
          <div className="about-team">
            <div className="about-team-img">
              <img
                src={teamImg}
                alt="Team member name"
                className="about-team-img"
              />
            </div>
            <ul>
              <li className="about-team-member">
                <h3 className="about-team-name">Dheeraj Kumar</h3>
                <p className="about-team-role">CEO & Founder</p>
              </li>
              <li className="about-team-member">
                <h3 className="about-team-name">Suket Suman</h3>
                <p className="about-team-role">Head of Technology</p>
              </li>
              <li className="about-team-member">
                <h3 className="about-team-name">Abhishek Gupta</h3>
                <p className="about-team-role">Head of Marketing</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;

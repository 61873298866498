import { connect } from "react-redux";
import { withRouter } from "react-router";
import SearchResultsIndex from "./search_results_index";
import { tagSearch } from "../../actions/search_actions";
import { fetchAllProducts } from "../../actions/product_actions";
import {
  fetchUpvotes,
  createUpvote,
  destroyUpvote,
} from "../../actions/upvote_actions";
import {
  fetchProduct,
  fetchProductImages,
} from "../../actions/product_actions";

const mapStateToProps = (state, ownProps) => {
  // let products = state.entities.searchResults.products.byId;
  // let productIds = state.entities.searchResults.products.allIds;
  // let users = state.entities.searchResults.users.byId;
  // let userIds = state.entities.searchResults.users.allIds;
  // let products = state.entities.products.byId
  // let productIds = state.entities.products.allIds
  // let productResults = state.entities.searchResults?.products || [];
  // const viewedProductId = ownProps.match.params.productId;
  const currentUser = state.session.currentUser;
  const token = state.session.currentUser?.token?.access;
  // console.log(state.entities.searchResults.products.productGroups.products);
  let products = state.entities.searchResults?.products?.productGroups;

  // console.log("xxxxxxxxxxxx");
  // console.log(productResults);
  // console.log(viewedProductId);
  // console.log("xxxxxxxxxxxx");

  return {
    // productResults,
    products,
    currentUser,
    token,

    // products,
    // productIds,
    // users,
    // userIds
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    tagSearch: (r) => {
      dispatch(tagSearch(r));
    },
    fetchProduct: (productId) => dispatch(fetchProduct(productId)),
    // fetchAllProducts: () => dispatch(fetchAllProducts()),
    createUpvote: (upvoteableType, upvoteableId, email) =>
      dispatch(createUpvote(upvoteableType, upvoteableId, email)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SearchResultsIndex)
);

import { connect } from "react-redux";
import { withRouter } from "react-router";
import Subscribe from "./SubscribeBasic";

const mapStateToProps = (state) => {
  let currentUser = null;
  // console.log(state.session.currentUser);
  if (state.session.currentUser) {
    currentUser = state.session.currentUser;
  }
  return {
    currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Subscribe)
);

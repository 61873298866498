import React from "react";
import ReactModal from "react-modal";
import SessionForm from "../session_form/session_form";
import { ReactPhotoCollage } from "react-photo-collage";
import ImageScroller from "react-image-scroller";
import { Gallery } from "react-grid-gallery";
import { GoogleLogin } from "@react-oauth/google";
import { GoogleAuthProvider } from "@firebase/auth";
import { Link } from "react-router-dom";
const provider = new GoogleAuthProvider();

class HeroBanner extends React.Component {
  constructor(props) {
    super(props);
    // console.log(props);

    this.state = {
      modalIsOpen: false,
      modalFormType: "login",
    };

    this.openModal = this.openModal.bind(this);
    this.afterModalOpen = this.afterModalOpen.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal(formType) {
    return () => {
      this.setState({
        modalFormType: formType,
        modalIsOpen: true,
      });
    };
  }

  afterModalOpen() {
    ReactModal.defaultStyles.overlay.backgroundColor = "rgba(128,128,128,0.75)";
    ReactModal.defaultStyles.content.display = "flex";
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  handleClick(formType) {
    return () => {
      this.openModal(formType)();
    };
  }

  render() {
    const collageInfo = [
      {
        "@context": "http://schema.org",
        "@type": "MusicEvent",
        description: "Frittenbude at Die Pumpe at 2023-03-16",
        endDate: "2023-03-16",
        eventStatus: "https://schema.org/EventScheduled",
        image:
          "https://images.sk-static.com/images/media/profile_images/artists/928215/huge_avatar",
        location: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressCountry: "Germany",
            addressLocality: "Kiel",
            postalCode: "24103",
            streetAddress: "Haßstr. 22",
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: 54.32458,
            longitude: 10.13807,
          },
          name: "Die Pumpe",
          sameAs: "https://www.diepumpe.de/",
        },
        name: "Frittenbude",
        performer: [
          {
            "@type": "MusicGroup",
            name: "Frittenbude",
          },
        ],
        startDate: "2023-03-16",
      },
      {
        "@context": "http://schema.org",
        "@type": "MusicEvent",
        description: "Milliarden at Die Pumpe at 2023-03-17",
        endDate: "2023-03-17",
        eventStatus: "https://schema.org/EventScheduled",
        image:
          "https://images.sk-static.com/images/media/profile_images/artists/8785339/huge_avatar",
        location: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressCountry: "Germany",
            addressLocality: "Kiel",
            postalCode: "24103",
            streetAddress: "Haßstr. 22",
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: 54.32458,
            longitude: 10.13807,
          },
          name: "Die Pumpe",
          sameAs: "https://www.diepumpe.de/",
        },
        name: "Milliarden",
        performer: [
          {
            "@type": "MusicGroup",
            name: "Milliarden",
          },
        ],
        startDate: "2023-03-17",
      },
      {
        "@context": "http://schema.org",
        "@type": "MusicEvent",
        description: "Stahlzeit at WUNDERINO ARENA at 2023-03-17",
        endDate: "2023-03-17",
        eventStatus: "https://schema.org/EventScheduled",
        image:
          "https://images.sk-static.com/images/media/profile_images/artists/2469931/huge_avatar",
        location: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressCountry: "Germany",
            addressLocality: "Kiel",
            postalCode: "24103",
            streetAddress: "Europaplatz 1",
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: 54.32092,
            longitude: 10.13151,
          },
          name: "WUNDERINO ARENA",
          sameAs: "https://www.wunderino-arena.de",
        },
        name: "Stahlzeit",
        performer: [
          {
            "@type": "MusicGroup",
            name: "Stahlzeit",
          },
        ],
        startDate: "2023-03-17",
      },
      {
        "@context": "http://schema.org",
        "@type": "MusicEvent",
        description: "Luciano at WUNDERINO ARENA at 2023-03-18",
        endDate: "2023-03-18",
        eventStatus: "https://schema.org/EventScheduled",
        image:
          "https://images.sk-static.com/images/media/profile_images/artists/41370/huge_avatar",
        location: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressCountry: "Germany",
            addressLocality: "Kiel",
            postalCode: "24103",
            streetAddress: "Europaplatz 1",
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: 54.32092,
            longitude: 10.13151,
          },
          name: "WUNDERINO ARENA",
          sameAs: "https://www.wunderino-arena.de",
        },
        name: "Luciano",
        performer: [
          {
            "@type": "MusicGroup",
            name: "Luciano",
          },
        ],
        startDate: "2023-03-18",
      },
      {
        "@context": "http://schema.org",
        "@type": "MusicEvent",
        description:
          "Black Moon Circle at Kieler Schaubude at 2023-03-18T09:00:00+0100",
        endDate: "2023-03-18",
        eventStatus: "https://schema.org/EventScheduled",
        image:
          "https://images.sk-static.com/images/media/profile_images/artists/7645044/huge_avatar",
        location: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressCountry: "Germany",
            addressLocality: "Kiel",
            postalCode: "24103",
            streetAddress: "Legienstr. 40",
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: 54.32784,
            longitude: 10.13041,
          },
          name: "Kieler Schaubude",
          sameAs: "http://www.kieler-schaubude.de",
        },
        name: "Black Moon Circle",
        performer: [
          {
            "@type": "MusicGroup",
            name: "Black Moon Circle",
          },
        ],
        startDate: "2023-03-18T09:00:00+0100",
      },
      {
        "@context": "http://schema.org",
        "@type": "MusicEvent",
        description: "WellBad at Räucherei at 2023-03-18",
        endDate: "2023-03-18",
        eventStatus: "https://schema.org/EventScheduled",
        image:
          "https://images.sk-static.com/images/media/profile_images/artists/8495483/huge_avatar",
        location: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressCountry: "Germany",
            addressLocality: "Kiel",
            postalCode: "24143",
            streetAddress: "Preetzer Strasse 35",
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: 54.30718,
            longitude: 10.14478,
          },
          name: "Räucherei",
          sameAs: null,
        },
        name: "WellBad",
        performer: [
          {
            "@type": "MusicGroup",
            name: "WellBad",
          },
        ],
        startDate: "2023-03-18",
      },
      {
        "@context": "http://schema.org",
        "@type": "MusicEvent",
        description:
          "Ein Fall für die drei Herren at derHeimathafen at 2023-03-18T19:00:00+0100",
        endDate: "2023-03-18",
        eventStatus: "https://schema.org/EventScheduled",
        image:
          "https://images.sk-static.com/images/media/profile_images/artists/10173422/huge_avatar",
        location: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressCountry: "Germany",
            addressLocality: "Kiel",
            postalCode: "24103",
            streetAddress: "Holstenstraße 2-12",
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: 54.32274,
            longitude: 10.13861,
          },
          name: "derHeimathafen",
          sameAs: "https://derheimathafen.net/",
        },
        name: "Ein Fall für die drei Herren",
        performer: [
          {
            "@type": "MusicGroup",
            name: "Ein Fall für die drei Herren",
          },
        ],
        startDate: "2023-03-18T19:00:00+0100",
      },
      {
        "@context": "http://schema.org",
        "@type": "MusicEvent",
        description: "KEI CAR at Prinz Willy at 2023-03-18T19:00:00+0100",
        endDate: "2023-03-18",
        eventStatus: "https://schema.org/EventScheduled",
        image:
          "https://images.sk-static.com/images/media/profile_images/artists/10185601/huge_avatar",
        location: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressCountry: "Germany",
            addressLocality: "Kiel",
            postalCode: "24114",
            streetAddress: "Lutherstrasse 9",
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: 54.31523,
            longitude: 10.11698,
          },
          name: "Prinz Willy",
          sameAs: null,
        },
        name: "KEI CAR",
        performer: [
          {
            "@type": "MusicGroup",
            name: "KEI CAR",
          },
        ],
        startDate: "2023-03-18T19:00:00+0100",
      },
      {
        "@context": "http://schema.org",
        "@type": "MusicEvent",
        description: "Thats Life at Stadttheater at 2023-03-19",
        endDate: "2023-03-19",
        eventStatus: "https://schema.org/EventScheduled",
        image:
          "https://images.sk-static.com/images/media/profile_images/artists/4097711/huge_avatar",
        location: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressCountry: "Germany",
            addressLocality: "Heide",
            postalCode: "25746",
            streetAddress: "Rosenstraße 17",
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: 54.19704,
            longitude: 9.08983,
          },
          name: "Stadttheater",
          sameAs: null,
        },
        name: "Thats Life",
        performer: [
          {
            "@type": "MusicGroup",
            name: "Thats Life",
          },
        ],
        startDate: "2023-03-19",
      },
      {
        "@context": "http://schema.org",
        "@type": "MusicEvent",
        description:
          "Alexa Feser at Max Nachttheater at 2023-03-21T20:00:00+0100",
        endDate: "2023-03-21",
        eventStatus: "https://schema.org/EventScheduled",
        image:
          "https://images.sk-static.com/images/media/profile_images/artists/7402134/huge_avatar",
        location: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressCountry: "Germany",
            addressLocality: "Kiel",
            postalCode: "24116",
            streetAddress: "Eichhofstraße 1",
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: 54.33272,
            longitude: 10.11172,
          },
          name: "Max Nachttheater",
          sameAs: null,
        },
        name: "Alexa Feser",
        performer: [
          {
            "@type": "MusicGroup",
            name: "Alexa Feser",
          },
        ],
        startDate: "2023-03-21T20:00:00+0100",
      },
      {
        "@context": "http://schema.org",
        "@type": "MusicEvent",
        description:
          "Ein Fall für die drei Herren at KulturForum Neues Rathaus at 2023-03-22T20:00:00+0100",
        endDate: "2023-03-22",
        eventStatus: "https://schema.org/EventScheduled",
        image:
          "https://images.sk-static.com/images/media/profile_images/artists/10173422/huge_avatar",
        location: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressCountry: "Germany",
            addressLocality: "Kiel",
            postalCode: "24103",
            streetAddress: "Andreas-Gayk-Straße 31",
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: 54.31899,
            longitude: 10.13407,
          },
          name: "KulturForum Neues Rathaus",
          sameAs: null,
        },
        name: "Ein Fall für die drei Herren",
        performer: [
          {
            "@type": "MusicGroup",
            name: "Ein Fall für die drei Herren",
          },
        ],
        startDate: "2023-03-22T20:00:00+0100",
      },
      {
        "@context": "http://schema.org",
        "@type": "MusicEvent",
        description: "Spidergawd at Die Pumpe at 2023-03-23",
        endDate: "2023-03-23",
        eventStatus: "https://schema.org/EventScheduled",
        image:
          "https://images.sk-static.com/images/media/profile_images/artists/2686701/huge_avatar",
        location: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressCountry: "Germany",
            addressLocality: "Kiel",
            postalCode: "24103",
            streetAddress: "Haßstr. 22",
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: 54.32458,
            longitude: 10.13807,
          },
          name: "Die Pumpe",
          sameAs: "https://www.diepumpe.de/",
        },
        name: "Spidergawd",
        performer: [
          {
            "@type": "MusicGroup",
            name: "Spidergawd",
          },
          {
            "@type": "MusicGroup",
            name: "Draken",
          },
        ],
        startDate: "2023-03-23",
      },
      {
        "@context": "http://schema.org",
        "@type": "MusicEvent",
        description:
          "Urbannino, Miese Mau, NILS KEPPEL, and lauenburg at Die Pumpe at 2023-03-24",
        endDate: "2023-03-24",
        eventStatus: "https://schema.org/EventScheduled",
        image:
          "https://images.sk-static.com/images/media/profile_images/artists/10210508/huge_avatar",
        location: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressCountry: "Germany",
            addressLocality: "Kiel",
            postalCode: "24103",
            streetAddress: "Haßstr. 22",
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: 54.32458,
            longitude: 10.13807,
          },
          name: "Die Pumpe",
          sameAs: "https://www.diepumpe.de/",
        },
        name: "Urbannino",
        performer: [
          {
            "@type": "MusicGroup",
            name: "Urbannino",
          },
          {
            "@type": "MusicGroup",
            name: "Miese Mau",
          },
          {
            "@type": "MusicGroup",
            name: "NILS KEPPEL",
          },
          {
            "@type": "MusicGroup",
            name: "lauenburg",
          },
        ],
        startDate: "2023-03-24",
      },
      {
        "@context": "http://schema.org",
        "@type": "MusicEvent",
        description: "Jazznight at Kornboden at 2023-03-25",
        endDate: "2023-03-25",
        eventStatus: "https://schema.org/EventScheduled",
        image:
          "https://images.sk-static.com/images/media/profile_images/artists/6910029/huge_avatar",
        location: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressCountry: "Germany",
            addressLocality: "Schönberg",
            postalCode: "22929",
            streetAddress: "Pöhlen 27",
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: 53.68231,
            longitude: 10.42704,
          },
          name: "Kornboden",
          sameAs: null,
        },
        name: "Jazznight",
        performer: [
          {
            "@type": "MusicGroup",
            name: "Jazznight",
          },
        ],
        startDate: "2023-03-25",
      },
      {
        "@context": "http://schema.org",
        "@type": "MusicEvent",
        description: "Gankino Circus at Schleswig at 2023-03-30",
        endDate: "2023-03-30",
        eventStatus: "https://schema.org/EventScheduled",
        image:
          "https://images.sk-static.com/images/media/profile_images/artists/1200183/huge_avatar",
        location: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressCountry: "Germany",
            addressLocality: "Schleswig",
          },
          name: "Schleswig",
        },
        name: "Gankino Circus",
        performer: [
          {
            "@type": "MusicGroup",
            name: "Gankino Circus",
          },
        ],
        startDate: "2023-03-30",
      },
      {
        "@context": "http://schema.org",
        "@type": "MusicEvent",
        description: "Miss Allie at Max Nachttheater at 2023-03-31",
        endDate: "2023-03-31",
        eventStatus: "https://schema.org/EventScheduled",
        image:
          "https://images.sk-static.com/images/media/profile_images/artists/9575414/huge_avatar",
        location: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressCountry: "Germany",
            addressLocality: "Kiel",
            postalCode: "24116",
            streetAddress: "Eichhofstraße 1",
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: 54.33272,
            longitude: 10.11172,
          },
          name: "Max Nachttheater",
          sameAs: null,
        },
        name: "Miss Allie",
        performer: [
          {
            "@type": "MusicGroup",
            name: "Miss Allie",
          },
        ],
        startDate: "2023-03-31",
      },
      {
        "@context": "http://schema.org",
        "@type": "MusicEvent",
        description: "Harderland Vol.2 2023 at Discothek Vineta at 2023-03-31",
        endDate: "2023-03-31",
        eventStatus: "https://schema.org/EventScheduled",
        image:
          "https://images.sk-static.com/images/media/profile_images/events/40902109/huge_avatar",
        location: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressCountry: "Germany",
            addressLocality: "Schleswig",
            postalCode: "24866",
            streetAddress: "Thorshammer 10",
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: 54.48039,
            longitude: 9.54499,
          },
          name: "Discothek Vineta",
          sameAs: "http://www.disco-vineta.de",
        },
        name: "Harderland Vol.2 2023",
        performer: [
          {
            "@type": "MusicGroup",
            name: "Level One",
          },
          {
            "@type": "MusicGroup",
            name: "Nightcraft",
          },
        ],
        startDate: "2023-03-31",
      },
      {
        "@context": "http://schema.org",
        "@type": "MusicEvent",
        description: "Queen Machine at Max Nachttheater at 2023-04-01",
        endDate: "2023-04-01",
        eventStatus: "https://schema.org/EventScheduled",
        image:
          "https://images.sk-static.com/images/media/profile_images/artists/82746/huge_avatar",
        location: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressCountry: "Germany",
            addressLocality: "Kiel",
            postalCode: "24116",
            streetAddress: "Eichhofstraße 1",
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: 54.33272,
            longitude: 10.11172,
          },
          name: "Max Nachttheater",
          sameAs: null,
        },
        name: "Queen Machine",
        performer: [
          {
            "@type": "MusicGroup",
            name: "Queen Machine",
          },
        ],
        startDate: "2023-04-01",
      },
      {
        "@context": "http://schema.org",
        "@type": "MusicEvent",
        description: "AntiHeld (DE) at Räucherei at 2023-04-01",
        endDate: "2023-04-01",
        eventStatus: "https://schema.org/EventScheduled",
        image:
          "https://images.sk-static.com/images/media/profile_images/artists/9215034/huge_avatar",
        location: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressCountry: "Germany",
            addressLocality: "Kiel",
            postalCode: "24143",
            streetAddress: "Preetzer Strasse 35",
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: 54.30718,
            longitude: 10.14478,
          },
          name: "Räucherei",
          sameAs: null,
        },
        name: "AntiHeld (DE)",
        performer: [
          {
            "@type": "MusicGroup",
            name: "AntiHeld (DE)",
          },
        ],
        startDate: "2023-04-01",
      },
      {
        "@context": "http://schema.org",
        "@type": "MusicEvent",
        description:
          "Ein Fall für die drei Herren at KulturForum Neues Rathaus at 2023-04-01T20:00:00+0200",
        endDate: "2023-04-01",
        eventStatus: "https://schema.org/EventScheduled",
        image:
          "https://images.sk-static.com/images/media/profile_images/artists/10173422/huge_avatar",
        location: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressCountry: "Germany",
            addressLocality: "Kiel",
            postalCode: "24103",
            streetAddress: "Andreas-Gayk-Straße 31",
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: 54.31899,
            longitude: 10.13407,
          },
          name: "KulturForum Neues Rathaus",
          sameAs: null,
        },
        name: "Ein Fall für die drei Herren",
        performer: [
          {
            "@type": "MusicGroup",
            name: "Ein Fall für die drei Herren",
          },
        ],
        startDate: "2023-04-01T20:00:00+0200",
      },
      {
        "@context": "http://schema.org",
        "@type": "MusicEvent",
        description:
          "Bodo Wartke and Melanie Haupt at Lutterbeker at 2023-04-03T20:00:00+0200",
        endDate: "2023-04-03",
        eventStatus: "https://schema.org/EventScheduled",
        image:
          "https://images.sk-static.com/images/media/profile_images/artists/422061/huge_avatar",
        location: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressCountry: "Germany",
            addressLocality: "Lutterbek",
            postalCode: "24235",
            streetAddress: "Dorfstraße 11",
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: 54.39134,
            longitude: 10.27636,
          },
          name: "Lutterbeker",
          sameAs: "http://www.lutterbeker.de/",
        },
        name: "Bodo Wartke",
        performer: [
          {
            "@type": "MusicGroup",
            name: "Bodo Wartke",
          },
          {
            "@type": "MusicGroup",
            name: "Melanie Haupt",
          },
        ],
        startDate: "2023-04-03T20:00:00+0200",
      },
      {
        "@context": "http://schema.org",
        "@type": "MusicEvent",
        description:
          "Törzs and Syberia at FahrradKinoKombinat at 2023-04-03T18:00:00+0200",
        endDate: "2023-04-03",
        eventStatus: "https://schema.org/EventScheduled",
        image:
          "https://images.sk-static.com/images/media/profile_images/artists/8894369/huge_avatar",
        location: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressCountry: "Germany",
            addressLocality: "Kiel",
            postalCode: null,
            streetAddress: "Lorentzendamm 6-8",
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: 54.3273,
            longitude: 10.14136,
          },
          name: "FahrradKinoKombinat",
          sameAs: null,
        },
        name: "Törzs",
        performer: [
          {
            "@type": "MusicGroup",
            name: "Törzs",
          },
          {
            "@type": "MusicGroup",
            name: "Syberia",
          },
        ],
        startDate: "2023-04-03T18:00:00+0200",
      },
      {
        "@context": "http://schema.org",
        "@type": "MusicEvent",
        description: "Die Prinzen at WUNDERINO ARENA at 2023-04-04",
        endDate: "2023-04-04",
        eventStatus: "https://schema.org/EventScheduled",
        image:
          "https://images.sk-static.com/images/media/profile_images/artists/543757/huge_avatar",
        location: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressCountry: "Germany",
            addressLocality: "Kiel",
            postalCode: "24103",
            streetAddress: "Europaplatz 1",
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: 54.32092,
            longitude: 10.13151,
          },
          name: "WUNDERINO ARENA",
          sameAs: "https://www.wunderino-arena.de",
        },
        name: "Die Prinzen",
        performer: [
          {
            "@type": "MusicGroup",
            name: "Die Prinzen",
          },
        ],
        startDate: "2023-04-04",
      },
      {
        "@context": "http://schema.org",
        "@type": "MusicEvent",
        description:
          "Bodo Wartke and Melanie Haupt at Lutterbeker at 2023-04-04T20:00:00+0200",
        endDate: "2023-04-04",
        eventStatus: "https://schema.org/EventScheduled",
        image:
          "https://images.sk-static.com/images/media/profile_images/artists/422061/huge_avatar",
        location: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressCountry: "Germany",
            addressLocality: "Lutterbek",
            postalCode: "24235",
            streetAddress: "Dorfstraße 11",
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: 54.39134,
            longitude: 10.27636,
          },
          name: "Lutterbeker",
          sameAs: "http://www.lutterbeker.de/",
        },
        name: "Bodo Wartke",
        performer: [
          {
            "@type": "MusicGroup",
            name: "Bodo Wartke",
          },
          {
            "@type": "MusicGroup",
            name: "Melanie Haupt",
          },
        ],
        startDate: "2023-04-04T20:00:00+0200",
      },
      {
        "@context": "http://schema.org",
        "@type": "MusicEvent",
        description: "Acht Eimer Hühnerherzen at Die Pumpe at 2023-04-05",
        endDate: "2023-04-05",
        eventStatus: "https://schema.org/EventScheduled",
        image:
          "https://images.sk-static.com/images/media/profile_images/artists/9836214/huge_avatar",
        location: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressCountry: "Germany",
            addressLocality: "Kiel",
            postalCode: "24103",
            streetAddress: "Haßstr. 22",
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: 54.32458,
            longitude: 10.13807,
          },
          name: "Die Pumpe",
          sameAs: "https://www.diepumpe.de/",
        },
        name: "Acht Eimer Hühnerherzen",
        performer: [
          {
            "@type": "MusicGroup",
            name: "Acht Eimer Hühnerherzen",
          },
        ],
        startDate: "2023-04-05",
      },
      {
        "@context": "http://schema.org",
        "@type": "MusicEvent",
        description:
          "Plattenbau at Kieler Schaubude at 2023-04-06T20:00:00+0200",
        endDate: "2023-04-06",
        eventStatus: "https://schema.org/EventScheduled",
        image:
          "https://images.sk-static.com/images/media/profile_images/artists/8342533/huge_avatar",
        location: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressCountry: "Germany",
            addressLocality: "Kiel",
            postalCode: "24103",
            streetAddress: "Legienstr. 40",
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: 54.32784,
            longitude: 10.13041,
          },
          name: "Kieler Schaubude",
          sameAs: "http://www.kieler-schaubude.de",
        },
        name: "Plattenbau",
        performer: [
          {
            "@type": "MusicGroup",
            name: "Plattenbau",
          },
        ],
        startDate: "2023-04-06T20:00:00+0200",
      },
      {
        "@context": "http://schema.org",
        "@type": "MusicEvent",
        description: "Lygo at Die Pumpe at 2023-04-06",
        endDate: "2023-04-06",
        eventStatus: "https://schema.org/EventScheduled",
        image:
          "https://images.sk-static.com/images/media/profile_images/artists/5261078/huge_avatar",
        location: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressCountry: "Germany",
            addressLocality: "Kiel",
            postalCode: "24103",
            streetAddress: "Haßstr. 22",
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: 54.32458,
            longitude: 10.13807,
          },
          name: "Die Pumpe",
          sameAs: "https://www.diepumpe.de/",
        },
        name: "Lygo",
        performer: [
          {
            "@type": "MusicGroup",
            name: "Lygo",
          },
        ],
        startDate: "2023-04-06",
      },
      {
        "@context": "http://schema.org",
        "@type": "MusicEvent",
        description: "Phil Bates at Stadttheater at 2023-04-07",
        endDate: "2023-04-07",
        eventStatus: "https://schema.org/EventScheduled",
        image:
          "https://images.sk-static.com/images/media/profile_images/artists/64608/huge_avatar",
        location: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressCountry: "Germany",
            addressLocality: "Heide",
            postalCode: "25746",
            streetAddress: "Rosenstraße 17",
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: 54.19704,
            longitude: 9.08983,
          },
          name: "Stadttheater",
          sameAs: null,
        },
        name: "Phil Bates",
        performer: [
          {
            "@type": "MusicGroup",
            name: "Phil Bates",
          },
        ],
        startDate: "2023-04-07",
      },
      {
        "@context": "http://schema.org",
        "@type": "MusicEvent",
        description: "The Sickest Squad at Kiel at 2023-04-09T23:00:00+0200",
        endDate: "2023-04-09",
        eventStatus: "https://schema.org/EventScheduled",
        image:
          "https://images.sk-static.com/images/media/profile_images/artists/2821821/huge_avatar",
        location: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressCountry: "Germany",
            addressLocality: "Kiel",
          },
          name: "Kiel",
        },
        name: "The Sickest Squad",
        performer: [
          {
            "@type": "MusicGroup",
            name: "The Sickest Squad",
          },
        ],
        startDate: "2023-04-09T23:00:00+0200",
      },
      {
        "@context": "http://schema.org",
        "@type": "MusicEvent",
        description: "Schmyt at Max Nachttheater at 2023-04-12",
        endDate: "2023-04-12",
        eventStatus: "https://schema.org/EventScheduled",
        image:
          "https://images.sk-static.com/images/media/profile_images/artists/10165039/huge_avatar",
        location: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressCountry: "Germany",
            addressLocality: "Kiel",
            postalCode: "24116",
            streetAddress: "Eichhofstraße 1",
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: 54.33272,
            longitude: 10.11172,
          },
          name: "Max Nachttheater",
          sameAs: null,
        },
        name: "Schmyt",
        performer: [
          {
            "@type": "MusicGroup",
            name: "Schmyt",
          },
        ],
        startDate: "2023-04-12",
      },
      {
        "@context": "http://schema.org",
        "@type": "MusicEvent",
        description: "Reis against the Spülmachine at Die Pumpe at 2023-04-13",
        endDate: "2023-04-13",
        eventStatus: "https://schema.org/EventScheduled",
        image:
          "https://images.sk-static.com/images/media/profile_images/artists/9441084/huge_avatar",
        location: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressCountry: "Germany",
            addressLocality: "Kiel",
            postalCode: "24103",
            streetAddress: "Haßstr. 22",
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: 54.32458,
            longitude: 10.13807,
          },
          name: "Die Pumpe",
          sameAs: "https://www.diepumpe.de/",
        },
        name: "Reis against the Spülmachine",
        performer: [
          {
            "@type": "MusicGroup",
            name: "Reis against the Spülmachine",
          },
        ],
        startDate: "2023-04-13",
      },
      {
        "@context": "http://schema.org",
        "@type": "MusicEvent",
        description:
          "Jeff Cascaro at KulturForum Neues Rathaus at 2023-04-14T20:00:00+0200",
        endDate: "2023-04-14",
        eventStatus: "https://schema.org/EventScheduled",
        image:
          "https://images.sk-static.com/images/media/profile_images/artists/489291/huge_avatar",
        location: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressCountry: "Germany",
            addressLocality: "Kiel",
            postalCode: "24103",
            streetAddress: "Andreas-Gayk-Straße 31",
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: 54.31899,
            longitude: 10.13407,
          },
          name: "KulturForum Neues Rathaus",
          sameAs: null,
        },
        name: "Jeff Cascaro",
        performer: [
          {
            "@type": "MusicGroup",
            name: "Jeff Cascaro",
          },
        ],
        startDate: "2023-04-14T20:00:00+0200",
      },
      {
        "@context": "http://schema.org",
        "@type": "MusicEvent",
        description: "Matthias Reim at WUNDERINO ARENA at 2023-04-14",
        endDate: "2023-04-14",
        eventStatus: "https://schema.org/EventScheduled",
        image:
          "https://images.sk-static.com/images/media/profile_images/artists/364743/huge_avatar",
        location: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressCountry: "Germany",
            addressLocality: "Kiel",
            postalCode: "24103",
            streetAddress: "Europaplatz 1",
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: 54.32092,
            longitude: 10.13151,
          },
          name: "WUNDERINO ARENA",
          sameAs: "https://www.wunderino-arena.de",
        },
        name: "Matthias Reim",
        performer: [
          {
            "@type": "MusicGroup",
            name: "Matthias Reim",
          },
        ],
        startDate: "2023-04-14",
      },
      {
        "@context": "http://schema.org",
        "@type": "MusicEvent",
        description: "HARDPLAY 2023 at Discothek Ela-Ela at 2023-04-14",
        endDate: "2023-04-14",
        eventStatus: "https://schema.org/EventScheduled",
        image:
          "https://images.sk-static.com/images/media/profile_images/events/40912400/huge_avatar",
        location: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressCountry: "Germany",
            addressLocality: "Schleswig",
            postalCode: null,
            streetAddress: null,
          },
          name: "Discothek Ela-Ela",
          sameAs: null,
        },
        name: "HARDPLAY 2023",
        performer: [
          {
            "@type": "MusicGroup",
            name: "Mish",
          },
        ],
        startDate: "2023-04-14",
      },
      {
        "@context": "http://schema.org",
        "@type": "MusicEvent",
        description: "Muff Potter at Die Pumpe at 2023-04-15T19:00:00+0200",
        endDate: "2023-04-15",
        eventStatus: "https://schema.org/EventScheduled",
        image:
          "https://images.sk-static.com/images/media/profile_images/artists/478704/huge_avatar",
        location: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressCountry: "Germany",
            addressLocality: "Kiel",
            postalCode: "24103",
            streetAddress: "Haßstr. 22",
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: 54.32458,
            longitude: 10.13807,
          },
          name: "Die Pumpe",
          sameAs: "https://www.diepumpe.de/",
        },
        name: "Muff Potter",
        performer: [
          {
            "@type": "MusicGroup",
            name: "Muff Potter",
          },
        ],
        startDate: "2023-04-15T19:00:00+0200",
      },
      {
        "@context": "http://schema.org",
        "@type": "MusicEvent",
        description: "Dunya at Hansa 48 at 2023-04-15T20:00:00+0200",
        endDate: "2023-04-15",
        eventStatus: "https://schema.org/EventScheduled",
        image:
          "https://images.sk-static.com/images/media/profile_images/artists/2938231/huge_avatar",
        location: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressCountry: "Germany",
            addressLocality: "Kiel",
            postalCode: "24118",
            streetAddress: "Hansastraße 48",
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: 54.33526,
            longitude: 10.1266,
          },
          name: "Hansa 48",
          sameAs: "http://www.hansa48.de",
        },
        name: "Dunya",
        performer: [
          {
            "@type": "MusicGroup",
            name: "Dunya",
          },
        ],
        startDate: "2023-04-15T20:00:00+0200",
      },
      {
        "@context": "http://schema.org",
        "@type": "MusicEvent",
        description: "OvO and Body Void at Kiel at 2023-04-16",
        endDate: "2023-04-16",
        eventStatus: "https://schema.org/EventScheduled",
        image:
          "https://images.sk-static.com/images/media/profile_images/artists/150025/huge_avatar",
        location: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressCountry: "Germany",
            addressLocality: "Kiel",
          },
          name: "Kiel",
        },
        name: "OvO",
        performer: [
          {
            "@type": "MusicGroup",
            name: "OvO",
          },
          {
            "@type": "MusicGroup",
            name: "Body Void",
          },
        ],
        startDate: "2023-04-16",
      },
      {
        "@context": "http://schema.org",
        "@type": "MusicEvent",
        description: "Johannes Oerding at WUNDERINO ARENA at 2023-04-18",
        endDate: "2023-04-18",
        eventStatus: "https://schema.org/EventScheduled",
        image:
          "https://images.sk-static.com/images/media/profile_images/artists/1971655/huge_avatar",
        location: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressCountry: "Germany",
            addressLocality: "Kiel",
            postalCode: "24103",
            streetAddress: "Europaplatz 1",
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: 54.32092,
            longitude: 10.13151,
          },
          name: "WUNDERINO ARENA",
          sameAs: "https://www.wunderino-arena.de",
        },
        name: "Johannes Oerding",
        performer: [
          {
            "@type": "MusicGroup",
            name: "Johannes Oerding",
          },
        ],
        startDate: "2023-04-18",
      },
      {
        "@context": "http://schema.org",
        "@type": "MusicEvent",
        description: "Fantasy at WUNDERINO ARENA at 2023-04-20",
        endDate: "2023-04-20",
        eventStatus: "https://schema.org/EventScheduled",
        image:
          "https://images.sk-static.com/images/media/profile_images/artists/551669/huge_avatar",
        location: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressCountry: "Germany",
            addressLocality: "Kiel",
            postalCode: "24103",
            streetAddress: "Europaplatz 1",
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: 54.32092,
            longitude: 10.13151,
          },
          name: "WUNDERINO ARENA",
          sameAs: "https://www.wunderino-arena.de",
        },
        name: "Fantasy",
        performer: [
          {
            "@type": "MusicGroup",
            name: "Fantasy",
          },
        ],
        startDate: "2023-04-20",
      },
      {
        "@context": "http://schema.org",
        "@type": "MusicEvent",
        description:
          "Kara Delik at FahrradKinoKombinat at 2023-04-20T20:00:00+0200",
        endDate: "2023-04-20",
        eventStatus: "https://schema.org/EventScheduled",
        image:
          "https://images.sk-static.com/images/media/profile_images/artists/10204334/huge_avatar",
        location: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressCountry: "Germany",
            addressLocality: "Kiel",
            postalCode: null,
            streetAddress: "Lorentzendamm 6-8",
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: 54.3273,
            longitude: 10.14136,
          },
          name: "FahrradKinoKombinat",
          sameAs: null,
        },
        name: "Kara Delik",
        performer: [
          {
            "@type": "MusicGroup",
            name: "Kara Delik",
          },
        ],
        startDate: "2023-04-20T20:00:00+0200",
      },
      {
        "@context": "http://schema.org",
        "@type": "MusicEvent",
        description: "Dieter Bohlen at WUNDERINO ARENA at 2023-04-21",
        endDate: "2023-04-21",
        eventStatus: "https://schema.org/EventScheduled",
        image:
          "https://images.sk-static.com/images/media/profile_images/artists/551941/huge_avatar",
        location: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressCountry: "Germany",
            addressLocality: "Kiel",
            postalCode: "24103",
            streetAddress: "Europaplatz 1",
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: 54.32092,
            longitude: 10.13151,
          },
          name: "WUNDERINO ARENA",
          sameAs: "https://www.wunderino-arena.de",
        },
        name: "Dieter Bohlen",
        performer: [
          {
            "@type": "MusicGroup",
            name: "Dieter Bohlen",
          },
        ],
        startDate: "2023-04-21",
      },
      {
        "@context": "http://schema.org",
        "@type": "MusicEvent",
        description:
          "Martin Wind, Peter Weniger, and Jonas Burgwinkel at Kulturforum at 2023-04-21T20:00:00+0200",
        endDate: "2023-04-21",
        eventStatus: "https://schema.org/EventScheduled",
        image:
          "https://images.sk-static.com/images/media/profile_images/artists/450771/huge_avatar",
        location: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressCountry: "Germany",
            addressLocality: "Kiel",
            postalCode: null,
            streetAddress: null,
          },
          name: "Kulturforum",
          sameAs: null,
        },
        name: "Martin Wind",
        performer: [
          {
            "@type": "MusicGroup",
            name: "Martin Wind",
          },
          {
            "@type": "MusicGroup",
            name: "Peter Weniger",
          },
          {
            "@type": "MusicGroup",
            name: "Jonas Burgwinkel",
          },
        ],
        startDate: "2023-04-21T20:00:00+0200",
      },
      {
        "@context": "http://schema.org",
        "@type": "MusicEvent",
        description:
          "portrayal of guilt, Antichrist Siege Machine, and Spirit Possession at Kieler Schaubude at 2023-04-22",
        endDate: "2023-04-22",
        eventStatus: "https://schema.org/EventScheduled",
        image:
          "https://images.sk-static.com/images/media/profile_images/artists/9083019/huge_avatar",
        location: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressCountry: "Germany",
            addressLocality: "Kiel",
            postalCode: "24103",
            streetAddress: "Legienstr. 40",
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: 54.32784,
            longitude: 10.13041,
          },
          name: "Kieler Schaubude",
          sameAs: "http://www.kieler-schaubude.de",
        },
        name: "portrayal of guilt",
        performer: [
          {
            "@type": "MusicGroup",
            name: "portrayal of guilt",
          },
          {
            "@type": "MusicGroup",
            name: "Antichrist Siege Machine",
          },
          {
            "@type": "MusicGroup",
            name: "Spirit Possession",
          },
        ],
        startDate: "2023-04-22",
      },
      {
        "@context": "http://schema.org",
        "@type": "MusicEvent",
        description: "Kathy Kelly at Petruskirche at 2023-04-22",
        endDate: "2023-04-22",
        eventStatus: "https://schema.org/EventScheduled",
        image:
          "https://images.sk-static.com/images/media/profile_images/artists/453618/huge_avatar",
        location: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressCountry: "Germany",
            addressLocality: "Kiel",
            postalCode: "24106",
            streetAddress: "Arkonastraße 4",
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: 54.35952,
            longitude: 10.13617,
          },
          name: "Petruskirche",
          sameAs: null,
        },
        name: "Kathy Kelly",
        performer: [
          {
            "@type": "MusicGroup",
            name: "Kathy Kelly",
          },
          {
            "@type": "MusicGroup",
            name: "Jay Alexander",
          },
        ],
        startDate: "2023-04-22",
      },
      {
        "@context": "http://schema.org",
        "@type": "MusicEvent",
        description: "Dona Rosa at Lutterbeker at 2023-04-23T19:15:00+0200",
        endDate: "2023-04-23",
        eventStatus: "https://schema.org/EventScheduled",
        image:
          "https://images.sk-static.com/images/media/profile_images/artists/501187/huge_avatar",
        location: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressCountry: "Germany",
            addressLocality: "Lutterbek",
            postalCode: "24235",
            streetAddress: "Dorfstraße 11",
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: 54.39134,
            longitude: 10.27636,
          },
          name: "Lutterbeker",
          sameAs: "http://www.lutterbeker.de/",
        },
        name: "Dona Rosa",
        performer: [
          {
            "@type": "MusicGroup",
            name: "Dona Rosa",
          },
        ],
        startDate: "2023-04-23T19:15:00+0200",
      },
      {
        "@context": "http://schema.org",
        "@type": "MusicEvent",
        description: "Klan at Die Pumpe at 2023-04-26",
        endDate: "2023-04-26",
        eventStatus: "https://schema.org/EventScheduled",
        image:
          "https://images.sk-static.com/images/media/profile_images/artists/64731/huge_avatar",
        location: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressCountry: "Germany",
            addressLocality: "Kiel",
            postalCode: "24103",
            streetAddress: "Haßstr. 22",
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: 54.32458,
            longitude: 10.13807,
          },
          name: "Die Pumpe",
          sameAs: "https://www.diepumpe.de/",
        },
        name: "Klan",
        performer: [
          {
            "@type": "MusicGroup",
            name: "Klan",
          },
        ],
        startDate: "2023-04-26",
      },
      {
        "@context": "http://schema.org",
        "@type": "MusicEvent",
        description: "Joe Bonamassa at WUNDERINO ARENA at 2023-04-27",
        endDate: "2023-04-27",
        eventStatus: "https://schema.org/EventScheduled",
        image:
          "https://images.sk-static.com/images/media/profile_images/artists/414198/huge_avatar",
        location: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressCountry: "Germany",
            addressLocality: "Kiel",
            postalCode: "24103",
            streetAddress: "Europaplatz 1",
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: 54.32092,
            longitude: 10.13151,
          },
          name: "WUNDERINO ARENA",
          sameAs: "https://www.wunderino-arena.de",
        },
        name: "Joe Bonamassa",
        performer: [
          {
            "@type": "MusicGroup",
            name: "Joe Bonamassa",
          },
        ],
        startDate: "2023-04-27",
      },
      {
        "@context": "http://schema.org",
        "@type": "MusicEvent",
        description: "Mal Élevé at Die Pumpe at 2023-04-27",
        endDate: "2023-04-27",
        eventStatus: "https://schema.org/EventScheduled",
        image:
          "https://images.sk-static.com/images/media/profile_images/artists/10090978/huge_avatar",
        location: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressCountry: "Germany",
            addressLocality: "Kiel",
            postalCode: "24103",
            streetAddress: "Haßstr. 22",
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: 54.32458,
            longitude: 10.13807,
          },
          name: "Die Pumpe",
          sameAs: "https://www.diepumpe.de/",
        },
        name: "Mal Élevé",
        performer: [
          {
            "@type": "MusicGroup",
            name: "Mal Élevé",
          },
        ],
        startDate: "2023-04-27",
      },
      {
        "@context": "http://schema.org",
        "@type": "MusicEvent",
        description:
          "Nathan Johnston and Angels Of Libra at Hansa 48 at 2023-04-27T20:00:00+0200",
        endDate: "2023-04-27",
        eventStatus: "https://schema.org/EventScheduled",
        image:
          "https://images.sk-static.com/images/media/profile_images/artists/10223875/huge_avatar",
        location: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressCountry: "Germany",
            addressLocality: "Kiel",
            postalCode: "24118",
            streetAddress: "Hansastraße 48",
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: 54.33526,
            longitude: 10.1266,
          },
          name: "Hansa 48",
          sameAs: "http://www.hansa48.de",
        },
        name: "Nathan Johnston",
        performer: [
          {
            "@type": "MusicGroup",
            name: "Nathan Johnston",
          },
          {
            "@type": "MusicGroup",
            name: "Angels Of Libra",
          },
        ],
        startDate: "2023-04-27T20:00:00+0200",
      },
      {
        "@context": "http://schema.org",
        "@type": "MusicEvent",
        description:
          "A Mess (DK) at Kieler Schaubude at 2023-04-28T19:00:00+0200",
        endDate: "2023-04-28",
        eventStatus: "https://schema.org/EventScheduled",
        image:
          "https://images.sk-static.com/images/media/profile_images/artists/10142099/huge_avatar",
        location: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressCountry: "Germany",
            addressLocality: "Kiel",
            postalCode: "24103",
            streetAddress: "Legienstr. 40",
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: 54.32784,
            longitude: 10.13041,
          },
          name: "Kieler Schaubude",
          sameAs: "http://www.kieler-schaubude.de",
        },
        name: "A Mess (DK)",
        performer: [
          {
            "@type": "MusicGroup",
            name: "A Mess (DK)",
          },
        ],
        startDate: "2023-04-28T19:00:00+0200",
      },
    ];

    const setting = {
      width: "1200px",
      height: ["250px", "170px"],
      layout: [4, 7],
      photos: [
        { source: collageInfo[0].image },
        { source: collageInfo[23].image },
        { source: collageInfo[14].image },
        { source: collageInfo[22].image },
        { source: collageInfo[3].image },
        { source: collageInfo[1].image },
        { source: collageInfo[24].image },
        { source: collageInfo[25].image },
        { source: collageInfo[9].image },
        { source: collageInfo[26].image },
        { source: collageInfo[11].image },
        { source: collageInfo[12].image },
        // { source: collageInfo[13].image },
        // chmod 700 C/Users/suket/.ssh && chmod 600 C/Users/suket/.ssh/authorized_keys

        // { source: collageInfo[15].image },
        // { source: collageInfo[16].image },
        // { source: collageInfo[17].image },
        // { source: collageInfo[18].image },
        // { source: collageInfo[19].image },
        // { source: collageInfo[20].image },
      ],
      // showNumOfRemainingPhotos: true,
    };
    return (
      <div className="hero-banner">
        <ReactModal
          id="modal"
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterModalOpen}
          onRequestClose={this.closeModal}
          contentLabel="Modal_Content"
          className="modal"
        >
          <SessionForm
            formType={this.state.modalFormType}
            closeModal={this.closeModal}
          />
        </ReactModal>
        <div className="hero-banner-container">
          <div className="hero-banner-content">
            <span className="hero-banner-title">New Events EveryDay</span>
            <p className="hero-banner-text">
              Bonzai surfaces the best new Events, every day. It's a place for
              events enthusiasts to share and geek out about the latest events.
              Subscribe to Bonzai and you will get event information, tickets.
            </p>
            <div className="herobutt">
              <Link
                className="subscribeButton"
                to="/subscribePlans"
                // target="_blank"
              >
                <button className="hero-button">Subscribe</button>
              </Link>

              <button
                className="hero-button"
                onClick={this.handleClick("signup")}
              >
                SIGN IN
              </button>
            </div>
          </div>
          <div className="heroCollage">
            {/* <ImageScroller> */}
            <ReactPhotoCollage {...setting} />
            {/* <Gallery images={collageInfo} /> */}
            {/* </ImageScroller> */}
          </div>
          {/* <img src="herrobanner.png" className="hero-banner-img"></img> */}
        </div>
      </div>
    );
  }
}

export default HeroBanner;

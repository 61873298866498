import {
  RECEIVE_CURRENT_USER,
  RECEIVE_SESSION_ERRORS,
} from "../actions/session_actions";
import { RECEIVE_CLEAR_ERRORS } from "../actions/error_actions";
import { merge } from 'lodash';

const sessionErrorsReducer = (state = [], action) => {
  Object.freeze(state);
  let newState = merge({}, state);
  switch (action.type) {
    case RECEIVE_SESSION_ERRORS:
      state.errors = action.errors
      console.log(state)
      return state;
    case RECEIVE_CURRENT_USER:
      // console.log(state);
      return state;
    case RECEIVE_CLEAR_ERRORS:
      return [];
    default:
      return state;
  }
};

export default sessionErrorsReducer;

import * as APIUtil from "../util/session_api_util";

export const RECEIVE_CURRENT_USER = "RECEIVE_CURRENT_USER";
export const RECEIVE_SESSION_ERRORS = "RECEIVE_SESSION_ERRORS";

export const receiveCurrentUser = (currentUser) => ({
  type: RECEIVE_CURRENT_USER,
  currentUser,
});
export const otpSent = (currentUser1) => ({
  type: RECEIVE_CURRENT_USER,
  currentUser1,
});

export const receiveSessionErrors = (errors) => ({
  type: RECEIVE_SESSION_ERRORS,
  errors,
});

export const signup =
  (phone, email, first_name, last_name, address, password) => (dispatch) =>
    APIUtil.signup(phone, email, first_name, last_name, address, password).then(
      (res) => dispatch(receiveCurrentUser(res)),
      (err) => dispatch(receiveSessionErrors(err.responseJSON))
    );
export const verify = (uid, token) => (dispatch) =>
  APIUtil.verify(uid, token).then(
    (res) => dispatch(receiveCurrentUser(res)),
    (err) => dispatch(receiveSessionErrors(err.responseJSON))
  );
export const login = (email, password) => (dispatch) =>
  APIUtil.login(email, password).then(
    (res) => dispatch(receiveCurrentUser(res)),
    (err) => dispatch(receiveSessionErrors(err.responseJSON))
  );

export const logout = () => (dispatch) =>
  APIUtil.logout().then((user) => dispatch(receiveCurrentUser(null)));

import {
  RECEIVE_PRODUCT,
  RECEIVE_ALL_PRODUCTS,
  RECEIVE_IMAGES,
  RECEIVE_PRODUCT_COMMENTS,
} from "../actions/product_actions"
import { RECEIVE_UPVOTE, REMOVE_UPVOTE } from "../actions/upvote_actions"
import { merge } from "lodash"

let defaultState = {}

const productsReducer = (state = defaultState, action) => {
  Object.freeze(state)
  let newState = merge({}, state)
  let upvote = undefined
  // const BigNumber = require('bignumber.js');
  switch (action.type) {
    case RECEIVE_PRODUCT_COMMENTS:
      newState.comments = action.comments
      console.log(action)
      return newState
    case RECEIVE_PRODUCT:
      newState.currentProductUuId = action.data[0].unique_identity_number;
      newState.currentProduct = action.data[0];
      // console.log(newState)
      return newState
    case RECEIVE_IMAGES:
      newState.currentProductImages = action.images.items
      return newState
    case RECEIVE_ALL_PRODUCTS:
      return action.products
    case RECEIVE_UPVOTE:
      upvote = action.upvote
      if (upvote.upvoteable_type === "Product") {
        newState.by_id[upvote.upvoteable_id].currentUserUpvoted = true
        newState.by_id[upvote.upvoteable_id].upvotes_count++
      }
      return newState
    case REMOVE_UPVOTE:
      upvote = action.upvote
      if (upvote.upvoteable_type === "Product") {
        newState.by_id[upvote.upvoteable_id].currentUserUpvoted = false
        newState.by_id[upvote.upvoteable_id].upvotes_count--
      }
      return newState
    default:
      return state
  }
}

export default productsReducer


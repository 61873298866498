import React from "react";
import ReactDOM from "react-dom";
import configureStore from "./store/store";
import Root from "./components/root";
// import "./App.scss";
import "./assets/stylesheets/application.scss";

//Start Testing
import { signup, login, logout } from "./actions/session_actions";
import {
  fetchProduct,
  fetchAllProducts,
  createProduct,
} from "./actions/product_actions";
import { setViewedProduct } from "./actions/ui_actions";
import { fetchUser, editUser } from "./actions/user_actions";
import { getPosts } from "./util/ph_api_util";
import { getProduct } from "./util/product_api_util";
import { GoogleOAuthProvider } from "@react-oauth/google";

window.signup = signup;
window.login = login;
window.logout = logout;

window.fetchProduct = fetchProduct;
window.fetchAllProducts = fetchAllProducts;
window.createProduct = createProduct;

window.setViewedProduct = setViewedProduct;

window.fetchUser = fetchUser;
window.editUser = editUser;

window.getPosts = getPosts;

window.getProduct = getProduct;
//End Testing

document.addEventListener("DOMContentLoaded", () => {
  let root = document.getElementById("root");
  let store = undefined;

  if (window.currentUser) {
    const preloadedState = { session: { currentUser: window.currentUser } };
    store = configureStore(preloadedState);
  } else {
    store = configureStore();
  }

  //Start Testing
  window.dispatch = store.dispatch;
  window.getState = store.getState;
  //End Testing

  ReactDOM.render(
    <GoogleOAuthProvider clientId="486412679779-429lfa63chlcvc5njglb520hh0o8voj8.apps.googleusercontent.com">
      <Root store={store} />
    </GoogleOAuthProvider>,
    root
  );
});

import React from "react";
import { Link } from "react-router-dom";

class SiteNav extends React.Component {
  constructor(props) {
    super(props);
    this.showMetaMenu = this.showMetaMenu.bind(this);
  }

  showMetaMenu() {}
  handleClick(formType) {
    return () => {
      this.props.clearErrors();
      this.props.openModal(formType)();
    };
  }

  render() {
    const x = this.props.currentUser;
    const userType = x?.id;
    // console.log(userType);
    return (
      <hgroup className="site-nav">
        {!this.props.currentUser && (
          <a onClick={this.handleClick("signup")}>Add New</a>
        )}
        {userType == 0 && (
          <a
            onClick={() =>
              alert("Verify your email address. To add new market places")
            }
            target="_blank"
          >
            Add New
          </a>
        )}
        {/* {userType == 1 && (
          <a href="" target="_blank">
            Add New
          </a>
        )} */}
        {/* <div className="dropdown-b">
          <button class="dropbtn-b">Explore</button>
          <div class="dropdown-content-b">
            <a href="#">About Us</a>
            <a href="#">FAQ</a>
            <a href="#">Advertise</a>
            <a href="#">Post a Job</a>
            <a href="#">Legal</a>
          </div>
        </div> */}

        {/* <Link to="/ask">EXPLORE</Link> */}

        {/* <Link to="/jobs">Jobs</Link> */}
        {/* <button className="meta-menu-button">...</button> */}
      </hgroup>
    );
  }
}

export default SiteNav;

import React from "react";
import "../Terms/TermsOfUse.scss";

const TermsOfUse = () => {
  return (
    <div className="app-content">
      <div>
        <div className="TermsOfUse">
          <div className="terms-container">
            <h2 className="terms-heading">BONZAI TERMS OF USE AGREEMENT</h2>
            <p className="terms-text">
              Welcome to Bonzai, a centralized platform that provides unlimited
              access to your preferred entertainment for free with one
              subscription. These Terms of Use govern your access to and use of
              the Bonzai website and mobile application (collectively, the
              "Service"). By using the Service, you agree to be bound by these
              Terms of Use and our Privacy Policy. If you do not agree to these
              Terms of Use, you may not use the Service.
            </p>
            <h3 className="terms-subheading">1. Eligibility</h3>
            <p className="terms-text">
              To use the Service, you must be at least 18 years old and have the
              legal capacity to enter into this agreement. If you are using the
              Service on behalf of a company or other legal entity, you
              represent and warrant that you have the authority to bind that
              entity to these Terms of Use.
            </p>
            <h3 className="terms-subheading">2. Use of the Service</h3>
            <p className="terms-text">
              You may use the Service only for lawful purposes and in accordance
              with these Terms of Use. You agree not to use the Service:
            </p>
            <ul className="terms-list">
              <li className="terms-list-item">
                In any way that violates any applicable federal, state, local or
                international law or regulation.
              </li>
              <li className="terms-list-item">
                For the purpose of exploiting, harming or attempting to exploit
                or harm minors in any way by exposing them to inappropriate
                content, asking for personally identifiable information, or
                otherwise.
              </li>
              <li className="terms-list-item">
                To transmit, or procure the sending of, any advertising or
                promotional material, including any "junk mail", "chain letter"
                or "spam" or any other similar solicitation.
              </li>
              <li className="terms-list-item">
                To impersonate or attempt to impersonate Bonzai, a Bonzai
                employee, another user or any other person or entity.
              </li>
              <li className="terms-list-item">
                To engage in any other conduct that restricts or inhibits
                anyone's use or enjoyment of the Service, or which, as
                determined by us, may harm Bonzai or users of the Service or
                expose them to liability.
              </li>
            </ul>
            <h3 className="terms-subheading">3. User Content</h3>
            <p className="terms-text">
              The Service may allow you to upload, submit, store, send or
              receive content, including but not limited to photos, videos, and
              text (collectively, "User Content"). You retain ownership of any
              intellectual property rights that you hold in your User Content.
              By submitting User Content to the Service, you grant Bonzai a
              non-exclusive, royalty-free, worldwide, transferable,
              sub-licensable license to use, copy, modify, create derivative
              works based on, distribute, publicly display, publicly perform,
              and otherwise exploit in any manner such User Content in all
              formats and distribution channels now known or hereafter devised
              (including in connection with the Service and Bonzai's business
              and on third-party sites and services), without further notice to
              or consent from you, and without the requirement of payment to you
              or any other person or entity.
            </p>
            <p className="terms-text">You represent and warrant that:</p>
            <ul className="terms-list">
              <li className="terms-list-item">
                You either are the sole and exclusive owner of all User Content
                or you have all rights, licenses, consents and releases that are
                necessary to grant to Bonzai the rights in such User Content, as
                contemplated under these Terms of Use.
              </li>
              <li className="terms-list-item">
                The User Content does not infringe any patent, trademark, trade
                secret, copyright, or other intellectual property or proprietary
                right of any party.
              </li>
              <li className="terms-list-item">
                The User Content does not violate any applicable law or
                regulation, including but not limited to the regulations of the
                Federal Trade Commission or any other equivalent regulatory
                agency.
              </li>
            </ul>
            <p className="terms-text">
              Bonzai is not responsible for the content of any User Content or
              the conduct of any user of the Service.
            </p>
            <h3 className="terms-subheading">4. Proprietary Rights</h3>
            <p className="terms-text">
              The Service and its entire contents, features, and functionality
              (including but not limited to all information, software, text,
              displays, images, video, and audio, and the design, selection, and
              arrangement thereof), are owned by Bonzai, its licensors, or other
              providers of such material and are protected by EU.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfUse;

import React from "react";
import "../policy/policy.scss";
const policy = () => {
  return (
    <div className="app-content">
      <div className="privacy-policy-container">
        <h2 className="privacy-policy-title">Bonzai Privacy Policy</h2>
        <p className="privacy-policy-text">Last Updated: May 6, 2023</p>
        <p className="privacy-policy-text">
          Thank you for using Bonzai! We are committed to protecting your
          privacy and have developed this privacy policy to explain how we
          collect, use, and protect your personal information.
        </p>
        <h3 className="privacy-policy-subtitle">Information We Collect</h3>
        <p className="privacy-policy-text">
          We collect the following types of information from our users:
        </p>
        <ul className="privacy-policy-list">
          <li className="privacy-policy-list-item">
            Personal Information: We may collect personal information such as
            your name, email address, and location data when you create an
            account on our platform or sign up for our subscription service.
          </li>
          <li className="privacy-policy-list-item">
            Usage Information: We may collect information about how you use our
            platform, including the types of content you access and your
            interactions with other users.
          </li>
          <li className="privacy-policy-list-item">
            Cookies and Other Tracking Technologies: We may use cookies or other
            tracking technologies to collect information about your browsing
            behavior and preferences.
          </li>
        </ul>
        <h3 className="privacy-policy-subtitle">How We Use Your Information</h3>
        <p className="privacy-policy-text">
          We may use the information we collect from you in the following ways:
        </p>
        <ul className="privacy-policy-list">
          <li className="privacy-policy-list-item">
            To Create and Manage User Accounts: We use your personal information
            to create and manage your user account on our platform.
          </li>
          <li className="privacy-policy-list-item">
            To Provide Personalized Recommendations and Advertising: We may use
            your usage information and other data to provide personalized
            recommendations for content and advertising.
          </li>
          <li className="privacy-policy-list-item">
            To Improve Our Platform: We may use your data to improve the
            functionality and performance of our platform, as well as to conduct
            research and analysis to better understand our users' needs and
            preferences.
          </li>
        </ul>
        <h3 className="privacy-policy-subtitle">
          Disclosure of Your Information
        </h3>
        <p className="privacy-policy-text">
          We do not share your personal information with third parties for their
          marketing purposes. However, we may share your information with
          third-party service providers that help us operate our platform and
          provide our subscription service.
        </p>
        <h3 className="privacy-policy-subtitle">Data Security</h3>
        <p className="privacy-policy-text">
          We take appropriate measures to protect your personal information from
          unauthorized access, disclosure, alteration, or destruction. These
          measures include encryption, secure servers, and access controls.
        </p>
        <h3 className="privacy-policy-subtitle">User Rights</h3>
        <p className="privacy-policy-text">
          You have the right to access, correct, or delete your personal
          information. You may also request that we stop using your data for
          certain purposes or object to our processing of your data. Please
          contact us at privacy@
        </p>
        <h3 className="privacy-policy-subtitle">Changes to This Policy</h3>
        <p className="privacy-policy-text">
          We may update this privacy policy from time to time to reflect changes
          in our practices or legal requirements. We will notify you of any
          material changes by posting a notice on our website or by other means.
        </p>
        <h3 className="privacy-policy-subtitle">Contact Us</h3>
        <p className="privacy-policy-text">
          If you have any questions or concerns about this privacy policy or our
          privacy practices, please contact us at contact@bonzaico.com
        </p>
      </div>
    </div>
  );
};

export default policy;

import React, { useState, useEffect } from "react";
import $ from "jquery";

import ProductIndexItem from "./product_index_item";
import { GridLoader } from "react-spinners";
import ProductNav from "../product_nav/product_nav";

import cities from "./csvjson";

function getCurrentCordinates() {
  return new Promise(function (resolve, reject) {
    $.ajax({
      url: "https://ip-geo-location.p.rapidapi.com/ip/check",
      method: "GET",
      headers: {
        "X-RapidAPI-Key": "d690b2db73mshc67042a791fb26ep1b7205jsn2dd96ed6cc7a",
        "X-RapidAPI-Host": "ip-geo-location.p.rapidapi.com",
      },
      dataType: "json",
      success: function (data) {
        resolve(data);
      },
      error: function (xhr, status, error) {
        reject(error);
      },
    });
  });
}

const ProductIndex = ({
  userUpvotes,
  createUpvote,
  destroyUpvote,
  products,
  token,
  productIds,
  currentUser,
  fetchAllProducts,
  viewedProductId,
  fetchProduct,
  fetchUpvotes,
  openModal,
  closeModal,
  clearErrors,
}) => {
  const [loading, setLoading] = useState(true);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [nearestCity, setNearestCity] = useState(null);
  const [locationAccessMessage, setLocationAccessMessage] = useState(null);
  const [error, setError] = useState(null);
  const months = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  const date = new Date();
  let da = date.getDate();
  let day = da;
  if (da == "1") {
    day = "01";
  } else if (da == "2") {
    day = "02";
  } else if (da == "3") {
    day = "03";
  } else if (da == "4") {
    day = "04";
  } else if (da == "5") {
    day = "05";
  } else if (da == "6") {
    day = "06";
  } else if (da == "7") {
    day = "07";
  } else if (da == "8") {
    day = "08";
  } else if (da == "9") {
    day = "09";
  } else {
    day = da;
  }
  let mont = months[date.getMonth()];
  let month = mont;
  if (mont == "1") {
    month = "01";
  } else if (mont == "2") {
    month = "02";
  } else if (mont == "3") {
    month = "03";
  } else if (mont == "4") {
    month = "04";
  } else if (mont == "5") {
    month = "05";
  } else if (mont == "6") {
    month = "06";
  } else if (mont == "7") {
    month = "07";
  } else if (mont == "8") {
    month = "08";
  } else if (mont == "9") {
    month = "09";
  } else {
    month = mont;
  }
  let year = date.getFullYear();
  let currentDate = year + "-" + month + "-" + day;
  let currentUrlDateR = window.location.href.split("?query=")[1];
  if (currentUrlDateR) {
    currentDate = currentUrlDateR.substring(0, currentUrlDateR.length - 2);
    // console.log(currentDate);
  }

  // const allowAccess = () => {
  //   console.log("ccc");
  //   alert("Please allow Location acess. To know events at your location");
  // };

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append(
      "X-RapidAPI-Key",
      "d690b2db73mshc67042a791fb26ep1b7205jsn2dd96ed6cc7a"
    );
    myHeaders.append("X-RapidAPI-Host", "ip-geo-location.p.rapidapi.com");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("https://ip-geo-location.p.rapidapi.com/ip/check", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        const { latitude, longitude } = {
          latitude: result?.location?.latitude,
          longitude: result?.location?.longitude,
        };
        console.log(currentLocation);
        if (!currentLocation) {
          setCurrentLocation({ latitude, longitude });
        }
        const nc = findNearestCity({ latitude, longitude });
        setNearestCity(nc);
        console.log(nc.City);
        const nc1 = nc.City;
        return fetchAllProducts(currentDate, nc1).then(() => {
          setLoading(false);
        });
      })
      .catch((error) => console.log("error", error));

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };
        // console.log({ latitude, longitude });
        setCurrentLocation({ latitude, longitude });
        const nc = findNearestCity({ latitude, longitude });
        setNearestCity(nc);
        // console.log(nc.City);
        const nc1 = nc.City;
        return fetchAllProducts(currentDate, nc1).then(() => {
          setLoading(false);
        });
      },
      (error) => console.log(error)
    );
  }, []);

  const findNearestCity = ({ latitude, longitude }) => {
    let nearest = null;
    let smallestDistance = Infinity;

    cities?.forEach((city) => {
      const distance = calculateDistance(
        latitude,
        longitude,
        city?.Latitude,
        city?.Longitude
      );

      if (distance < smallestDistance) {
        smallestDistance = distance;
        nearest = city;
      }
    });

    return nearest;
  };

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371e3; // Earth's radius in meters
    const φ1 = (lat1 * Math.PI) / 180;
    const φ2 = (lat2 * Math.PI) / 180;
    const Δφ = ((lat2 - lat1) * Math.PI) / 180;
    const Δλ = ((lon2 - lon1) * Math.PI) / 180;

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c;
  };

  return (
    <div className="product-index-container">
      <div className="product-nav-container">
        <ProductNav productIds={productIds} />
      </div>

      <ul className="product-index">
        <div className="product-index-header">
          <p className="product-index-header-text">
            Events in {nearestCity?.City}
            {/* <div onClick={allowAccess}></div> */}
          </p>
        </div>
        <div className="product-index-header-border"></div>
        <div className="loading-container">
          <div className="sweet-loading">
            <GridLoader color={"#DA552F"} loading={loading} />
          </div>
        </div>
        {Array.isArray(productIds) &&
          productIds?.map((id) => {
            return (
              <ProductIndexItem
                product={id}
                key={id.unique_identity_number}
                currentUser={currentUser}
                token={token}
                fetchProduct={fetchProduct}
                viewedProductId={id.unique_identity_number}
                fetchAllProducts={fetchAllProducts}
                // userUpvotes={userUpvotes}
                createUpvote={createUpvote}
                openModal={openModal}
                closeModal={closeModal}
                clearErrors={clearErrors}
                // destroyUpvote={destroyUpvote}
              />
            );
          })}
      </ul>
    </div>
  );
};

export default ProductIndex;

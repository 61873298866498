import React from "react";
import "../Terms/Terms.scss";

const Terms = () => {
  return (
    <div className="app-content">
      <div>
        <h1 className="termsHeader">Terms of Service</h1>
        <p className="termsParagraph">
          Welcome to Bonzai! By using our platform, you agree to the following
          terms of service. If you do not agree to these terms, please do not
          use our platform.
        </p>
        <h2 className="termsSecondHeader">1. Description of Bonzai</h2>
        <p className="termsParagraph">
          Bonzai is a centralised platform that provides unlimited access to
          entertainment for a subscription fee. We offer different subscription
          models with various benefits such as recommendations, free tickets,
          and more. With Bonzai, you can choose your preferences and access all
          types of entertainment happening around you in a hassle-free manner.
        </p>
        <h2 className="termsSecondHeader">2. User Responsibilities</h2>
        <p className="termsParagraph">
          By using our platform, you agree to the following:
        </p>
        <ul className="termsContent">
          <li className="termsContentLI">
            You must be at least 13 years old or have the consent of a parent or
            guardian to use our platform.
          </li>
          <li className="termsContentLI">
            You are responsible for keeping your account information up-to-date
            and secure.
          </li>
          <li className="termsContentLI">
            You may not use our platform for illegal activities or to harm
            others.
          </li>
          <li className="termsContentLI">
            You may not share your account with anyone else.
          </li>
          <li className="termsContentLI">
            You agree to comply with all applicable laws and regulations.
          </li>
        </ul>
        <h2 className="termsSecondHeader">3. Payment and Subscription</h2>
        <p className="termsParagraph">
          To access our platform, you must subscribe to one of our subscription
          models. Payment will be charged to your account at the beginning of
          each subscription period. You may cancel your subscription at any
          time, but refunds will not be provided for any unused portion of the
          subscription period.
        </p>
        <h2 className="termsSecondHeader">4. Intellectual Property Rights</h2>
        <p className="termsParagraph">
          All content on our platform, including text, graphics, logos, images,
          and software, is the property of Bonzai or its licensors and is
          protected by copyright and other intellectual property laws. You may
          not use any content on our platform without our permission.
        </p>
        <h2 className="termsSecondHeader">5. Privacy</h2>
        <p className="termsParagraph">
          We take your privacy seriously and will only use your personal
          information in accordance with our Privacy Policy, which is
          incorporated into these terms of service by reference.
        </p>
        <h2 className="termsSecondHeader">6. Limitation of Liability</h2>
        <p className="termsParagraph">
          We do not guarantee that our platform will be error-free or
          uninterrupted, and we will not be liable for any damages resulting
          from your use of our platform.
        </p>
        <h2 className="termsSecondHeader">7. Changes to Terms of Service</h2>
        <p className="termsParagraph">
          We may update these terms of service from time to time. If we make any
          material changes, we will notify you by email or by posting a notice
          on our platform.
        </p>
        <h2 className="termsSecondHeader">8. Governing Law</h2>
        <p className="termsParagraph">
          These terms of service will be governed by and construed in accordance
          with the laws of EU.
        </p>
      </div>
    </div>
  );
};

export default Terms;

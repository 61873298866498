import React from "react";
import "../Contact/Contact.scss";

function Contact() {
  return (
    <div className="app-content">
      {/* <div> */}
      <div className="contact-us">
        <h1 className="contact-us__heading">Contact Us</h1>
        <p className="contact-us__text">
          At Bonzai, we're dedicated to providing a world-class entertainment
          experience to our users. We believe that personalized recommendations
          and hassle-free access to entertainment should be available to
          everyone. That's why we're always happy to hear from our users and
          welcome any questions, comments, or feedback you may have.
        </p>
        <p className="contact-us__text">
          When you contact us, you can expect a prompt and helpful response from
          our friendly customer support team. We take pride in our personalized
          approach to support, and we strive to provide the best possible
          experience for each and every user.
        </p>
        <h2 className="contact-us__subheading">Benefits of Contacting Us</h2>
        <ul className="contact-us__list">
          <li className="contact-us__item">Personalized support</li>
          <li className="contact-us__item">Feedback and suggestions</li>
          <li className="contact-us__item">Partnership opportunities</li>
          <li className="contact-us__item">Press inquiries</li>
          <li className="contact-us__item">Career opportunities</li>
        </ul>
        <p className="contact-us__text">
          We're confident that you'll love using Bonzai, and we're committed to
          providing exceptional service to help you get the most out of our
          platform. Whether you have a question, a suggestion, or just want to
          say hello, we're always happy to hear from you.
        </p>
        <p className="contact-us__text">
          To get in touch with us, please email us at{" "}
          <a href="mailto:info@bonzaico.com" className="contact-us__link">
            info@bonzaico.com
          </a>{" "}
          or call us at{" "}
          <a href="tel:+4917632890611" className="contact-us__link">
            +491-763-289-0611
          </a>
          . Our customer support team is available Monday through Friday from
          9:00am to 5:00pm.
        </p>
      </div>
      {/* </div> */}
    </div>
  );
}

export default Contact;

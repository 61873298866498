import { connect } from "react-redux"
import { withRouter } from "react-router"
import ProductDiscussion from "./product_discussion"
import { createComment, destroyComment, getComment } from "../../actions/product_actions"
import { createUpvote, destroyUpvote } from "../../actions/upvote_actions"
import { event } from "jquery"

const mapStateToProps = (state, ownProps) => {
  // let commentsById = {}
  // let childComments = state.entities.products.comments.filter(comment => {
  //   if(comments?.parent_comment_id != ""){
  //     return comment
  //   }
  // })
  // let commentIds = []
  // let comments = []
  let currentUser = state.session.currentUser?.userEmail
  let productId = ownProps.match.params.productId
  // console.log(currentUser)
  let allComments = state.entities.products.comments
  let childComments = [];
  // console.log(state);
  if(Array.isArray(allComments))
  {for (let i = 0; i < allComments.length; i++) {
    if (allComments[i]?.parent_comment_id != "") {
      childComments.push(allComments[i]);
    }
   }}
  // console.log(childComments)
  // if (state.entities.comments) {
  //   // commentsById = state.entities.comments.by_id
  //   // commentIds = state.entities.comments.all_ids
  //   // childComments = state.entities.comments.by_parent_id
  //   let comments = state.entities.comments
  // }

  return {
    allComments,
    // commentsById,
    // commentIds,
    childComments,
    currentUser,
    productId,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    createComment: (comment) => dispatch(createComment(comment)),
    getComment: (comment) => dispatch(getComment(comment)),
    destroyComment: (commentId) => dispatch(destroyComment(commentId)),
    createUpvote: (upvoteableType, upvoteableId) =>
      dispatch(createUpvote(upvoteableType, upvoteableId)),
    destroyUpvote: (upvoteableType, upvoteableId) =>
      dispatch(destroyUpvote(upvoteableType, upvoteableId)),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductDiscussion)
)


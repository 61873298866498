import React, { useEffect, useState, componentDidMount } from "react";
// import { productUrl } from "./product_detail_container";
import ProductDetailHeader from "./product_detail_header";
import ProductDiscussionContainer from "../product_discussion/product_discussion_container";
import ProductDetailNav from "./product_detail_nav";
import ProductDiscussionMedia from "./product_detail_media";
import product_detail_container from "./product_detail_container";
import createContext from "react";

const ProductDetail = ({
  product,
  currentUser,
  productImages,
  viewedProductId,
  productUrl,
  fetchProduct,
  fetchProductImages,
  createUpvote,
  destroyUpvote,
  token,

  clearErrors,
  openModal,
}) => {
  const [loading, setLoading] = useState(true);
  const [isShown, setIsShown] = useState(false);
  const [showTopBtn, setShowTopBtn] = useState(false);
  const handleClick = (event) => {
    // 👇️ toggle shown state
    setIsShown((current) => !current);

    // 👇️ or simply set it to true
    // setIsShown(true);
  };
  // if (productUrl) {
  //   let x = productUrl;
  //   if (x.indexOf("https://") == -1) {
  //     x = "https://" + x;
  //   }
  //   let domain = new URL(x);
  //   domain = domain.hostname;
  //   fetchProductImages(domain).then(() => {
  //     setLoading(false);
  //   });
  // }
  const goToTop = () => {
    window.scrollTo({
      top: 0,
    });
    console.log("dadadad");
  };
  useEffect(() => {
    // fetchProduct(viewedProductId);
    // console.log(product);
    // if (productUrl) {
    //   let x = productUrl;
    //   if (x.indexOf("https://") == -1) {
    //     x = "https://" + x;
    //   }
    //   let domain = new URL(x);
    //   domain = domain.hostname;
    //   fetchProductImages(domain);
    // }
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });

    let url = window.location.href;

    let link = url.split("#/products/")[0];
    let eventDay = link.split("?query=")[1];
    let productString = url.split("#/products/")[1];
    let productString1 = productString.split("%22").join('"');
    let product = JSON.parse(productString1.split("%20").join(" "));
    // console.log(product);
    // window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    fetchProductImages(product.name);
    goToTop();
  }, []);
  // window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

  return product ? (
    <div className="app-content">
      <div className="product-detail-container">
        <ProductDetailHeader product={product} />
        <div className="product-detail-main-content-container">
          <div className="product-detail-content-bar">
            {/* <div className="product-detail-content-header">
            <h3>DISCUSSION</h3>
          </div> */}
            <ProductDiscussionMedia
              product={product}
              productUrl={productUrl}
              productImages={productImages}
              viewedProductId={viewedProductId}
              fetchProduct={fetchProduct}
              fetchProductImages={fetchProductImages}
              currentUser={currentUser}
              isShown={isShown}
              setIsShown={setIsShown}
              clearErrors={clearErrors}
              openModal={openModal}
            />

            <ProductDiscussionContainer
              product={product}
              currentUser={currentUser}
            />
          </div>
          <div className="product-detail-aside">
            <ProductDetailNav
              product={product}
              fetchProduct={fetchProduct}
              token={token}
              viewedProductId={viewedProductId}
              createUpvote={createUpvote}
              destroyUpvote={destroyUpvote}
              currentUser={currentUser}
            />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="loader">Loading...</div>
  );
};

export default ProductDetail;

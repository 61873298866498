import React from "react";
import UserHeader from "./user_header";
// import UserEdit from "./user_edit_form";
import UserProfileNav from "./user_profile_nav";
import ProductsIndexContainer from "../products_index/products_index_container";
import ProductAd from "../product_ad/product_ad";

class UserProfile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    // let viewedUserId = this.props.match.params.userId;
    this.props.fetchUser(this.props.viewedUserId);
  }

  willReceiveProps(nextProps) {}

  render() {
    let user = this.props.viewedUser;
    let currentUser = this.props.currentUser;
    let viewedUserId = this.props.viewedUserId;
    // let currentUserId = currentUser?.id;
    return (
      <div className="app-content">
        <div className="user-profile">
          {/* <h1>Profile Page</h1> */}
          <UserHeader
            user={user}
            currentUser={currentUser}
            viewedUserId={viewedUserId}
          />
          {/* <UserEdit
            user={user}
            currentUser={currentUser}
            viewedUserId={viewedUserId}
          /> */}
          {/* <div className="user-profile-content-container"> */}
          {/* <div className="user-profile-nav-container">
            <UserProfileNav user={user} />
          </div> */}
          {/* <div className="user-profile-index-container">
            <ProductsIndexContainer />
          </div> */}
          {/* <div className="user-profile-ad-container">
            <ProductAd />
          </div> */}
          {/* </div> */}
        </div>
      </div>
    );
  }
}

export default UserProfile;

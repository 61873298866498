import React, { useState, useEffect } from "react";
import ProductIndexItem from "../products_index/product_index_item";
import ReactDOM from "react-dom/client";
import ProductNav from "../product_nav/product_nav";

class SearchResultsIndex extends React.Component {
  constructor(props) {
    super(props);
    let url = window.location.href;
    url = url.split("search?query=");
    let query = url[1];
    // console.log(query);
    // this.state = {
    //   loading: true,
    // };
    console.log(query);

    this.props.tagSearch(query);

    // .then(() => {
    //   this.setLoading(false);
    // });
    console.log(this.props?.products);
  }
  componentDidUpdate(previousProps) {
    if (previousProps.location.search != this.props.location.search) {
      let url = window.location.href;
      url = url.split("query=");
      const query = url[1];
      // console.log(query);
      // this.state = {
      //   loading: true,
      // };
      // console.log(props);
      this.props.tagSearch(query);
    }
  }
  render() {
    return (
      <div className="search-feed">
        {/* <div className="product-nav-container">
          <ProductNav productIds={this.props.productResults} />
        </div> */}
        <div className="search-index-container">
          <div className="product-index-header">
            <p className="search-index-header-text">Search Index</p>
          </div>
          <div className="product-index-header-border"></div>
          <ul className="search-index">
            {Array.isArray(this.props?.products) &&
              this.props?.products?.map((id) => {
                return (
                  <ProductIndexItem
                    product={id}
                    key={id.unique_identity_number}
                    currentUser={this.props.currentUser}
                    token={this.props.token}
                    fetchProduct={this.props.fetchProduct}
                    viewedProductId={id.unique_identity_number}
                    tagSearch={this.props.tagSearch}
                    createUpvote={this.props.createUpvote}
                  />
                );
              })}
          </ul>
        </div>
      </div>
    );
  }
}

export default SearchResultsIndex;

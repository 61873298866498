import { connect } from "react-redux";
import UserProfile from "./user_profile";
import { withRouter } from "react-router";

import { fetchUser } from "../../actions/user_actions";

const mapStateToProps = (state, ownProps) => {
  // let user = state.users[state.ui.viewedUser];
  let viewedUserId = null;
  let viewedUser = state.entities.viewedUser;
  let currentUser = null;

  if (state.session.currentUser) {
    currentUser = state.session.currentUser;
    viewedUserId = state.session.currentUser.token.access;
  }

  return {
    viewedUserId,
    viewedUser,
    currentUser,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchUser: (token) => dispatch(fetchUser(token)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserProfile)
);

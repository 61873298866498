import $ from "jquery";

export const signup = (
  phone,
  email,
  first_name,
  last_name,
  address,
  password
) => {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      phone: phone,
      first_name: first_name,
      second_name: last_name,
      email: email,
      address: address,
      password: password,
      password2: password,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
      credentials: "include",
    };

    fetch("https://admin1.bonzaico.com/email/register", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        resolve(result);

        // })
        // .catch((error) => alert(error));
      });
  });
};

export const verify = (uid, token) => {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      uid: uid,
      token: token,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://admin1.bonzaico.com/email/verify", requestOptions)
      .then((response) => response.json())
      .then((result) => resolve(result))
      .catch((error) => resolve(error));
  });
};

export const login = (email, password) => {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: email,
      password: password,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://admin1.bonzaico.com/email/login", requestOptions)
      .then((response) => response.json())
      .then((result) => resolve(result));
  });
};
// $.ajax({
//   method: "POST",
//   url: `api/session`,
//   data: { user },
// });

export const logout = () =>
  $.ajax({
    method: "DELETE",
    url: `api/session`,
  });

import React from "react";
import CommentItem from "./product_discussion_comment_item";

const Thread = ({
  comment,
  childComments,
  currentUser,
  productId,
  createComment,
  destroyComment,
  createUpvote,
  destroyUpvote,
}) => {
  // console.log(comment);

  // const childCommentsAllIds = childComments.all_ids;
  // const childCommentsById = childComments.by_id;

  return (
    <div className="thread">
      <CommentItem
        comment={comment}
        className={`comment`}
        currentUser={currentUser}
        productId={productId}
        parentCommentId={comment.parent_comment_id}
        createComment={createComment}
        destroyComment={destroyComment}
        createUpvote={createUpvote}
        destroyUpvote={destroyUpvote}
      />
      {childComments.map((childcomment) => {
        // console.log(comment);
        // console.log(childcomment);
        if (childcomment.parent_comment_id == comment.id)
          return (
            <CommentItem
              comment={childcomment}
              key={`child-comment-${childcomment.id}`}
              className={`child-comment`}
              currentUser={currentUser}
              productId={productId}
              parentCommentId={childcomment.parent_comment_id}
              createComment={createComment}
              destroyComment={destroyComment}
              createUpvote={createUpvote}
              destroyUpvote={destroyUpvote}
            />
          );
      })}
    </div>
  );
};

export default Thread;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import onClickOutside from 'react-onclickoutside'

import { withRouter } from 'react-router';
import i from 'react-fontawesome';
import { search } from '../../util/search_api_util';
import searchResultsReducer from '../../reducers/search_results_reducer';



class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { query: "" };
    this.wrapperRef = React.createRef();
    this.update = this.update.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    
  }
  componentDidMount(){
    document.addEventListener('click', function handleClickOutsideBox(event) {
      const box = document.getElementById('dropdown7');
      const searchInputBox = document.getElementById('search-input');
    // console.log(event.target.id);
    if(!box.contains(event.target)) {
        box.style.display = 'none';
      }
    if (searchInputBox.contains(event.target)) {
        box.style.display = 'initial';
      }
    });
  }

  update(e) {
    let query = e.target.value;
    // console.log(query)
    
    // let searchResults = state.entities.searchResults;
    this.setState({query}, () => {
      if (this.state.query.length > 0) {
        
        this.props.omniSearch(query);
        // console.log(this.props)
      }
    });
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      const box = document.getElementById('dropdown7');
      box.style.display = 'none';
      this.props.history.push({
        pathname: "/search",
        search: `?query=${this.state.query}`
      });
    }
  }
  handleSearchResultClick(r){
    // this.props.tagSearch(r);
    const box = document.getElementById('dropdown7');
    box.style.display = 'none';
    this.props.history.push({
      pathname: "/search",
      search: `?query=${r}`
    });
  }
  // handleOnClickListItem(res) {
  //   let path = `/products/${res.unique_identity_number}`
  //   console.log(this.props.searchResults)
  // } 

  render() {
    // console.log(this.props.searchResults)
    return (
      <div>
        {/* <i className="fa fa-search" /> */}
        <input type="search"
              id="search-input"
               className="search-bar" 
               value={this.state.query}
               onChange={this.update}
               onKeyPress={this.handleKeyPress}
              //  className="search-bar"
               placeholder="Location">
        </input>
        { Array.isArray(this.props.searchResults?.search) && this?.props?.searchResults?.search?.length != 0 &&
        (<div id ="dropdown7" className="dataResult" ref={this.wrapperRef}>
          { 
            (Array.isArray(this.props.searchResults?.search) && this?.props?.searchResults?.search?.map((res) => {
              return (
                
                <button className="searchDropdown" id={res.key} value={res.value}
                 onClick={() => this.handleSearchResultClick(res.value)}
                 >
                  {res.value}
                </button>
                
              );
            }))
          }
        </div>)}
        
        
      </div>
      );
  }
}

export default withRouter(SearchBar);
import { connect } from "react-redux";
import ProductIndex from "./products_index";
import { fetchAllProducts } from "../../actions/product_actions";
import {
  fetchUpvotes,
  createUpvote,
  destroyUpvote,
} from "../../actions/upvote_actions";
import { clearErrors } from "../../actions/error_actions";
import {
  fetchProduct,
  fetchProductImages,
} from "../../actions/product_actions";
// import { createUpvote, destroyUpvote } from "../../actions/upvote_actions"

const mapStateToProps = (state, ownProps) => {
  const token = state.session.currentUser?.token?.access;
  // console.log(state.session.currentUser?.userEmail);
  // const viewedProductId = ownProps.match.params.productId;
  // console.log(state)
  // let products = state.entities.products
  let productIds = state.entities.products.products;

  const currentUser = state.session.currentUser;
  return {
    // products,
    token,
    productIds,
    // viewedProductId,
    currentUser,
    openModal: ownProps.openModal,
    closeModal: ownProps.closeModal,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchProduct: (productId) => dispatch(fetchProduct(productId)),
    fetchAllProducts: (date, city) => dispatch(fetchAllProducts(date, city)),
    clearErrors: dispatch(clearErrors()),
    createUpvote: (upvoteableType, upvoteableId, email) =>
      dispatch(createUpvote(upvoteableType, upvoteableId, email)),
    // fetchUpvotes: (userId, upvoteableType) =>
    //   dispatch(fetchUpvotes(userId, upvoteableType)),
    // createUpvote: (upvoteableType, upvoteableId) =>
    //   dispatch(createUpvote(upvoteableType, upvoteableId)),
    // destroyUpvote: (upvoteableType, upvoteableId) =>
    //   dispatch(destroyUpvote(upvoteableType, upvoteableId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductIndex);

import $ from "jquery";

export const postUpvote = (token, productId, emailList) => {
  return new Promise((resolve, reject) => {
    var settings = {
      "url": `https://add.maascot.com/api/UpdateWebsiteInfo/${productId}/`,
      "method": "PUT",
      "timeout": 0,
      "headers": {
        "Authorization": `Bearer ${token}`,
        "content-type": "application/json"
      },
      "data": JSON.stringify({
        "unique_identity_number": `${productId}`,
        "history_or_background": `${emailList}`,
      }),
    };
    
    $.ajax(settings).done(function (response) {
      
      resolve(response);
    });

  })}

export const deleteUpvote = (upvoteableType, upvoteableId) => {
  return $.ajax({
    method: "DELETE",
    url: `api/upvotes/`,
    data: {
      upvote: { upvoteable_type: upvoteableType, upvoteable_id: upvoteableId },
    },
  });
};

export const getUpvotes = (userId, upvoteableType) => {
  return $.ajax({
    method: "GET",
    url: `api/upvotes/`,
    data: { upvote: { user_id: userId, upvoteable_type: upvoteableType } },
  });
};

export const getProductDetailUpvotes = (userId, productId) => {
  return $.ajax({
    method: "GET",
    url: `api/upvotes/`,
    data: { upvote: { user_id: userId, product_id: productId } },
  });
};

import React from "react";
import "../Subscribe/Subscribe.scss";

const Subscribe = (session) => {
  const handleBonzaiBasicClick = () => {
    // console.log(session.currentUser);
    if (!session.currentUser?.token) {
      alert("Please Login or Signup to subscribe to our plans");
    } else if (session.currentUser.id === "0") {
      alert("Please verify your email address to complete Signup");
    } else if (session.currentUser.id === 1) {
      window.open("https://buy.stripe.com/7sI01C04g6in3ZK8ww", "_blank");
    }
  };
  const handleBonzaiPlusClick = () => {
    // console.log(session.currentUser?.token);
    if (!session.currentUser?.token) {
      alert("Please Login or Signup to subscribe to our plans");
    } else if (session.currentUser.id === "0") {
      alert("Please verify your email address to complete Signup");
    } else if (session.currentUser.id === 1) {
      window.open("https://buy.stripe.com/aEUeWw8AMcGLcwg7st", "_blank");
    }
  };
  const handleBonzaiPremiumClick = () => {
    // console.log(session.currentUser?.token);
    if (!session.currentUser?.token) {
      alert("Please Login or Signup to subscribe to our plans");
    } else if (session.currentUser.id === "0") {
      alert("Please verify your email address to complete Signup");
    } else if (session.currentUser.id === 1) {
      window.open("https://buy.stripe.com/4gw7u4g3eayD0Ny5km", "_blank");
    }
  };
  return (
    <div className="app-content">
      <div className="subs">
        <div className="subscription-cards">
          <div className="card">
            <div className="card-header">Bonzai Basic</div>
            <div className="card-body">
              <h5 className="card-title">€19.99/month</h5>
              <ul>
                <li className="subscriptionListItem">
                  2 tickets per month for movies or live events
                </li>
                <li className="subscriptionListItem">
                  Personalized recommendations
                </li>
                <li className="subscriptionListItem">
                  Access to exclusive Bonzai community events and content
                </li>
                <li className="subscriptionListItem">
                  Priority customer support
                </li>
              </ul>
              <button
                className="btn btn-primary"
                onClick={handleBonzaiBasicClick}
              >
                {" "}
                {/* <a
                  href="https://buy.stripe.com/7sI01C04g6in3ZK8ww"
                  target="_blank"
                > */}
                Subscribe
                {/* </a> */}
              </button>
            </div>
          </div>
          <div className="card">
            <div className="card-header">Bonzai Plus</div>
            <div className="card-body">
              <h5 className="card-title">€39.99/month</h5>
              <ul>
                <li className="subscriptionListItem">
                  4 tickets per month for movies, live events, or a combination
                  of all sorts of events
                </li>
                <li className="subscriptionListItem">
                  Personalized recommendations with enhanced filtering options
                </li>
                <li className="subscriptionListItem">
                  Access to exclusive Bonzai community events and content
                </li>
                <li className="subscriptionListItem">
                  10% discount on additional tickets
                </li>
                <li className="subscriptionListItem">
                  Early access to event tickets and presales
                </li>
                <li className="subscriptionListItem">
                  Priority customer support
                </li>
                <li className="subscriptionListItem">
                  <strong>Switch Option:</strong> Swap one allotted ticket per
                  month with an alternative option
                </li>
              </ul>
              <button
                className="btn btn-primary"
                onClick={handleBonzaiPlusClick}
              >
                {" "}
                {/* <a
                  href="https://buy.stripe.com/aEUeWw8AMcGLcwg7st"
                  target="_blank"
                > */}
                Subscribe
                {/* </a> */}
              </button>
            </div>
          </div>
          <div className="card">
            <div className="card-header">Bonzai Premium</div>
            <div className="card-body">
              <h5 className="card-title">€69.99/month</h5>
              <ul>
                <li className="subscriptionListItem">Access to all features</li>
                <li className="subscriptionListItem">
                  8 tickets per month for movies, live events, or a combination
                  of all sorts of events
                </li>
                <li className="subscriptionListItem">
                  Personalized recommendations with premium filters and curated
                  experiences
                </li>
                <li className="subscriptionListItem">
                  Access to exclusive Bonzai community events and content
                </li>
                <li className="subscriptionListItem">
                  15% discount on additional tickets
                </li>
                <li className="subscriptionListItem">
                  Early access to event tickets and presales with VIP seating
                  options
                </li>
                <li className="subscriptionListItem">
                  Premium customer support with a dedicated account manager
                </li>
                <li className="subscriptionListItem">
                  <strong>Switch Option:</strong> Swap up to three allotted
                  tickets per month with alternative options
                </li>
              </ul>
              <button
                className="btn btn-primary"
                onClick={handleBonzaiPremiumClick}
              >
                {/* <a
                  href="https://buy.stripe.com/4gw7u4g3eayD0Ny5km"
                  target="_blank"
                > */}
                Subscribe
                {/* </a> */}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscribe;

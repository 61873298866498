import React from "react";
import { Route, Switch, withRouter } from "react-router";
import ProductFeed from "./product_feed/product_feed";
import ProductNav from "./product_nav/product_nav";
import ProductAd from "./product_ad/product_ad";
import { verify } from "../actions/session_actions";
import SearchResultsIndexContainer from "./search_results/search_results_index_container";

import SearchNav from "./search_results/search_nav.jsx";
import { connect } from "react-redux";

class AppContent extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;
    const queryParams = new URLSearchParams(this.props.location.search);
    const uid = queryParams.get("uid");
    const token = queryParams.get("token");

    if (uid && token) {
      dispatch(verify(uid, token))
        .then((res) => {
          // console.log(res);
        })
        .catch((error) => {
          alert("Token Expired");
        });
    }
  }
  render() {
    return (
      <div className="app-content">
        {/* <div className="product-nav-container">
          <Switch>
            <Route path="/search" render={() => <SearchNav />} />
            <Route path="/" render={() => <ProductNav />} />
          </Switch>
        </div> */}
        <div className="product-feed-container">
          <Switch>
            <Route
              path="/search"
              render={() => <SearchResultsIndexContainer />}
            />
            <Route path="/" render={() => <ProductFeed />} />
          </Switch>
        </div>
        {/* <div className="product-ad-container"> 
          <ProductAd />
        </div> */}
      </div>
    );
  }
}

{
  /* <Switch>
  <Route path="/search" component={SearchResultsIndexContainer}/>
  <Route path="/" component={ProductFeed}/>
</Switch> */
}

const AppContentWithRouter = withRouter(AppContent);

export default connect()(AppContentWithRouter);

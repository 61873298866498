import React from "react";
import "../Home/Home.scss";

function Home() {
  return (
    <div className="app-content">
      <div className="homepage-container">
        <h1 className="homepage-title">Welcome to Bonzai!</h1>
        <p className="homepage-text">
          We are a centralized platform that provides unlimited access to your
          preferred entertainment for free with one subscription. With Bonzai,
          you have the freedom to choose your preferences, and we provide you
          with a hassle-free way of accessing all types of entertainment
          happening around you.
        </p>
        <p className="homepage-text">
          At Bonzai, we believe in providing personalized recommendations to our
          users to help them find events that match their interests. To achieve
          this, we use data analysis and AI to analyze user data and provide
          personalized recommendations.
        </p>
        <p className="homepage-text">
          When you sign up for Bonzai, we collect data such as your location,
          past activity, search history, and preferences. We use this data to
          understand your entertainment preferences and suggest events that
          match your interests.
        </p>
        <p className="homepage-text">
          Our recommendation system is designed to learn from your activity on
          our platform. As you interact with our platform, our algorithms
          analyze your behavior to provide even more personalized
          recommendations. For example, if you frequently attend music concerts,
          we will suggest upcoming concerts in your area.
        </p>
        <p className="homepage-text">
          We also use AI to analyze user data to identify patterns and trends in
          entertainment preferences. This analysis helps us to understand which
          events are popular among our users and provide recommendations based
          on these insights.
        </p>
        <p className="homepage-text">
          Our platform also includes features to help you discover new and
          exciting entertainment options. Our calendar feature allows you to
          view upcoming events in your area, while our search function makes it
          easy to find specific movies, shows, or artists.
        </p>
        <p className="homepage-text">
          We understand that entertainment is personal, which is why we offer
          personalized recommendations based on your preferences. Our advanced
          algorithms take into account your past activity, search history, and
          location to suggest events, movies, and shows that you will love.
        </p>
        <p className="homepage-text">
          At Bonzai, we take the privacy and security of our users' data very
          seriously. We are committed to using user data in a responsible and
          ethical way to provide the best entertainment experience possible.
        </p>
        <div className="homepage-text">
          Join Bonzai today and experience entertainment like never before!
        </div>
      </div>
    </div>
  );
}

export default Home;

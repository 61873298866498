import React, { useState } from "react";
import "../user_profile/ChangePassword.scss";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [err, setErr] = useState("");
  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Access the query parameters
    const uid = queryParams.uid;
    const token = queryParams.token;
    // const uid = queryParams.get("uid");
    // const token = queryParams.get("token");
    if (uid && token) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        password: newPassword,
        password2: confirmPassword,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `https://admin1.bonzaico.com/email/reset-password/${uid}/${token}/`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result?.msg) {
            // console.log(result);
            setErr(
              "Password reset successfull. Please Login again to use your new Password"
            );

            alert(
              "Password changed. Please Login again to use your new Password. You are being redirected to Bonzai main Page."
            );
            window.location.href = "https://bonzaico.com/#/";
          }
          if (
            result?.errors?.non_field_errors[0] ===
            "Password and Confirm password does not match"
          ) {
            // console.log(result);
            setErr("Password and Confirm password does not match");
            // alert("Password and Confirm password does not match");
          }
        })
        .catch((error) => {
          console.log(error);
          setErr("error: Password change link is invalid now");
        });
    } else {
      setErr("InValid usage of password change or Network issue");
    }
    // TODO: Implement password change logic here
    // You can validate the passwords and make an API call to update the password
    // You can also handle any error messages and display them to the user
  };

  return (
    <div className="app-content">
      <form className="change-password-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="newPassword">New Password</label>
          <input
            type="password"
            id="newPassword"
            className="form-input"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="confirmPassword">Confirm Password</label>
          <input
            type="password"
            id="confirmPassword"
            className="form-input"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
        <button type="submit" className="form-button">
          Change Password
        </button>
        <div className="form-error">
          <div>{err}</div>
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;

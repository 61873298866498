import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import OTPForm from "./OTPForm";
import PhoneForm from "./PhoneForm";
import "react-phone-number-input/style.css";
import { login, signup } from "../../actions/session_actions";
import { useDispatch } from "react-redux";

const SessionForm = ({ formType, closeModal }) => {
  const [phone, setPhone] = useState("");
  const [otp, setOTP] = useState("");
  const [otpSent, setOTPSent] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  // console.log(formType);

  const handlePhoneSubmit = (
    phone,
    email,
    first_name,
    last_name,
    address,
    password
  ) => {
    if (formType == "signup") {
      dispatch(
        signup(phone, email, first_name, last_name, address, password)
      ).then((res) => {
        console.log(res);
        {
          if (
            res?.currentUser?.errors?.email[0] ===
            "user with this email already exists."
          ) {
            console.log(res?.currentUser?.errors?.email[0]);
            setError(`User with email ${email} already exists. Please Login`);
          }
          if (res?.currentUser?.id === "0") {
            setError("");
            setOTPSent(true);
          }
        }
      });
    } else if (formType == "login") {
      dispatch(login(email, password)).then((res) => {
        console.log(res?.currentUser?.id);
        if (res?.currentUser?.id == 0 || res?.currentUser?.id == 1) {
          closeModal();
        }
        if (res?.currentUser?.errors === "Email doesnot exist") {
          return setError(
            `${email} is not registered with us. Please SignUp/SignIn`
          );
        } else if (res?.currentUser?.errors === "Password is not valid") {
          return setError(`Incorrect Password for ${email}.`);
        }
        // closeModal();
      });
    }
  };

  const handleOTPSubmit = (otp) => {
    dispatch(login(phone, otp)).then((res) => {
      if (res?.currentUser?.message == "OTP verified successfully") {
        // console.log(state.session);
        closeModal();
      } else if (
        res?.currentUser?.message ==
        "User doesnot exist or user is verified already."
      ) {
        setError("User doesnot exist or user is verified already.");
      } else if (res?.currentUser?.message == "OTP expired") {
        setError("OTP expired");
      } else if (res?.currentUser?.message == "Invalid OTP") {
        setError("Invalid OTP");
        // closeModal();
      }
    });
    // closeModal;
  };

  return (
    <div className="session-form">
      {formType === "signup" && <div className="logoBack">SignUp/SignIn</div>}
      {formType === "login" && <div className="logoBack">Login</div>}
      {formType === "signup" &&
        (otpSent ? (
          <OTPForm onSubmit={handleOTPSubmit} closeModal={closeModal} />
        ) : (
          <PhoneForm onSubmit={handlePhoneSubmit} formType={formType} />
        ))}
      {formType === "login" && (
        <PhoneForm onSubmit={handlePhoneSubmit} formType={formType} />
      )}
      {error && <div>{error}</div>}
    </div>
  );
};

export default withRouter(SessionForm);

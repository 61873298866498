import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import { Router, Route, Switch, Link } from "react-router-dom";
import { createBrowserHistory } from "history";
import NavBarContainer from "./nav_bar/nav_bar_container";
import SessionFormContainer from "./session_form/session_form_container";
import NewProductFormContainer from "./new_product_form/new_product_form_container";
import UserProfileContainer from "./user_profile/user_profile_container";
import UserEditFormContainer from "./user_profile/user_edit_form_container";
import AppContent from "./app_content";
// import UserEditFormContainer from "./user_profile/user_edit_form_container";
import ProductDetailContainer from "./product_detail/product_detail_container";
import HeroBanner from "./hero_banner/hero_banner";
import Terms from "./Terms/Terms";
import TermsOfUse from "./Terms/TermsOfUse";
import policy from "./policy/policy";
import AboutUs from "./AboutUs/AboutUs";
import Home from "./Home/Home";
import Contact from "./Contact/Contact";
import SubscribeCont from "./Subscribe/SubscribeCont";
import SubscribeContPremium from "./Subscribe/SubscribeContPremium";
import SubscribeContPlus from "./Subscribe/SubscribeContPlus";
import SubscribeContBasic from "./Subscribe/SubscribeContBasic";
import change_password_container from "./user_profile/change_password_container";

import { AuthRoute, ProtectedRoute, UserRoute } from "../util/route_util";
const history = createBrowserHistory();
const App = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalFormType, setModalFormType] = useState("login");

  const hideDropdown = (e) => {
    let dropDownArray = document.getElementsByClassName("dropdown-content");
    Array.prototype.forEach.call(dropDownArray, (dropdown) => {
      dropdown.classList.add("hide");
    });
  };

  const openModal = (formType) => {
    return () => {
      setModalFormType(formType);
      setModalIsOpen(true);
    };
  };

  const afterModalOpen = () => {
    ReactModal.defaultStyles.overlay.backgroundColor = "rgba(128,128,128,0.75)";
    ReactModal.defaultStyles.content.display = "flex";
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="app" onClick={hideDropdown}>
      <header>
        <NavBarContainer openModal={openModal} formType={modalFormType} />
      </header>

      <ReactModal
        id="modal"
        isOpen={modalIsOpen}
        onAfterOpen={afterModalOpen}
        onRequestClose={closeModal}
        contentLabel="Modal_Content"
        className="modal"
      >
        <SessionFormContainer
          formType={modalFormType}
          closeModal={closeModal}
        />
      </ReactModal>

      <AuthRoute exact path="/" component={HeroBanner} openModal={openModal} />
      <Switch>
        <ProtectedRoute
          exact
          path="/products/new"
          component={NewProductFormContainer}
        />
        <UserRoute
          exact
          path="/users/:userId/edit"
          component={UserEditFormContainer}
        />
        {/* <UserRoute exact path="/settings" component={UserEditFormContainer} /> */}
        {/* <Route path="/settings" component={UserEditFormContainer}></Route> */}
        <Route path="/home" component={Home}></Route>
        <Route path="/subscribePlans" component={SubscribeCont}></Route>
        <Route
          path="/subscribePremium"
          component={SubscribeContPremium}
        ></Route>
        <Route
          path="/changePassword"
          component={change_password_container}
        ></Route>
        <Route path="/subscribePlus" component={SubscribeContPlus}></Route>
        <Route path="/subscribeBasic" component={SubscribeContBasic}></Route>
        <Route path="/contact" component={Contact}></Route>
        <Route path="/about" component={AboutUs}>
          {/* <AboutUs /> */}
        </Route>
        <Route path="/terms" component={Terms}></Route>
        <Route path="/termsOfUse" component={TermsOfUse}></Route>
        <Route path="/privacy" component={policy}>
          {/* <PrivacyPolicy /> */}
        </Route>
        <Route path="/faq">{/* <Faq /> */}</Route>
        <Route path="/user" component={UserProfileContainer} />
        <Route path="/settings" component={UserEditFormContainer} />
        {/* <Route path="/settings" component={UserEditFormContainer} /> */}
        <Route path="/products/:productId" component={ProductDetailContainer} />
        <Route path="/" component={AppContent} />
      </Switch>

      <footer class="footer">
        <div class="row">
          <div class="contact">
            <h4>Contact Us</h4>
            <ul>
              <li>
                <a href="mailto:info@bonzaico.com">info@bonzaico.com</a>
              </li>
              <li>
                <a href="tel:+4917632890611">+491-763-289-0611</a>
              </li>
              <li>Kiel, Germany</li>
            </ul>
          </div>
          <div class="links">
            <h4>Links</h4>
            <ul>
              <li>
                <Link to="/about" target="_blank">
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/terms" target="_blank">
                  Terms of Service
                </Link>
              </li>
              <li>
                <Link to="/privacy" target="_blank">
                  Privacy Policy
                </Link>
              </li>
              {/* <li>
                <Link to="/faq" target="_blank">
                  FAQ
                </Link>
              </li> */}
            </ul>

            <ul>
              <li>
                <a
                  href="https://instagram.com/bonzaientertainment"
                  target="_blank"
                  className="icon-link"
                >
                  <img
                    src="https://png.pngtree.com/png-vector/20221018/ourmid/pngtree-instagram-icon-png-image_6315974.png"
                    alt="Instagram"
                    class="icon-img"
                  />
                  <p className="icon-text">Instagram</p>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/bonzaico/"
                  target="_blank"
                  className="icon-link"
                >
                  <img
                    src="https://icon-library.com/images/official-linkedin-icon-png/official-linkedin-icon-png-16.jpg"
                    alt="LinkedIn"
                    class="icon-img"
                  />
                  <p className="icon-text">linkedin</p>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="legal">
          <div>&copy; 2023 Bonzaico. All rights reserved.</div>
          <div>
            <Link to="/termsOfUse" target="_blank">
              Terms Of Use
            </Link>{" "}
            |{" "}
            <Link to="/privacy" target="_blank">
              Privacy Policy
            </Link>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default App;

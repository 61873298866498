import React from "react";
import { fetchProduct } from "../../actions/product_actions";

const ProductDetailNav = ({
  product,
  viewedProductId,
  currentUser,
  createUpvote,
  fetchProduct,
  destroyUpvote,
  token,
}) => {
  // const { currentUserUpvoted, upvotes_count: countUpvotes } = product;
  console.log(token);
  let currentUserUpvoted = 0;
  let x = [];
  let productUpvoteCount = [];
  let productUpvoteCountString = product.history_or_background;
  let count = 0;
  // if (product.history_or_background) {
  //   count = JSON.parse(product.history_or_background);
  // }
  // if (currentUser && count) {
  //   currentUserUpvoted = count.includes(currentUser);
  // }

  // const toggleUpvote = (e) => {
  //   e.stopPropagation();
  //   if (currentUserUpvoted) {
  //     if (productUpvoteCountString) {
  //       productUpvoteCount = JSON.parse(productUpvoteCountString);
  //       x = productUpvoteCount.filter((item) => item !== currentUser);
  //       console.log(token);
  //       console.log(viewedProductId);
  //       console.log(JSON.stringify(x));
  //     }
  //     createUpvote(token, viewedProductId, JSON.stringify(x));
  //     // fetchProduct(viewedProductId);
  //   } else {
  //     if (productUpvoteCountString) {
  //       productUpvoteCount = JSON.parse(productUpvoteCountString);
  //       productUpvoteCount.push(currentUser);
  //       // console.log(productUpvoteCount);
  //       // console.log(newState);
  //       // console.log(productUpvoteCount);
  //       // console.log(token);
  //       // console.log(viewedProductId);
  //       x = JSON.stringify(productUpvoteCount);
  //       // console.log(x);
  //     }
  //     if (productUpvoteCount[productUpvoteCount.length - 1]) {
  //       // console.log(typeof x);
  //       createUpvote(token, viewedProductId, x);
  //     } else {
  //       alert("Login to Upvote");
  //     }
  //   }
  //   // setInterval(function () {
  //   //   fetchProduct(viewedProductId);
  //   // }, 1000);
  //   // setInterval(fetchProduct(viewedProductId), 10000);
  //   setTimeout(() => {
  //     fetchProduct(viewedProductId);
  //   }, "100");
  // };

  let linkUrl = product.link_url;
  let upvoteButtonClass = currentUserUpvoted
    ? "orange-button"
    : "orange-border-button";
  let upvoteButtonText = currentUserUpvoted ? "UPVOTED" : "UPVOTE";
  let url = window.location.href;

  let link = url.split("#/products/")[0];
  let eventDay = link.split("?query=")[1];
  let productString = url.split("#/products/")[1];
  let productString1 = productString.split("%22").join('"');
  product = JSON.parse(productString1.split("%20").join(" "));
  console.log(product);
  const handleClickButton = () => {
    const token = currentUser?.token?.access;
    if (!currentUser) {
      alert("Please Login or Signup to purchase tickets.");
    }
    if (token) {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch("https://admin1.bonzaico.com/email/profile", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.tc === 0) {
            alert("To purchase tickets. Please verify your email.");
          } else if (result.tc === 1) {
            var newWindow = window.open();
            newWindow.location = `${product.link}`;
          }
        })
        .catch((error) => alert("error", error));
    }
  };

  return (
    <div className="product-detail-nav">
      <div className="product-detail-nav-upvote-container">
        <button
          // onClick={toggleUpvote}
          // href={`${product.link}`}
          // target="_blank"
          className={`orange-border-button medium-size`}
          // value="Upvote"
          onClick={handleClickButton}
        >
          Buy Ticket
        </button>
      </div>
      {/* <div className="product-detail-nav-link-container">
        <a href={linkUrl} className="button white-button large-size">
          <div>Website</div>
          <div>{linkUrl}</div>
        </a>
      </div> */}
    </div>
  );
};

export default ProductDetailNav;

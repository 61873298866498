import React from "react";
import { getComment } from "../../util/product_api_util";

class CommentForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      commentText: "",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.autoSize = this.autoSize.bind(this);
    // console.log(this.props);
  }
  // console.log("dddd");

  handleSubmit(e) {
    e.preventDefault();

    let comment = {
      body: this.state.commentText,
      user_email: this.props.user,
      product_uuid: this.props.productId,
      parent_comment_id: e.target[1].getAttribute("id") || "",
    };
    comment = JSON.stringify(comment);
    // console.log(e.target[1].getAttribute("id"));
    this.props
      .createComment(comment)
      .then(() => {
        this.setState({ commentText: "" });
      })
      .then(() => {
        this.autoSize();
      })
      .then(() => {
        // console.log(this.props);
        // getComment(this.props.productId);
        if (this.toggleReplyForm) {
          this.toggleReplyForm();
        }
      });
  }

  handleChange(e) {
    this.setState({ commentText: e.target.value });
    this.autoSize();
  }

  autoSize() {
    let commentId = this.props.commentId;
    let textArea = document.getElementById(`textArea-${commentId}`);
    // console.log(this.props);
    let text = textArea.value.replace(/\n/g, "<br/>") + "&nbsp;";
    let textCopy = document.getElementById(`textCopy-${commentId}`);
    textCopy.innerHTML = text;
  }

  render() {
    let user = this.props.user;
    let classProp = this.props.classProp;
    let commentId = this.props.commentId;
    let userIcon = (
      <div className="user-profile-img-container">
        <img className="user-profile-img" src={user.imageUrl} />
      </div>
    );
    let buttonText = "Comment";
    if (classProp === "reply-form") {
      buttonText = "Reply";
    }

    return (
      <form className={classProp} onSubmit={this.handleSubmit}>
        <div className={`${classProp}-input-bar`}>
          {userIcon}
          <div id="textContainer" className="textarea-container">
            <textarea
              id={`textArea-${commentId}`}
              type="text"
              placeholder="What do you think of this product..."
              onChange={this.handleChange}
              value={this.state.commentText}
            />
            <div id={`textCopy-${commentId}`} className="text-copy" />
          </div>
        </div>
        <div className={`${classProp}-button-container`}>
          <input
            id={commentId}
            type="submit"
            className="button orange-button medium-size"
            value={buttonText}
          ></input>
        </div>
      </form>
    );
  }
}

export default CommentForm;

import React from "react";
import { Link } from "react-router-dom";

class SessionNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedButton: "login", // Initially set the "login" button as selected
    };
  }

  handleClick(formType) {
    return () => {
      this.props.clearErrors();
      this.props.openModal(formType)();
      this.setState({ selectedButton: formType });
    };
  }

  render() {
    const { selectedButton } = this.state;
    return (
      <nav className="login-signup">
        <button
          className={
            selectedButton === "login"
              ? "white-button medium-size"
              : "orange-button medium-size"
          }
          // className="white-button medium-size"
          onClick={this.handleClick("login")}
        >
          LOG IN
        </button>
        <button
          className={
            selectedButton === "signup"
              ? "white-button medium-size"
              : "orange-button medium-size"
          }
          // className="orange-button medium-size"
          onClick={this.handleClick("signup")}
        >
          SIGN UP
        </button>
      </nav>
    );
  }
}

export default SessionNav;

import { connect } from "react-redux";
import ChangePassword from "./ChangePassword";

const mapStateToProps = (state, ownProps) => {
  // console.log(state.entities.viewedUser.first_name);
  return {
    currentUser: state.session.currentUser,
  };
};

const mapDispactToProps = (dispatch, ownProps) => {
  console.log(ownProps);
  return {};
};

export default connect(mapStateToProps, mapDispactToProps)(ChangePassword);

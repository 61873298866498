import React, { useEffect } from "react";
import { fetchUser } from "../../actions/user_actions";

class UserEditForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      first_name: this.props.first_name,
      second_name: this.props.second_name,
      phone: this.props.phone,
      address: this.props.address,
      // email: this.props.currentUser.userEmail,

      // userslug: "",
    };
    // console.log(this.state);

    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    this.props.fetchUser(this.props?.currentUser?.token?.access);
    // console.log(this.props.first_name);
    setTimeout(() => {
      this.setState({
        first_name: this.props.first_name,
        second_name: this.props.second_name,
        phone: this.props.phone,
        address: this.props.address,

        // userslug: "",
      });
      // console.log(this.props.first_name);
    }, 230);
    // console.log(this.props.currentUser.userEmail);
    // this.setState({ email: this.props.currentUser.userEmail });
  }

  handleSubmit(e) {
    let userId = this.props.currentUser?.token?.access;
    e.preventDefault();
    // let user = Object.assign({}, this.state);
    // user.id = currentUser.id;
    // console.log(e);
    this.props.editUser(
      userId,
      this.state.first_name,
      this.state.second_name,
      this.state.phone,
      this.state.address
    );
    if (userId) {
      alert("Profile Data Updated");
    } else {
      alert("Login or SignUp");
    }
  }

  update(key) {
    return (e) => {
      this.setState({ [key]: e.target.value });
    };
  }
  handlePasswordEmailSend(e) {
    console.log(e.target.value);
    console.log(e.target);
    const email = e.target.value;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: email,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://admin1.bonzaico.com/email/send-reset-password-email",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => alert(`An email has ben sent at your email ${email}`))
      .catch((error) => alert("error"));
  }

  render() {
    const email = this.props?.currentUser?.userEmail;
    return (
      <div className="app-content">
        <div className="ue-form-container">
          <h1 className="ue-form-header">Settings</h1>

          <form className="ue-form" onSubmit={this.handleSubmit}>
            <div className="ue-form-input-container">
              <div className="ue-form-input">
                <label>First Name</label>
                <input
                  type="text"
                  onChange={this.update("first_name")}
                  value={this.state.first_name}
                />
              </div>

              <div className="ue-form-input">
                <label>Second Name</label>
                <input
                  type="text"
                  onChange={this.update("second_name")}
                  value={this.state.second_name}
                />
              </div>

              <div className="ue-form-input">
                <label>Address</label>
                <input
                  type="text"
                  onChange={this.update("address")}
                  value={this.state.address}
                />
              </div>
              <div className="ue-form-input">
                <label>Phone</label>
                <input
                  type="text"
                  onChange={this.update("phone")}
                  value={this.state.phone}
                />
              </div>
            </div>

            <div className="ue-form-buttons">
              <input
                type="submit"
                className="button orange-button medium-size"
                value="Update"
              ></input>
            </div>
            <div></div>
          </form>
          <button
            className="button orange-button medium-size"
            value={email}
            onClick={this.handlePasswordEmailSend}
          >
            Change Password?
          </button>
        </div>
      </div>
    );
  }
}

export default UserEditForm;

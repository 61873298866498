import React, { useState } from "react";
import "../nav_bar/Plans.scss";
import onClickOutside from "react-onclickoutside";

function Plans() {
  const [showDropdown, setShowDropdown] = useState(false);

  Plans.handleClickOutside = () => {
    setShowDropdown(false);
  };

  return (
    <div className="dropdown1">
      <div className="dropdown1-toggle" onClick={() => setShowDropdown(!showDropdown)}>
        Events
      </div>
      {showDropdown? (
        <ul className="dropdown1-menu">
          <li className="dropdown1-item">
            <a href="/#/search?query=Konzerte">Konzerte</a>
          </li>
          <li className="dropdown1-item">
            <a href="/#/search?query=Kultur">Kultur</a>
          </li>
          <li className="dropdown1-item">
            <a href="/#/search?query=Sport">Sport</a>
          </li>
          <li className="dropdown1-item">
            <a href="/#/search?query=Humor">Humor</a>
          </li>
          <li className="dropdown1-item">
            <a href="/#/search?query=Freizeit">Freizeit</a>
          </li>
        </ul>
      ):<></>}
    </div>
  );
}

const clickOutsideConfig = {
  handleClickOutside: () => Plans.handleClickOutside,
};

export default onClickOutside(Plans, clickOutsideConfig);

import React from "react";
import { useState } from "react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { getProductImages } from "../../util/product_api_util";
// import { openModal } from "../../components/nav_bar/nav_bar_container";

class ProductDetailMedia extends React.Component {
  render() {
    // constructor(props) {
    //   super(props);
    // }
    // console.log(this.props);
    // handleClick(formType) { return () => { this.props.clearErrors(); this.props.openModal(formType)(); }; }

    const handleClick = (formType) => {
      // this.props.clearErrors();
      // this.props.openModal(formType)();
      // console.log("adadadadadad");
      // 👇️ toggle shown state
      // this.props.setIsShown((current) => !current);
      // this.props.clearErrors();
      // this.props.openModal(formType)();
      // 👇️ or simply set it to true
      // setIsShown(true);
      return () => {
        console.log(this.props);
        this.props.clearErrors();
        this.props.openModal(formType)();
      };
    };

    const currentUser = this.props.currentUser;

    const productImages = this.props.productImages;
    const productUrl = this.props.productUrl;
    let url = window.location.href;

    let link = url.split("#/products/")[0];
    let eventDay = link.split("?query=")[1];
    let productString = url.split("#/products/")[1];
    let productString1 = productString.split("%22").join('"');
    let product = JSON.parse(productString1.split("%20").join(" "));
    console.log(product);
    let tags = [];
    // if (product.tag_primary && product.tag_secondary) {
    //   let pt = product.tag_primary.slice(2, -2);
    //   if (pt == "") {
    //     pt = [];
    //   } else {
    //     pt = pt.split('","');
    //   }
    //   let st = product.tag_secondary.slice(2, -2);
    //   if (st == "") {
    //     st = [];
    //   } else {
    //     st = st.split('","');
    //   }
    //   tags = pt.concat(st);
    // }
    let fs = [];
    // if (product.fuctions_or_services) {
    //   fs = product.fuctions_or_services.slice(1, -1);
    //   // if (fs == "") {
    //   //   fs = [];
    //   // } else {
    //   //   fs = fs.split('","');
    //   // }
    //   // console.log(fs);
    // }
    // console.log(pt);
    // console.log(st);

    // console.log(tags);

    // getProductImages(productUrl);
    // console.log(productImages);
    return (
      <div>
        <div className="product-detail-media">
          <div className="item">
            <img
              src={productImages[0]?.link}
              alt=""
              onError={((e) => (e.target.src = "missing-logo-dummy.png"), [])}
            />
          </div>
          <div className="item">
            <img
              src={productImages[1]?.link}
              alt=""
              onError={((e) => (e.target.src = "missing-logo-dummy.png"), [])}
            />
          </div>
          <div className="item">
            <img
              src={productImages[2]?.link}
              alt=""
              onError={((e) => (e.target.src = "missing-logo-dummy.png"), [])}
            />
          </div>
          <div className="item">
            <img
              src={productImages[3]?.link}
              alt=""
              onError={((e) => (e.target.src = "missing-logo-dummy.png"), [])}
            />
          </div>
          <div className="item">
            <img
              src={productImages[4]?.link}
              alt=""
              onError={((e) => (e.target.src = "missing-logo-dummy.png"), [])}
            />
          </div>
          <div className="item">
            <img
              src={productImages[5]?.link}
              alt=""
              onError={((e) => (e.target.src = "missing-logo-dummy.png"), [])}
            />
          </div>
          <div className="item">
            <img
              src={productImages[6]?.link}
              alt=""
              onError={((e) => (e.target.src = "missing-logo-dummy.png"), [])}
            />
          </div>
          <div className="item">
            <img
              src={productImages[7]?.link}
              alt=""
              onError={((e) => (e.target.src = "missing-logo-dummy.png"), [])}
            />
          </div>
          <div className="item">
            <img
              src={productImages[8]?.link}
              alt=""
              onError={((e) => (e.target.src = "missing-logo-dummy.png"), [])}
            />
          </div>
          <div className="item">
            <img
              src={productImages[9]?.link}
              alt=""
              onError={((e) => (e.target.src = "missing-logo-dummy.png"), [])}
            />
          </div>
        </div>
        <div className="product-addtional-information">
          <h1>Additional Information</h1>
          <div>
            <div>
              <h1>Event Name</h1>
              <h3>{product?.name}</h3>
            </div>
            <div>
              <h4>{product?.description}</h4>
            </div>
            <div>
              <h3>Type of Event -</h3>
              <p>{product?.type}</p>
            </div>
            <div>
              <h3>Ticket Availablity -</h3>
              <p>{product?.status}</p>
            </div>
            <div>
              <h3>Price -</h3>
              <p>{product?.price}</p>
            </div>
            <div>
              <h3>Start Date -</h3>
              <p>{product?.typeAttributes?.liveEntertainment?.startDate}</p>
            </div>
            <div>
              <h3>Location -</h3>
              <p>
                {product?.typeAttributes?.liveEntertainment?.location?.name},{" "}
                {product?.typeAttributes?.liveEntertainment?.location?.city}
              </p>
            </div>
            {/* <div>
              <h3>Attraction -</h3>
              <p>{product?.attractions[0].name}</p>
            </div>
            <div>
              <h3>Category -</h3>
              <p>{product?.categories[0]?.name}</p>
            </div> */}
            <div>
              <h3>Rating -</h3>
              <p>
                {product?.rating?.average} from ({product?.rating?.count})
              </p>
            </div>
          </div>
        </div>
        {/* <div id="review" className="product-reviews">
          <div className="review-board">
            <h2>Word From Our Customers</h2>
            <p>Some of the fulfilled customers review</p>
            <Swiper
              // install Swiper modules
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={50}
              slidesPerView={1}
              navigation
              pagination={{ clickable: true }}
              scrollbar={{ draggable: true }}
              // onSwiper={(swiper) => console.log(swiper)}
              // onSlideChange={() => console.log("slide change")}
            >
              <SwiperSlide className="review-slide">
                <div className="review-flex">
                  <div className="review-comments">
                    This is the best product in the market. I love this and
                    every body should use it.And Should Always use it.This is
                    the best product in the market. I love this and every body
                    should use it.And Should Always use it.
                  </div>
                  <div className="profile">
                    <a href="#">Suket Suman</a>
                    <span>Software Developer</span>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="review-slide">
                <div className="review-flex">
                  <div className="review-comments">
                    This is the best product in the market. I love this and
                    every body should use it.
                  </div>
                  <div className="profile">
                    <a href="#">Suket Suman</a>
                    <span>Software Developer</span>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="review-slide">
                <div className="review-flex">
                  <div className="review-comments">
                    This is the best product in the market. I love this and
                    every body should use it.
                  </div>
                  <div className="profile">
                    <a href="#">Suket Suman</a>
                    <span>Software Developer</span>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="review-slide">
                <div className="review-flex">
                  <div className="review-comments">
                    This is the best product in the market. I love this and
                    every body should use it.
                  </div>
                  <div className="profile">
                    <a href="#">Suket Suman</a>
                    <span>Software Developer</span>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="review-slide">
                <div className="review-flex">
                  <div className="review-comments">
                    This is the best product in the market. I love this and
                    every body should use it.
                  </div>
                  <div className="profile">
                    <a href="#">Suket Suman</a>
                    <span>Software Developer</span>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
          {currentUser && (
            <font
              id="reviews"
              onClick={this.handleClick}
              size="4"
              face="verdana"
              color="gray"
            >
              Add Review
            </font>
          )}
        </div> */}
      </div>
    );
  }
}

export default ProductDetailMedia;

import React, { useState } from "react";
import "../nav_bar/Plans.scss";
import onClickOutside from "react-onclickoutside";

function Plans() {
  const [showDropdown, setShowDropdown] = useState(false);

  Plans.handleClickOutside = () => {
    setShowDropdown(false);
  };

  return (
    <div className="dropdown1">
      <div className="dropdown1-toggle" onClick={() => setShowDropdown(!showDropdown)}>
        Subscription Plans
      </div>
      {showDropdown ? (
        <ul className="dropdown1-menu">
          <li className="dropdown1-item">
            <a
              href="/#/subscribePremium"
              // target="_blank"
            >
              Bonzai Premium
            </a>
          </li>
          <li className="dropdown1-item">
            <a
              href="/#/subscribePlus"
              // target="_blank"
            >
              Bonzai Plus
            </a>
          </li>
          <li className="dropdown1-item">
            <a
              href="/#/subscribeBasic"
              // target="_blank"
            >
              Bonzai Basic
            </a>
          </li>
        </ul>
      ):<></>}
    </div>
  );
}

const clickOutsideConfig = {
  handleClickOutside: () => Plans.handleClickOutside,
};
export default onClickOutside(Plans, clickOutsideConfig);

import { connect } from "react-redux";
import { withRouter } from "react-router";
import ProductDetail from "./product_detail";
import {
  fetchProduct,
  fetchProductImages,
} from "../../actions/product_actions";
import { createUpvote, destroyUpvote } from "../../actions/upvote_actions";
import { clearErrors } from "../../actions/error_actions";

const mapStateToProps = (state, ownProps) => {
  const viewedProductId = ownProps.match.params.productId;
  // var productUrl = "images not found";
  const product = state.entities.products.currentProduct || {};
  const token = state.session.currentUser?.token?.access;
  const productUrl = product?.url;
  const productImages = state.entities.products.currentProductImages || [];
  const currentUser = state.session.currentUser;
  console.log(ownProps);
  // const openModal = ownProps.openModal;
  // const closeModal = ownProps.closeModal;
  // console.log(token);
  // console.log(product)
  // console.log(productUrl);
  // console.log(viewedProductId)
  // console.log(product)
  // console.log(productsById)
  // console.log(product)

  return {
    token,
    product,
    viewedProductId,
    productImages,
    productUrl,
    currentUser,
    openModal: ownProps.openModal,
    closeModal: ownProps.closeModal,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchProduct: (productId) => dispatch(fetchProduct(productId)),
    fetchProductImages: (productUrl) =>
      dispatch(fetchProductImages(productUrl)),
    createUpvote: (upvoteableType, upvoteableId, email) =>
      dispatch(createUpvote(upvoteableType, upvoteableId, email)),
    destroyUpvote: (upvoteableType, upvoteableId) =>
      dispatch(destroyUpvote(upvoteableType, upvoteableId)),
    clearErrors: dispatch(clearErrors()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductDetail)
);

import React from "react";
import "../Subscribe/Subscribe.scss";

const Subscribe = (session) => {
  const handleBonzaiBasicClick = () => {
    // console.log(session.currentUser);
    if (!session.currentUser?.token) {
      alert("Please Login or Signup to subscribe to our plans");
    }
    if (session.currentUser.id === "0") {
      alert("Please verify your email address to complete Signup");
    } else if (session.currentUser.id === 1) {
      window.open("https://buy.stripe.com/7sI01C04g6in3ZK8ww", "_blank");
    }
  };
  return (
    <div className="app-content">
      <div className="subs">
        <div className="subscription-card">
          <div className="card">
            <div className="card-header">Bonzai Basic</div>
            <div className="card-body">
              <h5 className="card-title">€19.99/month</h5>
              <ul>
                <li className="subscriptionListItem">
                  2 tickets per month for movies or live events
                </li>
                <li className="subscriptionListItem">
                  Personalized recommendations
                </li>
                <li className="subscriptionListItem">
                  Access to exclusive Bonzai community events and content
                </li>
                <li className="subscriptionListItem">
                  Priority customer support
                </li>
              </ul>
              <button
                className="btn btn-primary"
                onClick={handleBonzaiBasicClick}
              >
                {" "}
                {/* <a
                  href="https://buy.stripe.com/7sI01C04g6in3ZK8ww"
                  target="_blank"
                > */}
                Subscribe
                {/* </a> */}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscribe;

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import NavBar from "./nav_bar";
// import ProductDetail from '../../components/product_detail/product_detail';
import { logout } from "../../actions/session_actions";
import { clearErrors } from "../../actions/error_actions";
import { fetchUser } from "../../actions/user_actions";
import { fetchAllProducts } from "../../actions/product_actions";

const mapStateToProps = (state, ownProps) => {
  // console.log("sssssssssss", state.session);

  return {
    formType: ownProps.formType,
    currentUser: state.session.currentUser,
    openModal: ownProps.openModal,
    closeModal: ownProps.closeModal,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    logout: () =>
      dispatch(logout()).then(() => {
        ownProps.history.push("/");
      }),
    clearErrors: dispatch(clearErrors()),
    fetchUser: (userId) => dispatch(fetchUser(userId)),
    fetchAllProducts: () => dispatch(fetchAllProducts()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBar));

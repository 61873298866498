import $ from "jquery";

export const search = (query) =>
{return new Promise((resolve, reject) => {
  var settings = {
    "url": `https://add.maascot.com/api/omnisearch/?search=${query}`,
    "method": "GET",
    "timeout": 0,
    "headers": {
      "content-type": "application/json"
    },
  };
 
  
  $.ajax(settings).done(function (response) {
    resolve(response);
    // return response;
    
  });
});}

export const tagSearchResult = (tag) => {
  return new Promise((resolve, reject) => {
    var settings = {
      "url": `https://public-api.eventim.com/websearch/search/api/exploration/v2/productGroups?webId=web__eventim-de&language=DE&retail_partner=EVE&categories=${tag}&sort=Recommendation&in_stock=true`,
      "method": "GET",
      "timeout": 0,
      "headers": {
        "content-type": "application/json"
      },
    };
    
    $.ajax(settings).done(function (response) {
      resolve(response);
    });
  })
}


import { connect } from "react-redux";
import UserEditForm from "./user_edit_form.jsx";
import { editUser } from "../../actions/user_actions";
import { fetchUser } from "../../actions/user_actions";

const mapStateToProps = (state, ownProps) => {
  // console.log(state.entities.viewedUser.first_name);
  let viewedUser = state.entities.viewedUser;
  return {
    currentUser: state.session.currentUser,
    first_name: state.entities.viewedUser.first_name,
    second_name: state.entities.viewedUser.second_name,
    phone: state.entities.viewedUser.phone,
    address: state.entities.viewedUser.address,
    // user: viewedUser,
  };
};

const mapDispactToProps = (dispatch, ownProps) => {
  console.log(ownProps);
  return {
    editUser: (userId, first_name, second_name, phone, address) =>
      dispatch(editUser(userId, first_name, second_name, phone, address)),
    fetchUser: (token) => dispatch(fetchUser(token)),
  };
};

export default connect(mapStateToProps, mapDispactToProps)(UserEditForm);

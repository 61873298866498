import React from "react";
import { withRouter, Redirect } from "react-router";

class Dropdown extends React.Component {
  constructor(props) {
    super(props);

    this.showDropdown = this.showDropdown.bind(this);
    this.handleRedirectToProfile = this.handleRedirectToProfile.bind(this);
    this.handleRedirectToSettings = this.handleRedirectToSettings.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  showDropdown(e) {
    e.stopPropagation();
    let dropdownArray = document.getElementsByClassName("dropdown-content");
    Array.prototype.forEach.call(dropdownArray, (dropdown) => {
      dropdown.className = "dropdown-content";
    });
  }

  handleRedirect(path) {
    return (e) => {
      this.props.history.push(path);
    };
  }

  handleLogout() {
    // this.props;
    // window.location.reload(false);
    this.props.fetchAllProducts();
    this.props.history.push("/");
    window.location.reload(false);
  }

  handleRedirectToProfile() {
    // let currentUserTaken = this.props.currentUser.id.token.access;
    // console.log(this.props);
    this.props.history.push("/user");
    // this.props.fetchUser(currentUserTaken);
  }
  handleRedirectToSettings() {
    // console.log(this.props);
    this.props.history.push("/settings");
    // window.location.href = "/settings";
  }
  render() {
    let currentUser = this.props.currentUser?.id;
    let imageUrl =
      "https://png.pngtree.com/png-vector/20190215/ourlarge/pngtree-vector-valid-user-icon-png-image_516298.jpg";
    return (
      <div className="dropdown">
        <div className="user-profile-img-container" onClick={this.showDropdown}>
          <img className="user-profile-img" src={imageUrl} />
          <div className="dropdown-content hide">
            <ul>
              <li
                className="dropdown-item"
                onClick={this.handleRedirectToProfile}
              >
                Profile
              </li>
              <li
                className="dropdown-item"
                onClick={this.handleRedirectToSettings}
              >
                Settings
              </li>
              <li className="dropdown-item" onClick={this.handleLogout}>
                Log Out
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Dropdown);

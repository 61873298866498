import { RECEIVE_SEARCH_RESULTS } from '../actions/search_actions';
import { RECEIVE_TAG_SEARCH_RESULTS } from '../actions/search_actions';
import { merge } from 'lodash';

const defaultState = {
  // products: {
  //   byId: [],
  //   allIds: []
  // },
  // users: {
  //   byId: [],
  //   allIds: []
  // }
  // by_id: {}, all_ids: [] 
};

const searchResultsReducer = (state = defaultState, action) => {
  Object.freeze(state);
  let newState = merge({}, state);
  switch (action.type) {
    case RECEIVE_SEARCH_RESULTS:
      newState.search = action.searchResults;
      return newState;
    
    case RECEIVE_TAG_SEARCH_RESULTS:
      newState.products = action.tagSearchResults;
      return newState;
  
    default:
      return state;
  }
};

export default searchResultsReducer;